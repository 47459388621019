import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addViewingSlotAction, removeViewingSlotAction } from '../../../store/actions/PropertyActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import SchedulerCalendar from '../../calendar';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import BottomBtn from '../common/ButtonContainer';
import VerticalStepper from '../common/VerticalStepper';
import ConfirmSlots from './ModalConfirm';

interface ViewingSlotsProps {
  handleBack(): void;
  handleNext(): void;
  handleRebookLead?(advertId :string, leadId :string): void;
  steps: string[];
  backText?: string;
  nextText?: string;
  alertText?: string;
  alertOnExit?: boolean;
  enableStepper?: boolean;
  advertId?: boolean;
  leadId?: boolean;
}

const ViewingSlots: React.FC<ViewingSlotsProps> = ({
  handleBack,
  handleNext,
  handleRebookLead,
  steps,
  backText,
  nextText,
  alertText,
  alertOnExit,
  enableStepper,
  advertId,
  leadId,
}) => {
  const { roomDetails, viewingSlots } = useAppSelector((state) => state.createpropertyState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingDate, setLoadingDate] = React.useState<string>('');
  const [openSlots, setOpenSlots] = React.useState(false);
  const handlePickedDate = async (
    date: string,
    id?: string,
    virtual?: boolean,
    onsite?: boolean,
    maxAttendees?: number,
  ): Promise<void> => {
    setLoading(true);
    setLoadingDate(date);
    try {
      if (id) {
        await dispatch(removeViewingSlotAction(id, roomDetails?.id as string) as any);
      } else {
        await dispatch(addViewingSlotAction(
          date,
          roomDetails?.id as string,
          virtual || false,
          onsite || false,
          maxAttendees || 0,
        ));
      }
    } catch (error) {
      toast.error('an error occured while updating the slot, try again later');
    }
    setLoading(false);
  };

  const handleHasVirtualMeetings = (): void => {
    const virtualSlots = viewingSlots.filter((slot) => slot?.isVirtual);
    if (virtualSlots.length) {
      handleNext();
      if (handleRebookLead === undefined) {
        navigate('../virtual-meeting');
      }
    } else {
      handleNext();
    }
  };
  const handleValidateSlots = (event: any): void => {
    event.preventDefault();
    if (!viewingSlots.length) {
      toast.error('Please create at least one viewing slot');
    } else {
      setOpenSlots(true);
    }
  };

  useEffect(() => {
    if (!roomDetails?.id) {
      toast.error('create an advert first and then try again');
      navigate('../room-details');
    }
    const confirmExit = (): string => alertText || '';
    window.onbeforeunload = confirmExit;
    if (alertOnExit) {
      window.onbeforeunload = confirmExit;
    }
  }, []);
  return (
    <ApplicationWrapper
      customSideNav={
        enableStepper ? <VerticalStepper activeStep={steps.indexOf('Viewing Slots')} steps={steps} /> : null
      }
      activeMenu={0}
      menuElements={[]}
    >
      <Box
        sx={{
          display: 'flex',
          minHeight: '70vh',
          position: 'relative',
          flexDirection: 'column',
          mb: 20,
        }}
      >
        <SchedulerCalendar
          loading={loading}
          handleCheck={handlePickedDate}
          datePicked={viewingSlots}
          loadingDate={loadingDate}
        />
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            position: 'relative',
            mt: 10,
          }}
        >
          <BottomBtn
            typeSubmit="submit"
            loading={loading}
            handleBack={handleBack}
            handleNext={handleValidateSlots}
            backText={backText}
            nextText={nextText}
          />
        </Box>
        <ConfirmSlots
          open={openSlots}
          handleClose={() => setOpenSlots(false)}
          handleNext={handleHasVirtualMeetings}
          viewingSlots={viewingSlots}
        />
      </Box>
    </ApplicationWrapper>
  );
};

export default ViewingSlots;
