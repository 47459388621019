import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RegisterForm from '../components/Auth/Signup';
import Navbar from '../components/layout/Navbar';
import usePageTitle from '../hooks/pageTitle';
import { useRequestState } from '../hooks/request';
import useLazyEffect from '../hooks/useEffect';
import { registerUser, IUserRegisterParams } from '../sdk/authentication';
import { parseApiError } from '../utils/errors';

const Register: React.FC = () => {
  usePageTitle('register');
  const {
    error,
    loading,
    fetch,
    value,
  } = useRequestState(registerUser);
  const navigate = useNavigate();
  const [open, setOpenModal] = React.useState<boolean>(false);
  const handleClose = (): void => {
    setOpenModal(false);
    navigate('/email-instructions');
  };

  const handleSubmit = async (userDetails: IUserRegisterParams): Promise<void> => {
    await fetch(userDetails);
  };

  useLazyEffect(() => {
    if (error) {
      parseApiError(
        error?.data?.errors || error,
        'something went wrong while registering, please check your details and submit again',
      );
    } else {
      setOpenModal(true);
      toast.success('You have registered successfully, please log in.');
      navigate('/email-instructions');
    }
  }, [value, error]);
  return (
    <>
      <Navbar />
      <RegisterForm open={open} handleClose={handleClose} loading={loading} handleSubmitData={handleSubmit} />
    </>
  );
};

export default Register;
