/* eslint-disable react/require-default-props */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Switch,
  Button,
  DialogActions,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { addMinutes, isWeekend } from 'date-fns';
import { BpCheckedIcon, BpIcon } from './styles';
import { daysWeeksGenerator, IDateObject } from '../../utils/dateGenerator';
import { IViewingSlot } from '../../store/reducers/createProperty';
import ChipIsVirtual from '../common/ChipIsVirtual';

const options = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 0,
    label: 'Unlimited',
  },
];

const getTimeRanges = (interval: number, language = window.navigator.language): string[] => {
  const ranges: string[] = [];
  const date = new Date();
  const format = {
    hour: 'numeric',
    minute: 'numeric',
  } as any;

  for (let minutes = 0; minutes < 16 * 60; minutes += interval) {
    date.setHours(6);
    date.setMinutes(minutes);
    ranges.push(date.toLocaleTimeString(language, format));
  }

  return ranges;
};

const DateTitle: React.FC<{ date: number; month: string; day: string; }> = ({
  date, month, day,
}) => (
  <Box>
    <Typography sx={{ fontWeight: 'bold' }} color="primary">{day}</Typography>
    <Typography sx={{ fontWeight: 'bold' }} color="primary">{month}</Typography>
    <Typography sx={{ fontWeight: 'bold' }} color="primary">{date}</Typography>
  </Box>
);

interface ICalendarProps {
  handleCheck: any;
  loading?: boolean;
  datePicked: IViewingSlot[];
  loadingDate: string;
}

const Calendar: React.FC<ICalendarProps> = ({
  handleCheck,
  loading,
  datePicked,
  loadingDate,
}) => {
  const [week, setWeek] = React.useState(0);
  const [dates, setDates] = React.useState(daysWeeksGenerator());
  const [open, setOpen] = React.useState(false);
  const [isVirtual, setIsVirtual] = React.useState(false);
  const [isOnsite, setIsOnsite] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState('');
  const [maxAttendees, setMaxAttendees] = React.useState(0);
  const goNextWeek = (): void => {
    if (dates.length - 1 > week) {
      setWeek(week + 1);
    } else {
      const lastDate = dates[week][6].jsDate;
      const extraWeeks = daysWeeksGenerator(new Date(lastDate), 35);
      setDates((prevState: any) => ([...prevState, ...extraWeeks]));
      setWeek(week + 1);
    }
  };
  const goPrevWeek = (): void => {
    setWeek(week - 1);
  };
  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>

        <DialogTitle>Meeting Type</DialogTitle>
        <DialogContent>
          <List sx={{ pt: 0 }}>
            <ListItem>
              <ListItemText id="switch-list-label-isVirtual" primary="Virtual" />
              <Switch
                edge="end"
                onChange={() => setIsVirtual(!isVirtual)}
                checked={isVirtual}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-isVirtual',
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText id="switch-list-label-isOnsite" primary="Onsite" />
              <Switch
                edge="end"
                onChange={() => setIsOnsite(!isOnsite)}
                checked={isOnsite}
                inputProps={{
                  'aria-labelledby': 'switch-list-label-isOnsite',
                }}
              />
            </ListItem>
            <ListItem>
              <TextField
                select
                fullWidth
                label="Max Number Of Attendees Of Viewing"
                value={maxAttendees}
                onChange={(e) => setMaxAttendees(Number(e.target.value))}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={!isVirtual && !isOnsite}
            onClick={() => {
              const checked = datePicked.find((dta) => selectedDate === dta.dateAndTime);
              handleCheck(
                selectedDate,
                checked ? checked.id : undefined,
                isVirtual,
                isOnsite,
                maxAttendees,
              );
              setOpen(false);
              // Keep previous selection
              // setMaxAttendees(0);
              // setIsVirtual(false);
              // setIsOnsite(false);
            }}
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer
        sx={{
          maxHeight: 560,
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '20px',
            height: '0.3em',
            mt: 6,
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 2px rgba(71, 78, 209,.9)',
            borderRadius: '15px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(71, 78, 209,.5)',
            borderRadius: '15px',
            '&:hover': {
              backgroundColor: 'rgba(71, 78, 209,.9)',
            },
          },
        }}
        component={Paper}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              borderLeft: '1px solid rgba(224, 224, 224, 1) !important',
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              {dates[week].map((el: IDateObject) => {
                const date = new Date(el.jsDate);
                const weekend = isWeekend(date);
                return (
                  <TableCell
                    key={date.toISOString()}
                    align="center"
                    sx={{
                      ...weekend && ({ backgroundColor: '#f6f6f6' }),
                    }}
                  >
                    <DateTitle date={el.date} month={el.monthName} day={el.day} />
                  </TableCell>
                );
              })}
              <TableCell align="center">
                <IconButton onClick={goPrevWeek} disabled={week === 0}>
                  <ChevronLeftIcon color={week === 0 ? 'disabled' : 'primary'} />
                </IconButton>
                <IconButton onClick={goNextWeek}>
                  <ChevronRightIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTimeRanges(15, 'en-GB').map((row) => (
              <TableRow
                key={row}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
                {dates[week].map((el: IDateObject) => {
                  const date = new Date(el.jsDate);
                  date.setHours(Number(row.split(':')[0]), Number(row.split(':')[1]));
                  const dateDisabled = new Date(addMinutes(new Date(), 1)).getTime() >= date.getTime();
                  const dateVal = date.toISOString() as string;
                  const checked = datePicked.find((dta) => dateVal === dta.dateAndTime);
                  const { isVirtual: slotIsVirtual = false, isOnsite: slotIsOnsite = false } = checked || {};
                  const weekend = isWeekend(date);
                  return (
                    <TableCell
                      key={date.toISOString()}
                      align="center"
                      sx={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        ...weekend && ({ backgroundColor: '#f6f6f6' }),
                      }}
                    >
                      {loading && dateVal === loadingDate ? <CircularProgress /> : (
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={4} />
                          <Grid item xs={4}>
                            <Checkbox
                              sx={{ position: 'relative', top: 0, left: 0 }}
                              disableRipple
                              checked={!!checked}
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                              disabled={dateDisabled}
                              onChange={() => {
                                if (checked) {
                                  handleCheck(
                                    dateVal,
                                    checked ? checked.id : undefined,
                                    isVirtual,
                                    isOnsite,
                                    maxAttendees,
                                  );
                                } else {
                                  setSelectedDate(dateVal);
                                  setOpen(true);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ChipIsVirtual
                              isVirtual={slotIsVirtual}
                              isOnsite={slotIsOnsite}
                              useIcon={false}
                            />
                          </Grid>

                        </Grid>
                      )}
                    </TableCell>
                  );
                })}
                <TableCell sx={{ border: 0 }} align="center">{' '}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Calendar;
