import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/Auth/Login';
import Navbar from '../components/layout/Navbar';
import usePageTitle from '../hooks/pageTitle';
import { IUserLoginParams } from '../sdk/authentication';
import { loginAction } from '../store/actions/authActions';
import { useAppDispatch } from '../store/hooks';
import { parseApiError } from '../utils/errors';

const Login: React.FC = () => {
  usePageTitle('sign in');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleLogin = async (
    userCredentails: IUserLoginParams,
  ): Promise<void> => {
    setLoading(true);
    await dispatch(loginAction(userCredentails))
      .catch((error) => parseApiError(error.data?.errors || error));
    navigate('/', { replace: true });
  };

  return (
    <>
      <Navbar />
      <LoginForm loading={loading} handleLogin={handleLogin} />
    </>
  );
};

export default Login;
