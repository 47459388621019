import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import usePageTitle from '../../hooks/pageTitle';
import ViewingSlots from '../../components/CreateAd/ViewingSlots';
import VirtualMeeting from '../../components/CreateAd/VirtualMeeting';
import { rebookLeadAction, getLeadListAction } from '../../store/actions/LeadActions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateAdvertDetails } from '../../store/actions/PropertyActions';

export const steps = [
  'Viewing Slots',
];

interface CreateAdProps {
  match?: any;
}

const Rebooking: React.FC<CreateAdProps> = () => {
  const { advertId, leadId } = useParams();
  usePageTitle('Rebook Viewing Slot');

  const advertData = useAppSelector((state) => state.createpropertyState?.roomDetails);
  const leadsList = useAppSelector((state) => state.leadState.leads);
  const { virtualMeetingDetails = '' } = advertData || {};

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = React.useState(0);

  const componentSwitch = [{
    id: 0,
    title: 'Create an advert',
    route: '/rebooking/:advertId/lead/:leadId/viewing-slots',
  },
  ];

  const getDetails = async (id: string): Promise<void> => {
    try {
      await dispatch(updateAdvertDetails(id, true));
      await dispatch(getLeadListAction());
    } catch (error) {
      toast.error('something went wrong while fetching the property details, please try again later');
    }
  };

  const handleRebookLead = async (id: string, lead: string): Promise<void> => {
    try {
      const { viewingSlot } = leadsList.find((f) => f.id === lead) || {};
      const { id: viewingSlotId } = viewingSlot || {};

      await dispatch(rebookLeadAction(id, viewingSlotId || ''));
      await dispatch(getLeadListAction());
      toast.success('Leads rebooked successfully');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Something went wrong while rebooking the lead');
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    const newStep = componentSwitch.findIndex((item) => item.route === pathname);
    if (newStep !== step) {
      setStep(newStep);
    }
  }, [pathname]);

  const handleBack = (): void => {
    if (step > 0) {
      navigate(componentSwitch[step - 1].route);
    } else {
      navigate('/dashboard');
    }
  };

  const handleNext = (): void => {
    if (virtualMeetingDetails) {
      handleRebookLead(advertId || '', leadId || '');
    } else {
      navigate('./virtual-meeting');
    }
  };
  useEffect(() => {
    if (advertId) {
      getDetails(advertId);
    }
  }, [advertId]);

  return (
    <>
      <Routes>
        <Route
          path="/viewing-slots"
          element={(
            <ViewingSlots
              handleBack={handleBack}
              handleNext={handleNext}
              handleRebookLead={handleRebookLead}
              steps={steps}
              backText="Cancel"
              alertOnExit
              alertText="Please make sure you have at least a viewing slots available?"
            />
          )}
        />
        <Route
          path="/virtual-meeting"
          element={(
            <VirtualMeeting
              handleBack={handleBack}
              handleNext={handleNext}
              handleRebookLead={handleRebookLead}
              steps={steps}
              advertId={advertId}
              leadId={leadId}
            />
          )}
        />
      </Routes>
    </>
  );
};

export default Rebooking;
