import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  Divider,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  FormContainer,
  TextInput,
  TextInputContainer,
} from '../styles';
import BottomBtn from '../common/ButtonContainer';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import { steps } from '../../../views/CreateAd';
import { thirdStepScheme } from '../validations';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createAdvertAction, editAdvertAction } from '../../../store/actions/PropertyActions';
import { useRequestState } from '../../../hooks/request';
import { IwithDefault, rentFrequenciesList } from '../../../sdk/propertyApi';
import useScrollToError from '../../../hooks/useScrollToError';

interface RoomDetailsFormProps {
  handleBack(): void;
  handleNext(): void;
}

export const CheckBoxesRent = [
  {
    id: 1,
    name: 'internetIncluded',
    label: 'Internet',
  },
  {
    id: 2,
    name: 'councilTaxIncluded',
    label: 'Council Tax',
  },
  {
    id: 3,
    name: 'gasIncluded',
    label: 'Gas',
  },
  {
    id: 4,
    name: 'electricityIncluded',
    label: 'Electricity',
  },
  {
    id: 5,
    name: 'tvLicenceIncluded',
    label: 'TV Licence',
  },
  {
    id: 6,
    name: 'cleaningIncluded',
    label: 'Cleaning',
  },
];

export const CheckBoxesAmenities = [
  {
    id: 7,
    name: 'hasKitchenette',
    label: 'Kitchenette',
  },
  {
    id: 8,
    name: 'isDouble',
    label: 'Double',
  },
  {
    id: 9,
    name: 'isFurnished',
    label: 'Furnished',
  },
  {
    id: 10,
    name: 'pets',
    label: 'Pets Allowed',
  },
  {
    id: 11,
    name: 'couplesAllowed',
    label: 'Couples Allowed',
  },
];

const RoomDetailsForm: React.FC<RoomDetailsFormProps> = ({
  handleBack,
  handleNext,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const propertyId = useAppSelector((state) => state.createpropertyState?.data?.id);
  const advertData = useAppSelector((state) => state.createpropertyState?.roomDetails);
  const rentFrequencies = useRequestState(rentFrequenciesList);
  const [frequencies, setFrequencies] = useState<IwithDefault[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    control, watch, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(thirdStepScheme),
    defaultValues: useMemo(() => {
      if (advertData?.id) {
        return { ...advertData };
      }
      return {
        rentFrequencyId: frequencies.find((x) => x.default === true)?.id,
        availableBy: new Date(),
        internetIncluded: false,
        councilTaxIncluded: false,
        gasIncluded: false,
        electricityIncluded: false,
        cleaningIncluded: false,
        tvLicenceIncluded: false,
        isFurnished: false,
        hasKitchenette: false,
        pets: false,
        couplesAllowed: false,
        isDouble: false,
      } as any;
    }, [advertData, frequencies.length, rentFrequencies.value]),
  });
  useScrollToError(errors as any);
  useEffect(() => {
    if (propertyId) {
      rentFrequencies.fetch();
    } else {
      toast.error('create property first before adding details');
      navigate('/create-ad');
    }
  }, []);

  useEffect(() => {
    if (rentFrequencies.value?.length) {
      setFrequencies(rentFrequencies.value || []);
    }
  }, [rentFrequencies.value]);

  const handleForm = async (data: any): Promise<void> => {
    setLoading(true);
    try {
      data.propertyId = propertyId;
      if (!data.rentFrequencyId) {
        data.rentFrequencyId = frequencies.find((x) => x.default === true)?.id;
      }
      if (advertData?.id) {
        await dispatch(editAdvertAction(data, advertData.id));
      } else {
        await dispatch(createAdvertAction(data));
      }
      setLoading(false);
      handleNext();
    } catch (error) {
      setLoading(false);
      toast.error('please check your room advert fields and try again to update');
    }
  };

  const watchRent = watch('rent');
  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={3} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormContainer onSubmit={handleSubmit((data: any) => handleForm(data))} component="form">
          <Box>
            <Controller
              control={control}
              name="adTitle"
              render={({
                field: {
                  onChange, onBlur, value, ref,
                },
              }) => (
                <TextInput
                  id="prop-ad-adTitle"
                  label="Advert title"
                  variant="outlined"
                  name="adTitle"
                  type="text"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ''}
                  ref={ref}
                  fullWidth
                  error={!!errors.adTitle?.message}
                  helperText={errors.adTitle?.message}
                  size="small"
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="note"
              render={({
                field: {
                  onChange, onBlur, value, ref,
                },
              }) => (
                <TextInput
                  id="prop-ad-note"
                  name="note"
                  label="Advert description"
                  variant="outlined"
                  type="text"
                  multiline
                  minRows={3}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ''}
                  ref={ref}
                  fullWidth
                  error={!!errors.note?.message}
                  helperText={errors.note?.message}
                  size="small"
                />
              )}
            />
          </Box>
          <TextInputContainer>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Details For Room Advert</Typography>
            <Divider />
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="rent"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-ad-rent"
                      name="rent"
                      label="rent per month"
                      variant="outlined"
                      type="number"
                      onChange={onChange}
                      onBlur={onBlur}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">&#163;</InputAdornment>,
                      }}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      error={!!errors.rent?.message}
                      helperText={errors.rent?.message}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="deposit"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-ad-deposit"
                      label="Deposit amount"
                      variant="outlined"
                      type="number"
                      name="deposit"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">&#163;</InputAdornment>,
                      }}
                      ref={ref}
                      fullWidth
                      error={!!errors.deposit?.message}
                      helperText={errors.deposit?.message}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="availableBy"
                  render={({
                    field: {
                      onChange, value, ref,
                    },
                  }) => (
                    <MobileDatePicker
                      label="Available from"
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      renderInput={(params) => (
                        <TextInput
                          name="availableBy"
                          fullWidth
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="rentFrequencyId"
                    defaultValue={frequencies.find((x) => x.default === true)?.id || ''}
                    render={({
                      field: {
                        onChange, onBlur, value, ref,
                      },
                    }) => (
                      <TextInput
                        value={value || frequencies.find((x) => x.default === true)?.id || ''}
                        onChange={onChange}
                        size="small"
                        name="rentFrequencyId"
                        label="Minimum tenancy"
                        ref={ref}
                        onBlur={onBlur}
                        select
                      >
                        {frequencies.map((frequency) => (
                          <MenuItem key={frequency.id} value={frequency.id}>{frequency.name}</MenuItem>
                        ))}
                      </TextInput>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="prop-ad-holdingDeposit"
                  variant="outlined"
                  label="Holding Deposit"
                  type="number"
                  value={Math.floor((watchRent * 12) / 52)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">&#163;</InputAdornment>,
                  }}
                  disabled
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <FormControl component="fieldset">
              <FormLabel component="legend" sx={{ mt: 2 }}>Inclusive in rent</FormLabel>
              <RadioGroup row aria-label="included-rent" name="includedRent">
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {CheckBoxesRent.map(({ name, id, label }) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel
                        key={id}
                        value={name}
                        control={(
                          <Controller
                            control={control}
                            name={name}
                            render={({
                              field: {
                                onChange, value, ref,
                              },
                            }) => (
                              <Checkbox
                                name={name}
                                ref={ref}
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                              />
                            )}
                          />
)}
                        label={label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend" sx={{ mt: 2 }}>Room Amenities</FormLabel>
              <RadioGroup row aria-label="room-amenities" name="roomAmenities">
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {CheckBoxesAmenities.map(({ name, id, label }) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel
                        key={id}
                        value={name}
                        control={(
                          <Controller
                            control={control}
                            name={name}
                            render={({
                              field: {
                                onChange, value, ref,
                              },
                            }) => (
                              <Checkbox
                                name={name}
                                ref={ref}
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                              />
                            )}
                          />
)}
                        label={label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </TextInputContainer>
          <Controller
            control={control}
            name="youtubeLink"
            defaultValue=""
            render={({
              field: {
                onChange, value, onBlur, ref,
              },
            }) => (
              <TextInput
                name="youtubeLink"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                size="small"
                label="Youtube Link"
                helperText={errors?.youtubeLink?.message}
                error={!!errors?.youtubeLink?.message}
              />
            )}
          />
          <BottomBtn
            typeSubmit="submit"
            loading={loading}
            handleNext={handleSubmit((data: any) => handleForm(data))}
            handleBack={() => handleBack()}
          />
        </FormContainer>
      </LocalizationProvider>
    </ApplicationWrapper>
  );
};

export default RoomDetailsForm;
