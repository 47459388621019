import {
  Grid,
  Typography,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import React, { useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { steps } from '../../../views/CreateAd';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import {
  ScreeningContainer,
  FormContainer,
  ScreeningInput,
} from '../styles';
import BottomBtn from '../common/ButtonContainer';
import { useRequestState } from '../../../hooks/request';
import { screeningQuestionsList } from '../../../sdk/propertyApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createQuestionsReducer } from '../../../store/reducers/createProperty';
import { createQuestionAction, editQuestionAction, removeQuestionAction } from '../../../store/actions/PropertyActions';
import AddQuestion from './modal';
import Instructions from './Instructions';
import ConfirmQuestions from './ModalConfirm';

interface ScreeningQuestionViewProps {
  handleBack(): void;
  handleNext(): void;
}

const ScreeningQuestionView: React.FC<ScreeningQuestionViewProps> = ({
  handleBack,
  handleNext,
}) => {
  const dispatch = useAppDispatch();
  const { screeningQuestions } = useAppSelector((state) => state.createpropertyState);
  const navigate = useNavigate();
  const requestQuestions = useRequestState(screeningQuestionsList);
  const advertData = useAppSelector((state) => state.createpropertyState?.roomDetails);
  const [open, setOpen] = React.useState(false);
  const [questionsNumber, setQuestionsNumber] = React.useState<any>({});
  const [openQuestions, setOpenQuestions] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (advertData?.id) {
      if (!screeningQuestions.length) {
        requestQuestions.fetch(advertData?.id as string);
      }
    } else {
      toast.error('create an advert first and then try again');
      navigate('/create-ad/room-details');
    }
  }, []);

  useEffect(() => {
    if (requestQuestions.value?.length && !screeningQuestions.length) {
      requestQuestions.value.forEach((el) => dispatch(createQuestionsReducer(el)));
    }
  }, [requestQuestions.value]);

  const handleRemoveQuestion = async (id: string): Promise<void> => {
    setLoading(true);
    try {
      await dispatch(removeQuestionAction(id));
    } catch (error) {
      toast.error('can\'t delete the question, please try again later');
    }
    setLoading(false);
  };

  const handleEditQuestionValue = async (id: string, data: any): Promise<void> => {
    setLoading(true);
    try {
      await dispatch(editQuestionAction(id, data));
    } catch (error) {
      toast.error('unable to update the question, please try again later');
    }
    setLoading(false);
  };
  const debounced = useDebouncedCallback((id: string, data: any) => {
    handleEditQuestionValue(id, data);
  }, 1000);

  const handleEditQuestion = async (id: string, data: any): Promise<void> => {
    setLoading(true);
    try {
      await dispatch(editQuestionAction(id, data));
    } catch (error) {
      toast.error('unable to update the question, please try again later');
    }
    setLoading(false);
  };

  const handleAddQuestion = async (data: any): Promise<void> => {
    try {
      if (data.desiredAnswer === 'value') {
        const newData = {
          advertId: advertData?.id as string,
          type: 'value',
          question: data.question,
          minimumValue: Number(data.minimumValue),
        };
        await dispatch(createQuestionAction(newData));
      } else {
        const newData = {
          advertId: advertData?.id as string,
          type: 'yesno',
          question: data.question,
          desiredAnswer: data.desiredAnswer === 'yes',
        };
        await dispatch(createQuestionAction(newData));
      }
      setOpen(false);
    } catch (error) {
      toast.error('unable to add the question, please try again later');
    }
  };

  const handleValidateQuestions = (event: any): void => {
    event.preventDefault();
    const questions = [...screeningQuestions];
    const valueQuestions = questions.filter((el) => el.type === 'value' && !el.minimumValue);
    if (valueQuestions.length) {
      toast.error('Please fill in the minimum value for all questions');
    } else {
      setOpenQuestions(true);
    }
  };

  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={5} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      <FormContainer component="form">
        <Grid container spacing={2}>
          <Grid sm={10} xs={12} item>
            <Typography color="primary" variant="h4" sx={{ fontWeight: 500 }}>Screening Questions</Typography>
          </Grid>
          <Grid sm={10} xs={12} item>
            <Instructions />
          </Grid>
          {screeningQuestions.map((dfn) => (
            <Grid sm={10} xs={12} item key={dfn.id}>
              <ScreeningContainer>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Typography
                    sx={{ fontWeight: '700' }}
                  >
                    {dfn.question}
                  </Typography>
                  <IconButton onClick={() => handleRemoveQuestion(dfn.id as string)}>
                    <CancelIcon color="error" />
                  </IconButton>
                </Box>
                {dfn.type === 'value' ? (
                  <Box sx={{ width: '90%' }} display="flex" justifyContent="space-between">
                    <Typography>Suitable applicants will input number above</Typography>
                    <ScreeningInput
                      onChange={(e) => {
                        setQuestionsNumber((
                          prevState: any,
                        ) => ({ ...prevState, [dfn.id as string]: Number(e.target.value) }));
                        debounced(
                          dfn.id as string, { minimumValue: Number(e.target.value) || 0 },
                        );
                      }}
                      value={
                        questionsNumber[dfn.id as string]
                        || (questionsNumber[dfn.id as string] === 0 && ' ') || dfn.minimumValue || ''
                      }
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                    />
                  </Box>
                )
                  : (
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ mt: 2 }}>Desired answer</FormLabel>
                      <RadioGroup
                        row
                        aria-label="desired answer"
                        name="desiredAnswer"
                        value={dfn.desiredAnswer}
                        onChange={(e) => handleEditQuestion(
                          dfn.id as string, { desiredAnswer: e.target.value === 'true' },
                        )}
                      >
                        <FormControlLabel value control={<Radio />} label="yes" />
                        <FormControlLabel value={false} control={<Radio />} label="no" />
                      </RadioGroup>
                    </FormControl>
                  )}
              </ScreeningContainer>
            </Grid>
          ))}
          <Grid sm={10} item>
            <Button variant="contained" onClick={() => setOpen(true)}>Add New Question</Button>
            <AddQuestion
              open={open}
              handleClose={() => setOpen(false)}
              handleData={handleAddQuestion}
            />
            <ConfirmQuestions
              open={openQuestions}
              handleClose={() => setOpenQuestions(false)}
              handleNext={handleNext}
              screeningQuestions={screeningQuestions}
            />
          </Grid>
        </Grid>
        <BottomBtn
          loading={loading}
          typeSubmit="submit"
          handleNext={handleValidateQuestions}
          handleBack={handleBack}
        />
      </FormContainer>
    </ApplicationWrapper>
  );
};

export default ScreeningQuestionView;
