import { compact } from 'lodash';
import { IPropertyParams } from '../../sdk/propertyApi';

const address = (property: IPropertyParams): string => {
  const {
    flatNumber,
    buildingNameOrNumber,
    streetName,
    postalCode,
    town,
  } = property;

  const addressLines = [
    flatNumber ? `Flat ${flatNumber}` : null,
    `${buildingNameOrNumber} ${streetName}`,
    postalCode,
    town,
  ];

  return compact(addressLines)
    .join(', ');
};

export default address;
