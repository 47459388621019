import { AxiosError } from 'axios';
import React from 'react';

type ArgType =
  | [unknown?]
  | [unknown?, unknown?]
  | [unknown?, unknown?, unknown?]
  | [unknown?, unknown?, unknown?, unknown?];

type AsyncFunction<A extends ArgType, V> = (...a: A) => Promise<V>;

interface AsyncValue<A extends ArgType, V> {
  loading: boolean;
  value: V;
  error: any;
  setError: React.Dispatch<React.SetStateAction<any>>;
  fetch(...a: A): Promise<V>;
}

export function useRequestState<A extends ArgType, V>(
  request: AsyncFunction<A, V>,
  defaultValue: V = null as unknown as V,
): AsyncValue<A, V> {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(defaultValue);
  const [error, setError] = React.useState<any>(null);

  function fetch(...a: A): Promise<V> {
    setLoading(true);
    return request(...a)
      .then((v) => {
        setValue(v);
        setLoading(false);
        return v;
      })
      .catch((err: AxiosError) => {
        setError(err.response || err);
        setLoading(false);
        return null as unknown as V;
      });
  }
  return {
    loading, value, error, fetch, setError,
  };
}
