import { ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { ImenuElement } from '.';
import { ItemContainer, MenuImg } from './styles';

interface DrawerViewProps {
  drawerItems: ImenuElement[];
  handleMenuClick(menu: ImenuElement): void;
  selectedMenu: number | string;
}

const DrawerList: React.FC<DrawerViewProps> = ({
  drawerItems,
  selectedMenu,
  handleMenuClick,
}) => (
  <>
    {drawerItems.map((menu) => (
      <React.Fragment key={menu.title}>
        <ItemContainer
          onClick={() => handleMenuClick(menu)}
          key={menu.title}
          selected={selectedMenu === menu.id}
        >
          <ListItemIcon>
            <MenuImg />
          </ListItemIcon>
          <ListItemText
            primary={menu.title}
            primaryTypographyProps={{ sx: { fontWeight: 'bold' } }}
          />
        </ItemContainer>
      </React.Fragment>
    ))}
  </>
);

export default DrawerList;
