import React from 'react';
import AdsView from '../components/Home/Ads';
import ApplicationWrapper from '../components/layout/ApplicationWrapper';
import usePageTitle from '../hooks/pageTitle';

const menuElements: { id: string | number; title: string; icon: string; link: string; }[] = [
  {
    title: 'Adverts',
    id: 1,
    icon: '../../assets/icons/portfolio.svg',
    link: '/dashboard',
  },
  {
    title: 'Lead Manager',
    id: 2,
    icon: '/icons/portfolio',
    link: '/lead-manager',
  },
  {
    title: 'Viewings Calendar',
    id: 3,
    icon: '/icons/portfolio',
    link: '/upcoming-view',
  },
];

const Home: React.FC = () => {
  usePageTitle('properties');
  return (
    <ApplicationWrapper customSideNav={null} activeMenu={1} menuElements={menuElements}>
      <>
        <AdsView />
      </>
    </ApplicationWrapper>
  );
};

export default Home;
