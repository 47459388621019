import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProperty } from '../../sdk/propertyApi';

export interface PropertyAddress {
  buildingNameOrNumber: string;
  streetName: string;
  town: string;
  postalCode: string;
  flatNumber: string;
  typeId: string;
}

export interface IBathroom {
  id?: string;
  propertyId: string;
  hasToilet: boolean;
  hasShower: boolean;
  hasBath: boolean;
}

export interface IKitchen {
  id?: string;
  propertyId: string;
  microwaves: number;
  numberOfHobs: number;
  fridges: number;
  ovens: number;
  freezers: number;
}

export interface IAmenities {
  id: string;
  name: string;
}

export interface ICert {
  url: string; expiry?: string; type: string;
}

export interface IPropertyCerts {
  [key: string]: ICert
}

export interface Iphoto {id: string, file: { name: string, url: string }}

export interface IRoomDetails {
    id?: string;
    propertyId: string;
    adTitle: string;
    note: string;
    deposit: number;
    availableBy: string;
    rent: number;
    rentFrequencyId: string;
    internetIncluded: boolean;
    councilTaxIncluded: boolean;
    gasIncluded: boolean;
    electricityIncluded: boolean;
    cleaningIncluded: boolean;
    tvLicenceIncluded: boolean;
    isFurnished: boolean;
    hasKitchenette: boolean;
    pets: boolean;
    couplesAllowed: boolean;
    isDouble: boolean;
    youtubeLink?: string;
    published?: boolean;
    virtualMeetingDetails?: string;
}

export interface IScreeninQuestions {
    id?: string;
    advertId: string;
    type: string;
    question: string;
    minimumValue?: number;
    desiredAnswer?: boolean;
}

export interface InternetConnection {
  speed: number;
  provider: string;
  wifi: boolean;
  hardwired: boolean;
  internetConnectionTypeId: string;
}

export interface IViewingSlot {
  dateAndTime: string;
  advertId?: string;
  id: string;
  isOnsite: boolean;
  isVirtual: boolean;
  maxAttendees: number;
}

export interface ICreatePropertyState {
  data: IProperty;
  formRoute: | '/create-ad'
             | '/create-ad/property-address'
             | '/create-ad/property-details'
             | '/create-ad/room-details'
             | '/create-ad/viewing-slots'
             | '/create-ad/ad-summary';
  address: PropertyAddress | null;
  bathrooms: IBathroom[];
  kitchens: IKitchen[];
  propertyAmenities: IAmenities[];
  propertyCerts: IPropertyCerts;
  roomDetails: IRoomDetails | null;
  screeningQuestions: IScreeninQuestions[];
  viewingSlots: IViewingSlot[];
  edit: boolean;
  isEditAdvert: boolean;
  id: string;
  photos: Iphoto[];
  thumbnail: string;
  published: boolean;
}

const initialState: ICreatePropertyState = {
  data: {} as any,
  id: '',
  formRoute: '/create-ad',
  address: null,
  bathrooms: [],
  kitchens: [],
  propertyAmenities: [],
  propertyCerts: {},
  roomDetails: {} as any,
  photos: [],
  screeningQuestions: [],
  viewingSlots: [],
  edit: false,
  isEditAdvert: false,
  published: false,
  thumbnail: '',
};

export const propertySlice = createSlice({
  name: 'createAd',
  initialState,
  reducers: {
    createPropertyReducer: (
      state,
      action: PayloadAction<IProperty>,
    ) => {
      state.data = action.payload;
    },
    updatePropertyReducer: (
      state,
      action: PayloadAction<IProperty>,
    ) => {
      state.data = { ...state.data, ...action.payload };
    },
    addPropertyAdrress: (
      state,
      action,
    ) => {
      state.address = action.payload;
    },
    addPropertyDetails: (
      state,
      action,
    ) => {
      state.address = action.payload;
    },
    addBathroomsReducer: (
      state,
      action: PayloadAction<IBathroom>,
    ) => {
      state.bathrooms.push(action.payload);
    },
    editBathroomsReducer: (
      state,
      action: PayloadAction<IBathroom>,
    ) => {
      const bathIndex = state.bathrooms.findIndex(((obj) => obj.id === action.payload.id));
      state.bathrooms[bathIndex] = action.payload;
    },
    removeBathroomsReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      const bathIndex = state.bathrooms.findIndex(((obj) => obj.id === action.payload));
      state.bathrooms.splice(bathIndex, 1);
    },
    addKitchensReducer: (
      state,
      action: PayloadAction<IKitchen>,
    ) => {
      state.kitchens.push(action.payload);
    },
    editKitchensReducer: (
      state,
      action: PayloadAction<IKitchen>,
    ) => {
      const kitchenIndex = state.kitchens.findIndex(((obj) => obj.id === action.payload.id));
      state.kitchens[kitchenIndex] = action.payload;
    },
    removeKitchensReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      const kitchenIndex = state.kitchens.findIndex(((obj) => obj.id === action.payload));
      state.kitchens.splice(kitchenIndex, 1);
    },
    addPropertyAmenities: (
      state,
      action: PayloadAction<IAmenities>,
    ) => {
      state.propertyAmenities.push(action.payload);
    },
    removePropertyAmenities: (
      state,
      action: PayloadAction<string>,
    ) => {
      const amenityIndex = state.propertyAmenities.findIndex(((obj) => obj.id === action.payload));
      state.propertyAmenities.splice(amenityIndex, 1);
    },
    createAdvertReducer: (
      state,
      action: PayloadAction<IRoomDetails>,
    ) => {
      state.roomDetails = action.payload;
    },
    createQuestionsReducer: (
      state,
      action: PayloadAction<IScreeninQuestions>,
    ) => {
      state.screeningQuestions.push(action.payload);
    },
    editQuestionsReducer: (
      state,
      action: PayloadAction<IScreeninQuestions>,
    ) => {
      const SIndex = state.screeningQuestions.findIndex(((obj) => obj.id === action.payload.id));
      state.screeningQuestions[SIndex] = action.payload;
    },
    removeQuestionsReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      const SIndex = state.screeningQuestions.findIndex(((obj) => obj.id === action.payload));
      state.screeningQuestions.splice(SIndex, 1);
    },
    internetConnctionReducer: (
      state,
      action: PayloadAction<InternetConnection>,
    ) => {
      state.data.internetConnection = action.payload;
    },
    addViewingSlotReducer: (
      state,
      action: PayloadAction<IViewingSlot>,
    ) => {
      state.viewingSlots.push(action.payload);
    },
    removeViewingSlotReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      const slotIndex = state.viewingSlots.findIndex(((obj) => obj.id === action.payload));
      state.viewingSlots.splice(slotIndex, 1);
    },
    addCertsReducer: (
      state,
      action: PayloadAction<ICert>,
    ) => {
      state.propertyCerts[action.payload.type] = action.payload;
    },
    removeCertsReducer: (
      state,
      action: PayloadAction<{ type: string; expiryType?: string; }>,
    ) => {
      delete state.propertyCerts[action.payload.type];
      if (action.payload.expiryType) (state.data as any)[action.payload.expiryType] = '';
    },
    addPhotoReducer: (
      state,
      action: PayloadAction<Iphoto>,
    ) => {
      state.photos.push(action.payload);
    },
    removePhotoReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      const photoIndex = state.photos.findIndex(((obj) => obj.id === action.payload));
      state.photos.splice(photoIndex, 1);
      if (action.payload === state.thumbnail) {
        state.thumbnail = '';
      }
    },
    updateThumbnailReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.thumbnail = action.payload;
    },
    resetStateReducer: () => initialState,
    editStateReducer: (
      state,
      action: PayloadAction<ICreatePropertyState>,
    ) => action.payload,
  },
});

export const {
  createPropertyReducer,
  updatePropertyReducer,
  addPropertyAdrress,
  addBathroomsReducer,
  editBathroomsReducer,
  removeBathroomsReducer,
  addKitchensReducer,
  editKitchensReducer,
  removeKitchensReducer,
  createAdvertReducer,
  createQuestionsReducer,
  addPropertyAmenities,
  removePropertyAmenities,
  internetConnctionReducer,
  addViewingSlotReducer,
  removeViewingSlotReducer,
  editQuestionsReducer,
  removeQuestionsReducer,
  addCertsReducer,
  addPhotoReducer,
  removePhotoReducer,
  updateThumbnailReducer,
  resetStateReducer,
  editStateReducer,
  removeCertsReducer,
} = propertySlice.actions;

export default propertySlice.reducer;
