import {
  FormControl,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FormContainer,
  TextInput,
} from '../styles';
import BottomBtn from '../common/ButtonContainer';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import { steps } from '../../../views/CreateAd';
import { useRequestState } from '../../../hooks/request';
import { listProperty } from '../../../sdk/propertyApi';
import { useAppDispatch } from '../../../store/hooks';
import { useExistingProperty as updateExistingProperty } from '../../../store/actions/PropertyActions';
import { resetStateReducer } from '../../../store/reducers/createProperty';

interface SelectPropertyProps {
  value: string;
  setProperty(value: string): void;
  handleNext(): void;
}

const SelectProperty: React.FC<SelectPropertyProps> = ({
  value,
  setProperty,
  handleNext,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const propertiesList = useRequestState(listProperty);
  const handleChange = (event: any): void => {
    setProperty(event.target.value as string);
  };
  const handleGoNext = async (): Promise<void> => {
    if (value !== 'add') {
      try {
        setLoading(true);
        await dispatch(updateExistingProperty(value));
        setLoading(false);
        navigate('/create-ad/room-details');
      } catch (error) {
        setLoading(false);
        toast.error('something went wrong while fetching property details, please try again later');
      }
    } else {
      dispatch(resetStateReducer());
      handleNext();
    }
  };

  React.useEffect(() => {
    propertiesList.fetch();
  }, []);

  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={0} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      <FormContainer>
        <FormControl sx={{ margin: '12px 0' }}>
          <TextInput
            id="select-property"
            value={value}
            select
            label="Select Property"
            onChange={handleChange}
          >
            <MenuItem value="add">+ ADD NEW PROPERTY</MenuItem>
            {propertiesList?.value?.map((prop) => (
              <MenuItem value={prop.id}>
                {prop.buildingNameOrNumber}
                {', '}
                {prop.streetName}
              </MenuItem>
            ))}
          </TextInput>
        </FormControl>
        <BottomBtn
          typeSubmit="button"
          handleNext={handleGoNext}
          loading={loading}
          handleBack={() => navigate('/dashboard')}
        />
      </FormContainer>
    </ApplicationWrapper>
  );
};

export default SelectProperty;
