import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Box, Link } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import React from 'react';
import { PropertyWithIcon } from '../LeadCard';
import { LeadViewer } from '../../../sdk/leads';

interface Props {
  viewer: LeadViewer;
}

const LeadViewerDetails = (props: Props): JSX.Element => {
  const { viewer } = props;
  const {
    email,
    phone,
    name,
  } = viewer;

  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneUtil.parse(phone);

  const formattedPhoneNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);

  return (
    <Box>
      {
        name
        && (
          <PropertyWithIcon icon={PersonIcon} sx={{ marginBottom: 0.3 }}>
            {name}
          </PropertyWithIcon>
        )
      }

      {
        email
        && (
          <PropertyWithIcon icon={EmailIcon} sx={{ marginBottom: 0.3 }}>
            <Link href={`mailto:${email}`} color="inherit">{email}</Link>
          </PropertyWithIcon>
        )
      }

      <PropertyWithIcon icon={PhoneIcon}>
        <Link href={`tel:${phone}`} color="inherit">{formattedPhoneNumber}</Link>
      </PropertyWithIcon>
    </Box>
  );
};
export default LeadViewerDetails;
