import React from 'react';
import { toast } from 'react-toastify';
import {
  format, compareAsc, isBefore, subWeeks, isAfter,
} from 'date-fns';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SingleLead } from '../LeadManager/LeadManagerContainer';
import ViewingsTimeline from './ViewingsTimeline';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getLeadListAction } from '../../store/actions/LeadActions';
import { ILead } from '../../sdk/leads';

const UpcomingNextView: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobileTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = React.useState<any[]>([]);
  const [pastData, setPastData] = React.useState<any[]>([]);
  const [expanded, setExpanded] = React.useState('');
  const leadsList = useAppSelector((state) => state.leadState.leads);

  const getLeads = async (): Promise<void> => {
    try {
      await dispatch(getLeadListAction());
    } catch (error) {
      toast.error('something went while fetching your leads, please contact support');
    }
  };
  React.useEffect(() => {
    getLeads();
  }, []);

  const handleExpand = (id: string): void => {
    if (id === expanded) {
      setExpanded('');
      return;
    }
    setExpanded(id);
  };
  const cleanData = (arr: ILead[]): any => {
    if (arr.length > 0) {
      const filtered = arr?.filter((
        lead,
      ) => (
        lead.status === 'confirmed'
        || lead.status === 'booked')
        && !!lead.viewingSlot);
      const sortSLots = filtered.sort(
        (dateObjA, dateObjB) => compareAsc(
          new Date(dateObjA?.viewingSlot?.dateAndTime || new Date()),
          new Date(dateObjB?.viewingSlot?.dateAndTime || new Date()),
        ),
      );
      let checked = false;
      const latestSlots = sortSLots.map((date) => {
        const curr = new Date(date.viewingSlot?.dateAndTime as string).getTime();
        const now = new Date().getTime();
        if (!checked && curr >= now) {
          checked = true;
          return {
            ...date,
            viewingSlot: {
              id: date.viewingSlot?.id,
              dateAndTime: date.viewingSlot?.dateAndTime,
              latest: true,
            },
          };
        }
        return {
          ...date,
          viewingSlot: {
            id: date.viewingSlot?.id,
            dateAndTime: date.viewingSlot?.dateAndTime,
            latest: false,
          },
        };
      });
      const finalDates: any[] = [];
      const pastDates: any[] = [];
      const presentDates: any[] = [];
      latestSlots.forEach((slot) => {
        const date = slot?.viewingSlot?.dateAndTime?.split?.('T')?.[0];
        const foundDateIndex = finalDates.findIndex((e) => e.date === date);
        if (foundDateIndex > -1) {
          finalDates[foundDateIndex].slots.push(slot);
          finalDates[foundDateIndex].open = finalDates[foundDateIndex].open || slot?.viewingSlot?.latest;
          if (finalDates[foundDateIndex].open) {
            handleExpand(date as string);
          }
        } else {
          if (slot?.viewingSlot?.latest) {
            handleExpand(date as string);
          }

          finalDates.push({
            date,
            past: isBefore(new Date(date as string), new Date()),
            open: slot?.viewingSlot?.latest,
            name: `${format(new Date(date as string), 'cccc do')} of ${format(new Date(date as string), 'MMMM yyyy')}`,
            slots: [slot],
          });
        }
      });

      finalDates.forEach((elem) => {
        if (elem.past) {
          if (isAfter(new Date(elem.date), subWeeks(new Date(), 1))) {
            pastDates.unshift(elem);
          }
        } else {
          presentDates.push(elem);
        }
      });
      setData(presentDates);
      setPastData(pastDates);
    }
    return [];
  };

  React.useEffect(() => {
    if (leadsList.length) {
      cleanData(leadsList);
    }
  }, [leadsList]);

  return (
    <Container maxWidth="lg">
      <Box paddingTop={2} paddingBottom={2}>
        {data.map((row: any) => {
          const id = row.date;

          return (
            <Accordion
              sx={{
                borderColor: '#485fc7',
                backgroundColor: '#fff',
                borderStyle: 'solid',
                borderWidth: 0,
                borderLeftWidth: 4,
                marginBottom: 2,
              }}
              key={id}
              expanded={expanded === id}
              onChange={() => handleExpand(id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
              >
                <Typography><b>{row.name}</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ViewingsTimeline leads={row.slots} />
              </AccordionDetails>
            </Accordion>
          );
        })}
        {pastData.length > 0 && (
        <Typography
          sx={{
            mt: 5,
            mb: 2,
          }}
          variant="h5"
        >
          Past Viewings
        </Typography>
        )}
        {pastData.map((row: any) => {
          const id = row.date;
          return (
            <Accordion
              sx={{
                borderColor: '#485fc7',
                backgroundColor: '#fff',
                borderStyle: 'solid',
                borderWidth: 0,
                borderLeftWidth: 4,
                marginBottom: 2,
              }}
              key={id}
              expanded={expanded === id}
              onChange={() => handleExpand(id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
              >
                <Typography><b>{row.name}</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                {row.slots.map(
                  (slot: any) => (
                    <SingleLead
                      lead={slot}
                      leadsList={leadsList}
                    />
                  ),
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Container>
  );
};

export default UpcomingNextView;
