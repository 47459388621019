import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoomDetails } from './createProperty';

export interface IPropertyState {
  data: IRoomDetails[];
  showWelcome?: boolean;
}

const initialState: IPropertyState = {
  data: [],
  showWelcome: false,
};

export const advertListSlice = createSlice({
  name: 'advert-list',
  initialState,
  reducers: {
    advertListReducer: (
      state,
      action: PayloadAction<IRoomDetails[]>,
    ) => {
      state.data = action.payload;
    },
    togglePublish: (
      state,
      action: PayloadAction<IRoomDetails>,
    ) => {
      const propIndex = state.data.findIndex(((obj) => obj.id === action.payload.id));
      state.data[propIndex] = action.payload;
    },
    toggleWelcomeModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showWelcome = action.payload;
    },
  },
});

export const {
  advertListReducer,
  togglePublish,
  toggleWelcomeModal,
} = advertListSlice.actions;

export default advertListSlice.reducer;
