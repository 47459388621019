import { Box, Button, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import { checkoutAdvert } from '../../sdk/payments';
import { resetStateReducer } from '../../store/reducers/createProperty';
import { useAppDispatch } from '../../store/hooks';

interface PricingPlan {
  title: string;
  priceId: string;
  price: number;
  features: Array<string>;
}

const PRICING_PLANS: Record<string, PricingPlan> = {
  basic: {
    title: 'Basic',
    priceId: 'price_1MifhIHs3DdlB4MtvSSyhJIv',
    price: 8,
    features: [
      'FB Marketplace Ad',
      'Gumtree Ad',
      'Houseladder Ad',
      'Instant reply 24/7',
      'Pre-screening',
      'Book viewings',
      'Confirm bookings',
    ],
  },
  standard: {
    title: 'Standard',
    priceId: 'price_1Mm1oTHs3DdlB4MtnKoaWpRc',
    price: 29,
    features: [
      'Bold Spareroom Ad',
      'Zoopla Ad',
      'FB Marketplace Ad',
      'Gumtree Ad',
      'Houseladder Ad',
      'Houseshop Ad',
      'Instant reply 24/7',
      'Pre-screening',
      'Book viewings',
      'Confirm bookings',
      'Whatsapp/SMS support',
    ],
  },
  premium: {
    title: 'Premium',
    priceId: 'price_1MifYDHs3DdlB4Mtw2lwfAZe',
    price: 59,
    features: [
      'Rightmove Ad',
      'Feat. Spareroom Ad',
      'FB Marketplace Ad',
      'Bumped Gumtree Ad',
      'Premium Zoopla Advert',
      'Houseladder Ad',
      'Houseshop Ad',
      'Instant reply 24/7',
      'Pre-screening',
      'Book viewings',
      'Confirm bookings',
      'Whatsapp/SMS/Voice support',
    ],
  },
};

export const ListStyle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const FeatureItem = (props: { children: ReactNode }): JSX.Element => {
  const { children } = props;

  return (
    <ListStyle variant="body2">
      <CheckCircleOutlineIcon sx={{ mr: 1 }} color="success" fontSize="small" />
      {children}
    </ListStyle>
  );
};

interface Props {
  advertId: string;
}

const ProductSelect = (props: Props): JSX.Element => {
  const { advertId } = props;
  const dispatch = useAppDispatch();

  const onSelect = (plan: PricingPlan): void => {
    checkoutAdvert(advertId, plan.priceId)
      .then((response) => {
        dispatch(resetStateReducer);
        window.location.href = response.url;
      })
      .catch(() => {
        toast.error('something went wrong while trying to checkout your order');
      });
  };

  return (
    <Box display="flex">
      {
        map(PRICING_PLANS, (plan: PricingPlan) => (
          <Box
            key={plan.priceId}
            display="flex"
            flexDirection="column"
            sx={{
              mx: 4,
              py: 2,
              px: 4,
              width: '30%',
              border: '1px solid #a1a1a1',
              borderRadius: 4,
            }}
          >
            <Box flexGrow={1}>
              <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" sx={{ mb: 4 }}>
                <Typography variant="h5" component="div">{plan.title}</Typography>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  color="primary"
                >
                  &#163;
                  {plan.price}
                </Typography>
                <Typography component="div" variant="body2" color="textSecondary" sx={{ mx: 1, textAlign: 'center' }}>
                  single listing
                </Typography>
              </Box>

              {
                  plan.features.map((service: string) => <FeatureItem key={service}>{service}</FeatureItem>)
                }
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth onClick={() => onSelect(plan)}>Select</Button>
            </Box>
          </Box>
        ))
      }

    </Box>
  );
};

export default ProductSelect;
