import React from 'react';
import {
  Navigate,
} from 'react-router-dom';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
};

export default function ProtectedRoute({
  isAuthenticated, authenticationPath, children,
}: ProtectedRouteProps & { children: JSX.Element; }): JSX.Element {
  if (isAuthenticated) {
    return children;
  }
  return <Navigate replace to={{ pathname: authenticationPath }} />;
}
