import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
} from '@mui/material';
import {
  TextInput,
  FormContainer,
  LinkView,
  ButtonView as Button,
} from './styles';
import { resetSchema } from './validations';

interface IResetProps {
  handleReset(userEmail: { username: string }): void;
  loading?: boolean;
}

interface IResetFormParams {
  username: string
}

const ResetPasswordForm: React.FC<IResetProps> = ({ handleReset, loading }): JSX.Element => {
  const {
    control, handleSubmit, formState: { errors },
  } = useForm<IResetFormParams>({
    resolver: yupResolver(resetSchema),
  });

  return (
    <FormContainer
      component="form"
      onSubmit={handleSubmit((data: IResetFormParams) => handleReset(data))}
    >
      <Box>
        <Controller
          control={control}
          name="username"
          render={({
            field: {
              onChange, onBlur, value, ref,
            },
          }) => (
            <TextInput
              id="email-login"
              label="email"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type="email"
              fullWidth
              size="small"
              helperText={errors.username?.message || ''}
              error={!!errors.username?.message}
            />
          )}
        />
      </Box>
      <LinkView type="button" to="/login">Go back to Log in</LinkView>
      <Button loading={loading} type="submit" variant="contained">Reset Password</Button>
    </FormContainer>
  );
};

ResetPasswordForm.defaultProps = {
  loading: false,
};

export default ResetPasswordForm;
