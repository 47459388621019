import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Button,
  Typography,
  Box,
  FormControl,
  MenuItem,
  Chip,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText,
  Divider,
  Alert,
  CircularProgress,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import BathtubIcon from '@mui/icons-material/Bathtub';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import KitchenIcon from '@mui/icons-material/Kitchen';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import {
  FormContainer,
  CheckBoxContainer,
  CheckBoxLabel,
  AddCheckBoxContainer,
  Label,
  ChipContainer,
  TextInputContainer,
  TextInput,
  KitchenField,
  CertDocumentContainer,
} from '../styles';
import BottomBtn from '../common/ButtonContainer';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import { steps } from '../../../views/CreateAd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addBathroomAction,
  addDocumentsCertsAction,
  addKitchenAction,
  amenitiesAction,
  editBathroomAction,
  editKitchenAction,
  internetConnectionsAction,
  removeBathroomAction,
  removeDocumentsCertsAction,
  removeKitchenAction,
  updatePropertyAction,
} from '../../../store/actions/PropertyActions';
import { IBathroom, IKitchen } from '../../../store/reducers/createProperty';
import { secondStepScheme } from '../validations';
import { listAmenities, listInternetTypes, listTenantTypes } from '../../../sdk/propertyApi';
import { useRequestState } from '../../../hooks/request';
import useScrollToError from '../../../hooks/useScrollToError';

interface IBathroomProps {
  hasToilet: boolean;
  hasShower: boolean;
  hasBath: boolean;
}

interface IkitchenProps {
  microwaves: number;
  numberOfHobs: number;
  fridges: number;
  ovens: number;
  freezers: number;
}

const defaultBathroom = {
  hasToilet: false,
  hasShower: false,
  hasBath: false,
};

const defaultKitchen = {
  microwaves: 0,
  numberOfHobs: 0,
  fridges: 0,
  ovens: 0,
  freezers: 0,
};

interface PropertyDetailsFormProps {
  handleForm(): void;
  handleBack(): void;
}

interface amenitiesTypes {
  id: string;
  name: string
}

export const predCerts = [
  {
    name: 'EPC',
    type: 'epc',
    expiryName: 'epcExpiry',
  },
  {
    name: 'Gas',
    type: 'gas',
    expiryName: 'gasExpiry',
  },
  {
    name: 'Fire Safety',
    type: 'fireSafety',
    expiryName: 'fireSafetyExpiry',
  },
  {
    name: 'Floor Plan',
    type: 'floorPlan',
  },
  {
    name: 'EICR',
    type: 'niceic',
    expiryName: 'niceicExpiry',
  },
  {
    name: 'HMO Licence',
    type: 'hmoLicence',
    expiryName: 'hmoLicenceExpiry',
  },
];

const HIDE_DOCUMENT_UPLOAD = true as const;

const PropertyDetailsForm: React.FC<PropertyDetailsFormProps> = ({
  handleForm,
  handleBack,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const propertyData = useAppSelector((state) => state.createpropertyState);
  const [loading, setLoading] = useState(false);
  const {
    bathrooms,
    kitchens,
    propertyAmenities,
    data,
    propertyCerts,
    edit,
  } = propertyData;
  const [amenities, setAmenity] = React.useState<amenitiesTypes[]>([]);
  const [tenantypesState, setTenantTypes] = React.useState<amenitiesTypes[]>([]);
  const [internetypesState, setInternetTypes] = React.useState<amenitiesTypes[]>([]);
  const [certSelected, setCertSelected] = React.useState<string>('');
  const {
    control, handleSubmit, formState: { errors },
  } = useForm({
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(secondStepScheme),
    defaultValues: useMemo(() => {
      if (data?.id) {
        return {
          livingRooms: data?.livingRooms,
          maxOccupancy: data?.maxOccupancy,
          males: data?.males,
          females: data?.females,
          maxAge: data?.maxAge,
          minAge: data?.minAge,
          tenantTypeId: data?.tenantTypeId,
          speed: data?.internetConnection?.speed,
          hardwired: data?.internetConnection?.hardwired,
          internetConnectionTypeId: data?.internetConnection?.internetConnectionTypeId,
          wifi: data?.internetConnection?.wifi,
          provider: data?.internetConnection?.provider,
          epcExpiry: data?.epcExpiry,
          hmoLicenceExpiry: data?.hmoLicenceExpiry,
          fireSafetyExpiry: data?.fireSafetyExpiry,
          gasExpiry: data?.gasExpiry,
        };
      }
      return {
        livingRooms: null,
        maxOccopancy: null,
        males: null,
        females: null,
        maxAge: null,
        minAge: null,
      } as any;
    }, [data]),
  });
  useScrollToError(errors);
  const tenantsTypes = useRequestState(listTenantTypes);
  const internetTypes = useRequestState(listInternetTypes);
  const amenitiesList = useRequestState(listAmenities);

  React.useEffect(() => {
    if (propertyData?.data?.id) {
      tenantsTypes.fetch();
      internetTypes.fetch();
      amenitiesList.fetch();
    } else {
      toast.error('create property first before adding details');
      navigate('/create-ad');
    }
  }, []);

  React.useEffect(() => {
    setTenantTypes(tenantsTypes.value || []);
  }, [tenantsTypes.value]);

  React.useEffect(() => {
    setInternetTypes(internetTypes.value || []);
  }, [internetTypes.value]);

  React.useEffect(() => {
    setAmenity(amenitiesList.value || []);
  }, [amenitiesList.value]);

  const handlePropertyDetails = async (formData: any): Promise<void> => {
    setLoading(true);
    try {
      await dispatch(internetConnectionsAction({
        speed: Number(formData.speed || 0),
        provider: formData.provider,
        wifi: formData.wifi,
        hardwired: formData.hardwired,
        internetConnectionTypeId: formData.internetConnectionTypeId,
      }, propertyData?.data?.id as string));
      await dispatch(updatePropertyAction({
        livingRooms: formData.livingRooms,
        maxOccupancy: formData.maxOccupancy,
        tenantTypeId: formData.tenantTypeId,
        maxAge: formData.maxAge,
        minAge: formData.minAge,
        males: formData.males,
        females: formData.females,
        epcExpiry: formData.epcExpiry,
        hmoLicenceExpiry: formData.hmoLicenceExpiry,
        fireSafetyExpiry: formData.fireSafetyExpiry,
        gasExpiry: formData.gasExpiry,
      }, propertyData?.data?.id as string));
      handleForm();
    } catch (error) {
      toast.error('something went wrong while updating property details');
    }
    setLoading(false);
  };

  const handleBathroomChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    bthroom: IBathroom,
  ):Promise<void> => {
    try {
      setLoading(true);
      const cpBathroom = { ...bthroom };
      cpBathroom[e.target.name as keyof IBathroomProps] = e.target.checked as any;
      await dispatch(editBathroomAction(cpBathroom));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'something went wrong while updating the bathrrom');
    }
  };

  const handleRemoveBath = async (idx: string, propId: string):Promise<void> => {
    try {
      setLoading(true);
      await dispatch(removeBathroomAction(propId, idx));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong while remove the bathroom');
    }
  };

  const handleAddBathroom = async (): Promise<void> => {
    try {
      setLoading(true);
      if (propertyData.data?.id) {
        await dispatch(addBathroomAction({ propertyId: propertyData.data.id, ...defaultBathroom }));
      } else {
        throw new Error('create a property first');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong while adding the bathroom');
    }
  };

  const handleKitchenChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    kitchen: IKitchen,
  ):Promise<void> => {
    try {
      setLoading(true);
      const cpKitchen = { ...kitchen };
      cpKitchen[e.target.name as keyof IkitchenProps] = Number(e.target.value) as any;
      await dispatch(editKitchenAction(cpKitchen));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong while adding the bathroom');
    }
  };

  const handleRemoveKitchen = async (idx: string): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(removeKitchenAction(propertyData.data?.id as string, idx));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddKitchen = async (): Promise<void> => {
    try {
      setLoading(true);
      if (propertyData.data?.id) {
        await dispatch(addKitchenAction({ propertyId: propertyData.data.id, ...defaultKitchen }));
      } else {
        throw new Error('create a property first');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || 'Something went wrong while adding the bathroom');
    }
  };

  const handleAmenity = async (amenity: amenitiesTypes, exists:boolean): Promise<void> => {
    try {
      await dispatch(amenitiesAction(amenity, propertyData?.data?.id as string, exists));
    } catch (error) {
      toast.error(error.message || 'Something went wrong while updating the amenities, please try again later');
    }
  };

  const handleCertChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string): Promise<void> => {
    if (e.target?.files && e.target?.files?.length) {
      setCertSelected(type);
      setLoading(true);
      try {
        const bodyFormData = new FormData();
        bodyFormData.append('file', (e.target.files as any)[0] as any);
        bodyFormData.append('type', type);
        await dispatch(
          addDocumentsCertsAction(propertyData?.data?.id as string, bodyFormData, type),
        );
        setLoading(false);
        setCertSelected('');
      } catch (error) {
        setLoading(false);
        setCertSelected('');
        toast.error('something went wrong while updating the document');
      }
    }
  };

  const handleRemoveCerts = async (type: string, expiryType?: string): Promise<void> => {
    try {
      await dispatch(removeDocumentsCertsAction(propertyData?.data?.id as string, type, expiryType));
    } catch (error) {
      toast.error('something went wrong while deleting the document');
    }
  };

  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={2} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormContainer component="form" onSubmit={handleSubmit((formData: any) => handlePropertyDetails(formData))}>
          {edit && (
          <Alert severity="info" sx={{ mb: 5 }}>
            Editing property details will affect all the adverts related to this property.
          </Alert>
          )}
          <Typography sx={{ mb: 2 }} variant="h6">Number Of Shared Bathrooms</Typography>
          <Grid container spacing={2}>
            {bathrooms.map((bathroom, idx) => (
              <Grid key={`bathroom-${bathroom.id}`} item xs={12} sm={6}>
                <CheckBoxContainer sx={{ p: 1 }} variant="standard">
                  <CheckBoxLabel>
                    Bathroom
                    {' '}
                    {idx + 1}
                    <IconButton onClick={() => handleRemoveBath(bathroom.id as string, bathroom.propertyId)}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </CheckBoxLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={bathroom.hasToilet}
                          onChange={(e) => handleBathroomChange(e, bathroom)}
                          name="hasToilet"
                        />
                )}
                      label="Toilet"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={bathroom.hasShower}
                          onChange={(e) => handleBathroomChange(e, bathroom)}
                          name="hasShower"
                        />
              )}
                      label="Shower"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={bathroom.hasBath}
                          onChange={(e) => handleBathroomChange(e, bathroom)}
                          name="hasBath"
                        />
                )}
                      label="Bath"
                    />
                  </FormGroup>
                </CheckBoxContainer>
              </Grid>
            ))}
            <Grid item xs={12} sm={6}>
              <AddCheckBoxContainer onClick={handleAddBathroom}>
                <BathtubIcon sx={{ fontSize: 60 }} color="disabled" />
                <Button>
                  <AddIcon />
                  Add Bathroom
                </Button>
              </AddCheckBoxContainer>
            </Grid>
          </Grid>
          <Typography sx={{ mt: 2, mb: 2 }} variant="h6">Number Of Shared Kitchens</Typography>
          <Grid container spacing={2}>
            {kitchens.map((kitchen, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={`kitchen-${idx}`} item xs={12} sm={6}>
                <CheckBoxContainer sx={{ p: 1 }} variant="standard">
                  <CheckBoxLabel>
                    Kitchen
                    {' '}
                    {idx + 1}
                    <IconButton onClick={() => handleRemoveKitchen(kitchen.id as string)}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </CheckBoxLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <KitchenField
                          type="number"
                          size="small"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={kitchen.numberOfHobs}
                          onChange={(e) => handleKitchenChange(e, kitchen)}
                          name="numberOfHobs"
                        />
              )}
                      label="Hobs"
                    />
                    <FormControlLabel
                      control={(
                        <KitchenField
                          type="number"
                          size="small"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={kitchen.microwaves}
                          onChange={(e) => handleKitchenChange(e, kitchen)}
                          name="microwaves"
                        />
                )}
                      label="Microwaves"
                    />
                    <FormControlLabel
                      control={(
                        <KitchenField
                          type="number"
                          size="small"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={kitchen.ovens}
                          onChange={(e) => handleKitchenChange(e, kitchen)}
                          name="ovens"
                        />
                )}
                      label="Ovens"
                    />
                    <FormControlLabel
                      control={(
                        <KitchenField
                          type="number"
                          size="small"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={kitchen.fridges}
                          onChange={(e) => handleKitchenChange(e, kitchen)}
                          name="fridges"
                        />
                )}
                      label="Fridges"
                    />
                    <FormControlLabel
                      control={(
                        <KitchenField
                          type="number"
                          value={kitchen.freezers}
                          InputProps={{ inputProps: { min: 0 } }}
                          onChange={(e) => handleKitchenChange(e, kitchen)}
                          size="small"
                          name="freezers"
                        />
                )}
                      label="Freezers"
                    />
                  </FormGroup>
                </CheckBoxContainer>
              </Grid>
            ))}
            <Grid item xs={12} sm={6}>
              <AddCheckBoxContainer onClick={handleAddKitchen}>
                <KitchenIcon sx={{ fontSize: 60 }} color="disabled" />
                <Button>
                  <AddIcon />
                  Add Kitchen
                </Button>
              </AddCheckBoxContainer>
            </Grid>
          </Grid>
          <Typography sx={{ mt: 6, mb: 2 }} variant="h6">Amenities in the House</Typography>
          <ChipContainer mb={2}>
            {amenities.map((amenity) => {
              const ifExists = propertyAmenities?.some((el) => el.id === amenity.id);
              return (
                <Chip
                  label={amenity.name}
                  key={amenity.id}
                  sx={{ mr: 2, mb: 2 }}
                  color={ifExists ? 'primary' : undefined}
                  onClick={() => handleAmenity(amenity, ifExists)}
                />
              );
            })}
          </ChipContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <Controller
                  control={control}
                  name="maxOccupancy"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-occupancy"
                      label="Maximum occupancy"
                      variant="outlined"
                      type="number"
                      name="maxOccupancy"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      error={!!errors.maxOccupancy?.message}
                      helperText={errors.maxOccupancy?.message || ''}
                      size="small"
                    />
                  )}
                />
              </TextInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <Controller
                  control={control}
                  name="males"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-males"
                      label="Number of males"
                      variant="outlined"
                      type="number"
                      name="males"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      size="small"
                      error={!!errors.males?.message}
                      helperText={errors.males?.message || ''}
                    />
                  )}
                />
              </TextInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <Controller
                  control={control}
                  name="females"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-females"
                      label="Number of females"
                      variant="outlined"
                      type="number"
                      name="females"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      size="small"
                      error={!!errors.females?.message}
                      helperText={errors.females?.message || ''}
                    />
                  )}
                />
              </TextInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="tenantTypeId"
                    render={({
                      field: {
                        onChange, onBlur, value, ref,
                      },
                    }) => (
                      <>
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          size="small"
                          select
                          name="tenantTypeId"
                          label="Tenant Type"
                          error={!!errors.tenantTypeId?.message}
                        >
                          {tenantypesState.map((tenant) => <MenuItem value={tenant.id}>{tenant.name}</MenuItem>)}
                        </TextInput>
                        <FormHelperText error={!!errors.tenantTypeId?.message}>
                          {errors.tenantTypeId?.message || ''}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </TextInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <Controller
                  control={control}
                  name="minAge"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-minAge"
                      label="Minimum Age"
                      variant="outlined"
                      type="number"
                      name="minAge"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      size="small"
                      error={!!errors.minAge?.message}
                      helperText={errors.minAge?.message || ''}
                    />
                  )}
                />
              </TextInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <Controller
                  control={control}
                  name="maxAge"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-maxAge"
                      label="Maximum Age"
                      variant="outlined"
                      type="number"
                      name="maxAge"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      error={!!errors.maxAge?.message}
                      helperText={errors.maxAge?.message || ''}
                    />
                  )}
                />
              </TextInputContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputContainer>
                <Controller
                  control={control}
                  name="livingRooms"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      type="number"
                      size="small"
                      value={value}
                      InputProps={{ inputProps: { min: 0 } }}
                      ref={ref}
                      onBlur={onBlur}
                      onChange={onChange}
                      name="livingRooms"
                      label="Number of Living rooms"
                      error={!!errors.livingRooms?.message}
                      helperText={errors.livingRooms?.message}
                    />
                  )}
                />
              </TextInputContainer>
            </Grid>
          </Grid>
          <TextInputContainer sx={{ mt: 5 }}>
            <Label>Internet connection</Label>
            <FormControl sx={{ width: '50%' }}>
              <Controller
                control={control}
                name="internetConnectionTypeId"
                render={({
                  field: {
                    onChange, value, ref,
                  },
                }) => (
                  <>
                    <TextInput
                      value={value}
                      ref={ref}
                      onChange={onChange}
                      size="small"
                      name="internetConnectionTypeId"
                      select
                      label="Internet connection type"
                      error={!!errors.internetConnectionTypeId?.message}
                    >
                      {internetypesState.map((net) => (
                        <MenuItem value={net.id}>{net.name}</MenuItem>
                      ))}
                    </TextInput>
                    <FormHelperText error={!!errors.internetConnectionTypeId?.message}>
                      {errors.internetConnectionTypeId?.message}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="provider"
                  render={({
                    field: {
                      onChange, value, onBlur, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-net-provide"
                      label="Internet provider name"
                      variant="outlined"
                      type="text"
                      name="provider"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      size="small"
                      error={!!errors.provider?.message}
                      helperText={errors.provider?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="speed"
                  render={({
                    field: {
                      onChange, value, onBlur, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-net-speeds"
                      label="Internet speeds in mbps"
                      variant="outlined"
                      type="number"
                      name="speed"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                      error={!!errors.speed?.message}
                      helperText={errors.speed?.message || ''}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" sx={{ mt: 2, fontSize: 14 }}>Wifi available?</FormLabel>
                  <Controller
                    control={control}
                    name="wifi"
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                    }) => (
                      <RadioGroup
                        ref={ref}
                        row
                        aria-label="wifi"
                        value={value}
                        onChange={onChange}
                        name="wifi"
                      >
                        <FormControlLabel value control={<Radio />} label="yes" />
                        <FormControlLabel value={false} control={<Radio />} label="no" />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText error={!!errors.wifi?.message}>
                    {errors.wifi?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" sx={{ mt: 2, fontSize: 14 }}>Wired connection available?</FormLabel>
                  <Controller
                    control={control}
                    name="hardwired"
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                    }) => (
                      <RadioGroup
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        row
                        aria-label="wiredNet"
                        name="hardwired"
                      >
                        <FormControlLabel value control={<Radio />} label="yes" />
                        <FormControlLabel value={false} control={<Radio />} label="no" />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText error={!!errors.hardwired?.message}>
                    {errors.hardwired?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </TextInputContainer>

          {
            !HIDE_DOCUMENT_UPLOAD
            && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {predCerts.map((cert) => (
                  <CertDocumentContainer key={cert.type}>
                    <Box
                      sx={{ width: '100%', mb: 1, mt: -1 }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignSelf="flex-start"
                    >
                      <FormLabel sx={{ fontWeight: 'bold' }}>{cert.name}</FormLabel>
                      <IconButton onClick={() => handleRemoveCerts(cert.type, cert.expiryName)}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Box>
                    <label style={{ width: '100%' }} htmlFor={cert.type}>
                      <input
                        accept="image/*, application/pdf"
                        id={cert.type}
                        multiple
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => handleCertChange(e, cert.type)}
                      />
                      {propertyCerts[cert.type]?.url ? (
                        <Button fullWidth variant="contained" component="span">
                          {' '}
                          <EditIcon sx={{ fontSize: '18px', mr: 1 }} />
                          {' '}
                          Update
                        </Button>
                      )
                        : (
                          <Button fullWidth variant="contained" component="span">
                            {' '}
                            <AddIcon sx={{ fontSize: '18px', mr: 1 }} />
                            {' '}
                            Add
                          </Button>
                        )}
                    </label>
                    {cert.type !== 'floorPlan' ? (
                      <>
                        <Divider sx={{
                          my: 2, borderColor: '#868e96', width: '100%',
                        }}
                        />
                        <Controller
                          control={control}
                          name={cert.expiryName as string}
                          defaultValue={new Date()}
                          render={({
                            field: {
                              onChange, value, ref,
                            },
                          }) => (
                            <DatePicker
                              label="Expires in"
                              inputFormat="MM/dd/yyyy"
                              minDate={new Date()}
                              value={value}
                              onChange={onChange}
                              ref={ref}
                              renderInput={(params) => (
                                <TextInput
                                  fullWidth
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          )}
                        />
                      </>
                    ) : <div style={{ flex: 1 }} />}
                  </CertDocumentContainer>
                ))}
              </Box>
            )
          }
          <BottomBtn
            typeSubmit="submit"
            loading={loading}
            handleNext={() => handleSubmit((formData: any) => handlePropertyDetails(formData))}
            handleBack={handleBack}
          />
        </FormContainer>
      </LocalizationProvider>
    </ApplicationWrapper>
  );
};

export default PropertyDetailsForm;
