import React, { ReactNode, SyntheticEvent } from 'react';
import { styled, Theme } from '@mui/material/styles';
import {
  Box, Button, Divider, Paper, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import LeadViewerDetails from '../LeadViewerDetails';
import { ScreeningStatus } from '../../LeadManager/LeadManagerContainer';
import Format from '../../../utils/Format';
import DateTimeUtils from '../../../utils/DateTimeUtils';
import { ILead } from '../../../sdk/leads';

interface Props {
  lead: ILead;
  onOptionsClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  onScreeningClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

export const StyledCardWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: 5,
  height: 'auto',
  width: '100%',
  backgroundColor: theme.colors.white,
  marginTop: theme.spacing(2),
  position: 'relative',
}));

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StyledLeadStatus = styled(Box)(({ theme }) => ({
  color: '#fff',
  padding: theme.spacing(0, 2),
  height: 40,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0 5px 0 0',
  position: 'absolute',
  top: 0,
  right: 0,

  [theme.breakpoints.down('md')]: {
    position: 'static',
    justifyContent: 'center',
    borderRadius: '5px 5px 0 0',
    marginBottom: theme.spacing(1),
  },
}));

const LeadStatus = (props: { lead: ILead }): JSX.Element => {
  const { lead } = props;
  const status = ScreeningStatus[lead.status as keyof typeof ScreeningStatus];

  return (
    <StyledLeadStatus
      sx={{
        background: status.color,
      }}
    >
      {status.name}
    </StyledLeadStatus>
  );
};

export const PropertyWithIcon = (props: {
  icon: typeof SvgIcon;
  children: ReactNode;
  sx?: SxProps<Theme>
}): JSX.Element => {
  const {
    children,
    icon: Icon,
    sx,
  } = props;

  return (
    <Typography
      component="div"
      variant="body2"
      sx={{
        ...sx,
        display: 'flex',
        alignContent: 'center',
      }}
    >
      <span
        style={{
          marginRight: '0.6em',
        }}
      >
        <Icon
          fontSize="small"
          sx={{
            color: 'primary.light',
          }}
        />
      </span>
      <span>
        {children}
      </span>
    </Typography>
  );
};

const LeadCard = (props: Props): JSX.Element => {
  const {
    lead,
    onOptionsClick,
    onScreeningClick,
  } = props;
  const {
    advert,
    property,
    viewer,
    viewingSlot,
  } = lead;
  const adExpired = Boolean(viewingSlot && DateTimeUtils.isAfterDate(DateTimeUtils.now(), viewingSlot.dateAndTime));

  const theme = useTheme();
  const isSmallViewport = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledCardWrapper>
      <LeadStatus lead={lead} />

      <StyledSection
        sx={{
          paddingRight: isSmallViewport ? 0 : '138px',
        }}
      >
        <Typography variant="h6" lineHeight={1.3}>
          {advert.adTitle}
        </Typography>
      </StyledSection>

      <Stack direction={isSmallViewport ? 'column' : 'row'}>
        {
          viewingSlot
          && (
            <StyledSection>
              <Typography variant="overline" color={grey[600]}>
                Viewing details
              </Typography>

              <PropertyWithIcon icon={ScheduleIcon}>
                {DateTimeUtils.Format.dateTime(viewingSlot.dateAndTime)}
              </PropertyWithIcon>

              <PropertyWithIcon icon={LocationOnOutlinedIcon}>
                {lead.isVirtual ? 'Virtual' : 'On-site'}
              </PropertyWithIcon>
            </StyledSection>
          )
        }

        <StyledSection>
          <Typography variant="overline" color={grey[600]}>
            Prospect details
          </Typography>
          <LeadViewerDetails viewer={viewer} />
        </StyledSection>

        <StyledSection>
          <Typography variant="overline" color={grey[600]}>
            Property details
          </Typography>
          <PropertyWithIcon icon={HouseOutlinedIcon}>
            {Format.address(property)}
          </PropertyWithIcon>
        </StyledSection>
      </Stack>

      <Divider />

      <StyledSection display="flex" justifyContent="space-between">
        <Button size="small" onClick={onScreeningClick}>Screening</Button>

        {
          viewingSlot
          && (
            <Button color="primary" size="small" onClick={onOptionsClick} disabled={adExpired}>
              Actions
            </Button>
          )
        }

      </StyledSection>
    </StyledCardWrapper>
  );
};

export default LeadCard;
