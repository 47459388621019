import Chip from '@mui/material/Chip';
import React from 'react';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { styled } from '@mui/material/styles';

interface ChipIsVirtualProps {
  isVirtual: boolean;
  isOnsite: boolean;
  useIcon: boolean;
}

export const ChipStyle = styled(Chip)`
  fontSize: x-small !important;
`;

const ChipIsVirtual: React.FC<ChipIsVirtualProps> = ({
  isVirtual,
  isOnsite,
  useIcon,
}) => (
  <>
    {
      isVirtual && !isOnsite && (
        <ChipStyle
          label="Virtual"
          color="primary"
          size="small"
          icon={useIcon ? <LanguageRoundedIcon fontSize="small" /> : <></>}
        />
      )
    }
    {
      !isVirtual && isOnsite && (
        <ChipStyle
          label="OnSite"
          color="secondary"
          size="small"
          icon={useIcon ? <LocationOnRoundedIcon fontSize="small" /> : <></>}
        />
      )
    }
    {
      isVirtual && isOnsite && (
        <ChipStyle
          label="Both"
          color="warning"
          size="small"
        />
      )
    }
  </>
);

export default ChipIsVirtual;
