import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import {
  SuccessContainer,
  LinkView,
} from './styles';

const InstructionsMessage: React.FC = () => (
  <SuccessContainer>
    <Box>
      <Typography variant="h2" gutterBottom component="div">
        Email Sent
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        Please follow the instructions sent to the address entered.
      </Typography>
    </Box>
    <LinkView type="button" to="/login">
      Go back to Log in
    </LinkView>
  </SuccessContainer>
);

export default InstructionsMessage;
