import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Editor from 'for-editor';
import { editAdvertAction } from '../../../store/actions/PropertyActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import BottomBtn from '../common/ButtonContainer';
import VerticalStepper from '../common/VerticalStepper';
import ChipCharCounter from '../common/ChipCharCounter';
import Instructions from './Instructions';

interface VirtualMeetingProps {
  handleBack(): void;
  handleNext(): void;
  handleRebookLead?(advertId :string, leadId :string): void;
  steps: string[];
  enableStepper?: boolean;
  advertId?: string;
  leadId?: string;
}

const INSTRUCTIONS_CHAR_LIMIT = 500;

const VirtualMeeting: React.FC<VirtualMeetingProps> = (props) => {
  const {
    handleBack, handleNext, handleRebookLead, steps, enableStepper, advertId, leadId,
  } = props;

  const advertData = useAppSelector((state) => state.createpropertyState?.roomDetails);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [text, setText] = React.useState('');

  const handleForm = async (virtualMeetingDetails: string): Promise<void> => {
    setLoading(true);
    try {
      if (advertData?.id) {
        const data = { ...advertData, ...{ virtualMeetingDetails } };
        await dispatch(editAdvertAction(data, advertData.id));
      }
      if (handleRebookLead) {
        await handleRebookLead(advertId || '', leadId || '');
        setLoading(false);
        navigate('/dashboard');
      } else {
        setLoading(false);
        navigate('../preview-submission');
      }
    } catch (error) {
      setLoading(false);
      toast.error('please check your room advert fields and try again to update');
    }
  };
  const handleTextChange = (value: string): void => {
    if (value.length <= INSTRUCTIONS_CHAR_LIMIT) {
      setText(value);
    }
  };

  useEffect(() => {
    const { virtualMeetingDetails = '' } = advertData || {};
    setText(virtualMeetingDetails);
  }, []);
  return (
    <ApplicationWrapper
      customSideNav={
        enableStepper ? <VerticalStepper activeStep={steps.indexOf('Viewing Slots')} steps={steps} /> : null
      }
      activeMenu={0}
      menuElements={[]}
    >
      <Box
        sx={{
          display: 'flex',
          minHeight: '70vh',
          position: 'relative',
          flexDirection: 'column',
          mb: 20,
        }}
      >
        <Box sx={{ ml: 5, mr: 5, mt: 2 }}>
          <Typography color="primary" variant="h4" sx={{ fontWeight: 500 }}>Virtual Meeting Instructions</Typography>
        </Box>
        <Box sx={{ ml: 5, mr: 5, mt: 2 }}>
          <Instructions />
          <Editor
            value={text}
            onChange={handleTextChange}
            language="en"
            onSave={() => handleForm(text)}
            toolbar={{
              img: false,
              h1: true,
              h2: true,
              h3: true,
              h4: true,
              link: true,
              preview: true,
              expand: true,
              undo: true,
              redo: true,
              save: true,
              subfield: true,
            }}
          />
        </Box>
        <Box sx={{ ml: 5, mt: 2 }}>
          <ChipCharCounter text={text} limit={INSTRUCTIONS_CHAR_LIMIT} />
        </Box>
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            position: 'relative',
            mt: 10,
          }}
        >
          <BottomBtn
            typeSubmit="submit"
            loading={loading}
            handleBack={() => navigate('../viewing-slots')}
            handleNext={() => handleForm(text)}
          />
        </Box>
      </Box>
    </ApplicationWrapper>
  );
};
export default VirtualMeeting;
