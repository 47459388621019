import React from 'react';
import { Logo } from './styles';
import NevoLogoIcon from '../../../icons/NevoLogo';

const LogoLink = (): JSX.Element => (
  <Logo to="/">
    <NevoLogoIcon color="secondary" fontSize="large" />
  </Logo>
);

export default LogoLink;
