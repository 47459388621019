import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  TextField,
  Box,
  InputLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const TextInput = styled(TextField)(() => `
  margin: 12px 0;
`);

export const FormContainer = styled(Box)(() => `
  margin: 30px auto;
  max-width: 445px;
  width: 80%;
  display: flex;
  flex-direction: column;
`);

export const Label = styled(InputLabel)(() => `
  margin-top: 12px;
`);

export const LinkView = styled(Link)`
  color: #868e96;
  margin: 12px 0;
  font-family: 'Montserrat', sans-serif !important;
  text-decoration: none;
  &:hover {
    color: #474ed1;
  }
`;

export const ButtonView = styled(LoadingButton)`
  width: 40%;
  height: 40px;
`;

export const SuccessContainer = styled(Box)(() => `
  margin: 30px auto;
  max-width: 445px;
  width: 80%;
  display: flex;
  flex-direction: column;
`);
