import React from 'react';
import InstructionsMessage from '../components/Auth/InstructionsMessage';
import Navbar from '../components/layout/Navbar';
import usePageTitle from '../hooks/pageTitle';

const EmailInstructions: React.FC = () => {
  usePageTitle('email instructions');
  return (
    <>
      <Navbar />
      <InstructionsMessage />
    </>
  );
};

export default EmailInstructions;
