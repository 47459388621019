import {
  IBathroom,
  IKitchen,
  InternetConnection,
  IScreeninQuestions,
  IViewingSlot,
} from '../store/reducers/createProperty';
import { client } from './client';

export interface IProperty {
  id?: string;
  buildingNameOrNumber: string;
  flatNumber: string;
  streetName: string;
  town: string;
  females?: number;
  males?: number;
  maxAge?: number;
  maxOccupancy?: number;
  minAge?: number;
  typeId?: string;
  postalCode: string;
  internetConnection?: InternetConnection;
  livingRooms?: number;
  tenantTypeId?: string;
  createdAt?: string;
  updatedAt?: string;
  epcExpiry?: string;
  hmoLicenceExpiry?: string;
  fireSafetyExpiry?: string;
  gasExpiry?: string;
  virtualMeetingDetails?: string;
}

export interface IPropertyParams {
  id?: string;
  buildingNameOrNumber?: string;
  streetName?: string;
  town?: string;
  postalCode?: string;
  flatNumber?: string;
  typeId?: string;
  males?: number;
  females?: number;
  minAge?: number;
  maxAge?: number;
  maxOccupancy?: number;
  livingRooms?: number;
  tenantTypeId?: string;
  epcExpiry?: string;
  hmoLicenceExpiry?: string;
  fireSafetyExpiry?: string;
  gasExpiry?: string;
  virtualMeetingDetails?: string;
}

export interface IPropertyTypes {
  id: string;
  name: string;
}

export interface IwithDefault extends IPropertyTypes {
  default?: boolean;
}

export const createProperty = (data: IPropertyParams): Promise<IProperty> => client
  .post<IProperty>('/properties', data)
  .then((response) => response.data);

export const updateProperty = (data: IPropertyParams, id: string): Promise<IProperty> => client
  .patch<IProperty>(`/properties/${id}`, data)
  .then((response) => response.data);

export const listProperty = (): Promise<IPropertyParams[]> => client
  .get<IPropertyParams[]>('/properties')
  .then((response) => response.data);

export const fetchPropertyDetails = (propId: string): Promise<IPropertyParams> => client
  .get<IPropertyParams>(`/properties/${propId}`)
  .then((response) => response.data);

export const listPropertyTypes = (): Promise<IPropertyTypes[]> => client
  .get<IPropertyTypes[]>('/property-types')
  .then((response) => response.data);

export const listInternetTypes = (): Promise<IPropertyTypes[]> => client
  .get<IPropertyTypes[]>('/internet-connection-types')
  .then((response) => response.data);

export const listTenantTypes = (): Promise<IPropertyTypes[]> => client
  .get<IPropertyTypes[]>('/tenant-types')
  .then((response) => response.data);

export const addBathroom = ({
  hasBath, hasShower, hasToilet, propertyId,
}: IBathroom): Promise<IBathroom> => client
  .post<IBathroom>(`/properties/${propertyId}/bathrooms`, { hasBath, hasShower, hasToilet })
  .then((response) => response.data);

export const editBathroom = ({
  hasBath, hasShower, hasToilet, propertyId, id,
}: IBathroom): Promise<IBathroom> => client
  .patch<IBathroom>(`/properties/${propertyId}/bathrooms/${id}`, { hasBath, hasShower, hasToilet })
  .then((response) => response.data);

export const removeBathroom = (propertyId: string, id: string): Promise<void> => client
  .delete<void>(`/properties/${propertyId}/bathrooms/${id}`)
  .then((response) => response.data);

export const addKitchen = ({
  microwaves, numberOfHobs, ovens, freezers, fridges, propertyId,
}: IKitchen): Promise<IKitchen> => client
  .post<IKitchen>(`/properties/${propertyId}/kitchens`, {
    microwaves, numberOfHobs, ovens, freezers, fridges,
  })
  .then((response) => response.data);

export const listKitchens = (propertyId: string): Promise<IKitchen[]> => client
  .get<IKitchen[]>(`/properties/${propertyId}/kitchens`)
  .then((response) => response.data);

export const listBathrooms = (propertyId: string): Promise<IKitchen[]> => client
  .get<IKitchen[]>(`/properties/${propertyId}/bathrooms`)
  .then((response) => response.data);

export const listPropAmenities = (propertyId: string): Promise<any[]> => client
  .get<any[]>(`/properties/${propertyId}/amenities`)
  .then((response) => response.data);

export const editKitchen = ({
  microwaves, numberOfHobs, ovens, freezers, fridges, propertyId, id,
}: IKitchen): Promise<IKitchen> => client
  .patch<IKitchen>(`/properties/${propertyId}/kitchens/${id}`, {
    microwaves, numberOfHobs, ovens, freezers, fridges,
  })
  .then((response) => response.data);

export const removeKitchen = (propertyId: string, id: string): Promise<void> => client
  .delete<void>(`/properties/${propertyId}/kitchens/${id}`)
  .then((response) => response.data);

export const listAmenities = (): Promise<IPropertyTypes[]> => client
  .get<IPropertyTypes[]>('/amenities')
  .then((response) => response.data);

export const addAmenitiesProp = (id: string, propId: string): Promise<any> => client
  .post<any>(`/properties/${propId}/amenities/${id}`)
  .then((response) => response.data);

export const removeAmenitiesProp = (id: string, propId: string): Promise<any> => client
  .delete<any>(`/properties/${propId}/amenities/${id}`)
  .then((response) => response.data);

export const internetDetails = (data: InternetConnection, propId: string): Promise<IProperty> => client
  .post<IProperty>(`/properties/${propId}/internet-connection`, data)
  .then((response) => response.data);

export const rentFrequenciesList = (): Promise<IwithDefault[]> => client
  .get<IwithDefault[]>('/rent-frequencies')
  .then((response) => response.data);

export const screeningQuestionsList = (advertId: string): Promise<IScreeninQuestions[]> => client
  .get<IScreeninQuestions[]>(`/screening-questions?advertId=${advertId}`)
  .then((response) => response.data);

export const createScreeningQuestion = (data: IScreeninQuestions): Promise<IScreeninQuestions> => client
  .post<IScreeninQuestions>('/screening-questions', data)
  .then((response) => response.data);

export const editScreeningQuestions = (
  data: IScreeninQuestions, questionId: string,
): Promise<IScreeninQuestions> => client
  .patch<IScreeninQuestions>(`/screening-questions/${questionId}`, data)
  .then((response) => response.data);

export const removeScreeningQuestions = (
  questionId: string,
): Promise<null> => client
  .delete<null>(`/screening-questions/${questionId}`)
  .then((response) => response.data);

export const addViewingSlot = (
  advertId: string,
  date: string,
  isVirtual: boolean,
  isOnsite: boolean,
  maxAttendees: number,
): Promise<IViewingSlot> => client
  .post<IViewingSlot>(`/adverts/${advertId}/viewing-slots`, {
    dateAndTime: date, isVirtual, isOnsite, maxAttendees,
  })
  .then((response) => response.data);

export const fetchViewingSlot = (advertId: string): Promise<IViewingSlot[]> => client
  .get<IViewingSlot[]>(`/adverts/${advertId}/viewing-slots`)
  .then((response) => response.data);

export const removeViewingSlot = (advertId: string, viewingSlotId: string): Promise<null> => client
  .delete<null>(`/adverts/${advertId}/viewing-slots/${viewingSlotId}`)
  .then((response) => response.data);

export const addPropertyDocument = (propId: string, bodyData: any): Promise<{ url: string}> => client({
  method: 'post',
  url: `/properties/${propId}/upload-document`,
  headers: { 'Content-Type': 'multipart/form-data' },
  data: bodyData,
}).then((response) => response.data);

export const removePropertyDocument = (propId: string, type: string): Promise<{ url: string}> => client
  .delete(`/properties/${propId}/remove-document`, { data: { type } })
  .then((response) => response.data);

export const cancelScreening = (
  screeningId: string,
): Promise<IViewingSlot> => client
  .post<IViewingSlot>(`/screenings/${screeningId}/cancel`, { })
  .then((response) => response.data);
