import { client } from './client';
import { Nullable } from '../utils/types';

export interface LeadViewer {
  id: string;
  name: string;
  email: string;
  phone: string;

}

export interface LeadViewingSlot {
  id: string;
  dateAndTime: string;
  isVirtual: boolean;
  isOnsite: boolean;
}

export interface ILead {
  id: string;
  status: string,
  isVirtual: boolean,
  property: {
    id: string,
    flatNumber: string,
    buildingNameOrNumber: string,
    streetName: string,
    town: string,
    postalCode: string,
  },
  advert: {
    id: string,
    adTitle: string,
  },
  viewer: LeadViewer,
  viewingSlot: Nullable<LeadViewingSlot>
}

export const fetchLeads = (): Promise<ILead[]> => client
  .get('/leads')
  .then((response) => response.data);

export const fetchLeadDetails = (leadId: string): Promise<any> => client
  .get(`/leads/${leadId}`)
  .then((response) => response.data);

export const cancelLead = (
  leadId: string,
): Promise<any> => client
  .post(`/leads/${leadId}/cancel`, {})
  .then((response) => response.data);

export const rebookLead = (
  leadId: string,
  viewingSlotId: string,
): Promise<any> => client
  .post(`/adverts/${leadId}/viewing-slots/${viewingSlotId}/rebook-viewings`, {})
  .then((response) => response.data);
// TODO Add biewing slot id to the url /api/v1/adverts/:advertId/viewing-slots/:viewingSlotId/rebook-viewings
