import React from 'react';
import { Navigate } from 'react-router-dom';
import usePageTitle from '../hooks/pageTitle';
import { logoutAction } from '../store/actions/authActions';
import { useAppDispatch } from '../store/hooks';

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  usePageTitle('logout');

  React.useEffect(() => {
    dispatch(logoutAction());
  }, []);

  return (
    <Navigate replace to="/login" />
  );
};

export default Logout;
