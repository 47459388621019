import React from 'react';
import ApplicationWrapper from '../components/layout/ApplicationWrapper';
import UpcomingNextView from '../components/UpcomingView';
import usePageTitle from '../hooks/pageTitle';

const menuElements: { id: string | number; title: string; icon: string; link: string; }[] = [
  {
    title: 'Adverts',
    id: 1,
    icon: '../../assets/icons/portfolio.svg',
    link: '/',
  },
  {
    title: 'Lead Manager',
    id: 2,
    icon: '/icons/portfolio',
    link: '/lead-manager',
  },
  {
    title: 'Viewings Calendar',
    id: 3,
    icon: '/icons/portfolio',
    link: '/upcoming-view',
  },
];

const UpcomingViewing: React.FC = () => {
  usePageTitle('Upcoming view');
  return (
    <ApplicationWrapper customSideNav={null} activeMenu={3} menuElements={menuElements}>
      <>
        <UpcomingNextView />
        <div className="elfsight-app-f1daed66-3a0d-4ea3-8bd9-47ffe3c99ed9" />
      </>
    </ApplicationWrapper>
  );
};

export default UpcomingViewing;
