import { Iphoto, IRoomDetails } from '../store/reducers/createProperty';
import { client } from './client';

export const fetchAdverts = (): Promise<IRoomDetails[]> => client
  .get('/adverts')
  .then((response) => response.data);

export const fetchAdvertDetails = (advertId: string): Promise<IRoomDetails> => client
  .get(`/adverts/${advertId}`)
  .then((response) => response.data);

export const createAdvert = ({
  propertyId,
  adTitle,
  note,
  deposit,
  availableBy,
  rent,
  rentFrequencyId,
  internetIncluded,
  councilTaxIncluded,
  gasIncluded,
  electricityIncluded,
  cleaningIncluded,
  tvLicenceIncluded,
  isFurnished,
  hasKitchenette,
  pets,
  couplesAllowed,
  isDouble,
  youtubeLink,
}: IRoomDetails): Promise<IRoomDetails> => client
  .post<IRoomDetails>('/adverts', {
    propertyId,
    adTitle,
    note,
    deposit,
    availableBy,
    rent,
    rentFrequencyId,
    internetIncluded,
    councilTaxIncluded,
    gasIncluded,
    electricityIncluded,
    cleaningIncluded,
    tvLicenceIncluded,
    isFurnished,
    hasKitchenette,
    pets,
    couplesAllowed,
    isDouble,
    youtubeLink,
  })
  .then((response) => response.data);

export const updateAdvert = ({
  adTitle,
  note,
  deposit,
  availableBy,
  rent,
  rentFrequencyId,
  internetIncluded,
  councilTaxIncluded,
  gasIncluded,
  electricityIncluded,
  cleaningIncluded,
  tvLicenceIncluded,
  isFurnished,
  hasKitchenette,
  pets,
  couplesAllowed,
  isDouble,
  youtubeLink,
  virtualMeetingDetails,
}: IRoomDetails, id: string): Promise<IRoomDetails> => client
  .patch<IRoomDetails>(`/adverts/${id}`, {
    adTitle,
    note,
    deposit,
    availableBy,
    rent,
    rentFrequencyId,
    internetIncluded,
    councilTaxIncluded,
    gasIncluded,
    electricityIncluded,
    cleaningIncluded,
    tvLicenceIncluded,
    isFurnished,
    hasKitchenette,
    pets,
    couplesAllowed,
    isDouble,
    youtubeLink,
    virtualMeetingDetails,
  })
  .then((response) => response.data);

export const addAdvertPhoto = (advertId: string, bodyData: any): Promise<Iphoto> => client({
  method: 'post',
  url: `/adverts/${advertId}/photos`,
  headers: { 'Content-Type': 'multipart/form-data' },
  data: bodyData,
}).then((response) => response.data);

export const removePhoto = (advertId: string, photoId: string): Promise<null> => client
  .delete<null>(`/adverts/${advertId}/photos/${photoId}`)
  .then((response) => response.data);

export const fetchAdvertPhoto = (advertId: string): Promise<Iphoto[]> => client
  .get(`/adverts/${advertId}/photos`)
  .then((response) => response.data);

export const unpublishAdvert = (advertId: string): Promise<IRoomDetails> => client
  .post(`/adverts/${advertId}/unpublish`)
  .then((response) => response.data);

export const updateThumbNail = (advertId: string, photoId: string): Promise<any> => client
  .post(`/adverts/${advertId}/set-thumbnail`, {
    thumbnailId: photoId,
  })
  .then((response) => response.data);
