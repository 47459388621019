import * as yup from 'yup';

const youtubeRegex = /(^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*)|^$/;

export const firstStepSchema = yup.object({
  buildingNameOrNumber: yup.string().required('Building name or number is required'),
  streetName: yup.string().required('Street name is required'),
  town: yup.string().required('City is required'),
  flatNumber: yup.string().nullable(),
  postalCode: yup.string().required('Postal code is required'),
  typeId: yup.string().required('Type of proprty is required'),
}).required();

export const secondStepScheme = yup.object({
  livingRooms: yup.number()
    .typeError('you must specify a number')
    .required('number of livings is required')
    .min(0, 'Must not be a negative'),
  maxOccupancy: yup.number()
    .typeError('you must specify a number').required('max occupancy is required').min(0, 'Must not be a negative'),
  males: yup.number().typeError('you must specify a number')
    .required('number of males is required').min(0, 'Must not be a negative'),
  females: yup.number().typeError('you must specify a number')
    .required('number of females is required').min(0, 'Must not be a negative'),
  tenantTypeId: yup.string().required('tenant type is required'),
  maxAge: yup.number().typeError('you must specify a number')
    .required('maximum age is required').min(0, 'Must not be a negative'),
  minAge: yup.number().typeError('you must specify a number')
    .required('minimum age is required').min(0, 'Must not be a negative'),
  internetConnectionTypeId: yup.string().when(['wifi', 'hardwired'], {
    is: (wifi: boolean, hardwired: boolean) => wifi || hardwired,
    then: yup.string().required('internet connection type is required'),
    otherwise: yup.string(),
  }),
  provider: yup.string().when(['wifi', 'hardwired'], {
    is: (wifi: boolean, hardwired: boolean) => wifi || hardwired,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  speed: yup.number().when(['wifi', 'hardwired'], {
    is: (wifi: boolean, hardwired: boolean) => wifi || hardwired,
    then: yup.number().typeError('you must specify a number')
      .required('internet speeds are required').min(0, 'Must not be a negative'),
    otherwise: yup.number(),
  }),
  wifi: yup.boolean().required(),
  hardwired: yup.boolean().required('wired connection required'),
});

export const thirdStepScheme = yup.object({
  adTitle: yup.string().required('Advert title is required'),
  note: yup.string().required('Advert Description'),
  deposit: yup.number().required().when('rent', (
    rent: number, schema: any,
  ): any => schema.test({
    test: (deposit: number) => !!rent && ((rent * 12) / 52) * 5 >= deposit,
    message: 'deposit should not be more than 5 weeks rent',
  })),
  availableBy: yup.string().required('available by is required'),
  rent: yup.number().typeError('you must specify a number')
    .required().min(0, 'Must not be a negative'),
  rentFrequencyId: yup.string(),
  internetIncluded: yup.boolean().required(),
  councilTaxIncluded: yup.boolean().required(),
  gasIncluded: yup.boolean().required(),
  electricityIncluded: yup.boolean().required(),
  cleaningIncluded: yup.boolean().required(),
  tvLicenceIncluded: yup.boolean().required(),
  isFurnished: yup.boolean().required(),
  hasKitchenette: yup.boolean().required(),
  pets: yup.boolean().required(),
  couplesAllowed: yup.boolean().required(),
  isDouble: yup.boolean().required(),
  youtubeLink: yup.string().matches(youtubeRegex, 'invalid youtube Url').nullable(),
});

export const questionSchema = yup.object({
  question: yup.string().required(),
  desiredAnswer: yup.string().required('desired answer is required'),
  // eslint-disable-next-line consistent-return
  minimumValue: yup.string().when('desiredAnswer', (desiredAnswer): any => {
    if (desiredAnswer === 'value') {
      return yup.number()
        .required('value is required').min(1, 'Value must be more than 0');
    }
  }),
});
