import {
  Modal,
  Typography,
  Box,
  Button,
  Grid,
} from '@mui/material';
import * as React from 'react';
import { IScreeninQuestions } from '../../../store/reducers/createProperty';
import {
  ScreeningContainer,
} from '../styles';

interface ModalConfirmProps {
  open: boolean;
  handleClose(): void;
  handleNext(): void;
  screeningQuestions: IScreeninQuestions[]
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  open,
  handleClose,
  handleNext,
  screeningQuestions,
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="add-question-modal"
    aria-describedby="screening-questions-added"
  >
    <ScreeningContainer sx={{
      margin: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
    }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        ⚠️THESE ARE THE SCREENING QUESTIONS YOU WILL BE ASKING APPLICANTS - PLEASE READ
      </Typography>
      <Box sx={{
        mt: 3,
        mb: 3,
        overflow: 'auto',
        height: 400,
      }}
      >
        <Grid container spacing={2}>
          {screeningQuestions.map((dfn) => (
            <Grid xs={12} item>
              <ScreeningContainer>
                <Box display="flex" alignItems="flex-start">
                  <Typography
                    sx={{ fontWeight: '700' }}
                  >
                    Q:
                    {' '}
                  </Typography>
                  <Typography>
                    {dfn.question}
                  </Typography>
                </Box>
                {dfn.type === 'value' ? (
                  <Box display="flex" alignItems="flex-start">
                    <Typography
                      sx={{ fontWeight: '700' }}
                    >
                      R:
                      {' '}
                    </Typography>
                    <Typography>
                      {dfn.minimumValue || ''}
                    </Typography>
                  </Box>
                )
                  : (
                    <Box display="flex" alignItems="flex-start">
                      <Typography
                        sx={{ fontWeight: '700' }}
                      >
                        R:
                        {' '}
                      </Typography>
                      <Typography>
                        {dfn.desiredAnswer ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  )}
              </ScreeningContainer>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mb: 3 }} display="flex" flexDirection="row" justifyContent="space-between">
        <Button sx={{ mr: 1 }} color="error" variant="outlined" onClick={handleClose}>Edit Screening Questions</Button>
        <Button sx={{ mr: 1 }} variant="contained" onClick={handleNext}>Confirm Screening Questions</Button>
      </Box>
    </ScreeningContainer>
  </Modal>
);

export default ModalConfirm;
