import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button as MuiButton, ButtonProps } from '@mui/material';

type Props =
  LinkProps &
  Pick<ButtonProps, 'variant' | 'color' | 'startIcon' | 'endIcon' | 'children' | 'disabled' | 'size'>

const Button = (props: Props): JSX.Element => (
  <MuiButton component={Link} {...props} />
);

export default Button;
