import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/material';

interface MarkdownViewerProps {
  markdown: string;
}
const MarkdownViewer: React.FC<MarkdownViewerProps> = ({
  markdown,
}) => (
  <Box sx={{ ml: 2, mr: 2 }}>
    <ReactMarkdown className="markdown">{markdown}</ReactMarkdown>
  </Box>
);

export default MarkdownViewer;
