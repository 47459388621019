import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Instructions: React.FC = () => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="body2" gutterBottom>
      Please add instructions for all virtual meetings.
      Provide a link to the meeting or a description to attend the meeting virtually.
    </Typography>
  </Box>
);

export default Instructions;
