import { styled } from '@mui/material/styles';

const checkSize = 40;

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 8,
  width: checkSize,
  height: checkSize,
  backgroundColor: theme.colors[100],
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.3)',
    borderColor: '#ccc',
  },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:before': {
    display: 'block',
    width: checkSize,
    height: checkSize,
    color: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // eslint-disable-next-line max-len
    backgroundImage: 'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22-ms-transform%3A%20rotate(360deg)%3B%20-webkit-transform%3A%20rotate(360deg)%3B%20transform%3A%20rotate(360deg)%3B%22%3E%3Cpath%20d%3D%22M9%2019.414l-6.707-6.707l1.414-1.414L9%2016.586L20.293%205.293l1.414%201.414z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E")',
    content: '""',
  },
}));
