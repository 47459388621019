import {
  Modal,
  Typography,
  Box,
  Button,
} from '@mui/material';
import * as React from 'react';
import { IViewingSlot } from '../../../store/reducers/createProperty';
import { ScreeningContainer } from '../styles';
import AccordionViewingSlots from './AccordionViewingSlots';

interface ModalConfirmProps {
  open: boolean;
  handleClose(): void;
  handleNext(): void;
  viewingSlots: IViewingSlot[]
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  open,
  handleClose,
  handleNext,
  viewingSlots,
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="add-question-modal"
    aria-describedby="screening-questions-added"
  >
    <ScreeningContainer sx={{
      margin: 'auto',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
    }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        ⚠️PLEASE CONFIRM THE FOLLOWING VIEWING SLOTS
      </Typography>
      <Box sx={{ mt: 3, mb: 3 }}>
        <AccordionViewingSlots viewingSlots={viewingSlots} />
      </Box>
      <Box sx={{ mb: 3 }} display="flex" flexDirection="row" justifyContent="space-between">
        <Button sx={{ mr: 1 }} color="error" variant="outlined" onClick={handleClose}>Cancel</Button>
        <Button sx={{ mr: 1 }} variant="contained" onClick={handleNext}>Confirm</Button>
      </Box>
    </ScreeningContainer>
  </Modal>
);

export default ModalConfirm;
