import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';

import config from '../config';
import { AppHistory } from '../routes/BrowserHistory';
import store from '../store';

const httpClient = (baseURL: string): AxiosInstance => {
  const baseClient = axios.create({ baseURL, timeout: 30000 });
  baseClient.interceptors.request.use((request: any) => {
    const SESSION_TOKEN = store.getState().authState.sessionToken;
    if (SESSION_TOKEN) {
      request.headers.common.Authorization = `Bearer ${SESSION_TOKEN}`;
    }

    return request;
  });

  baseClient.interceptors.response.use((response) => response, (error) => {
    if (error.response?.status === 401 && error.response?.config?.url !== '/log-out') {
      AppHistory.replace('/logout');
      toast.info('Your session has expired, please login again', {
        toastId: 'sessionexpire',
      });
      // return;
    }
    throw error;
  });

  return baseClient;
};

export const client = httpClient(config.apiUrl as string);
