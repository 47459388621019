import React, { FC } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const CHECKOUT_STATUS_MAP: Record<string, { color: SvgIconProps['color'], message: string, icon: typeof SvgIcon }> = {
  succeeded: {
    color: 'success',
    message: 'Thank you for the payment!',
    icon: CheckCircleOutlineIcon,
  },
  canceled: {
    color: 'warning',
    message: 'Checkout cancelled!',
    icon: WarningAmberIcon,
  },
};

const CheckoutStatus: FC<{ status: string }> = (props): JSX.Element => {
  const { status } = props;
  const statusInfo = CHECKOUT_STATUS_MAP[status];
  const Icon = statusInfo.icon;

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" justifyContent="center" marginBottom={2}>
        <Icon style={{ fontSize: '6em' }} color={statusInfo.color} />
      </Box>
      <Typography
        textAlign="center"
        style={
          { fontSize: '2em' }
        }
      >
        {statusInfo.message}
      </Typography>
    </Box>
  );
};

export default CheckoutStatus;
