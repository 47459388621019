/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

type LogoColor = 'primary' | 'secondary'

interface Props extends SvgIconProps {
  // eslint-disable-next-line react/require-default-props
  color?: LogoColor;
}

const COLORS_MAP: { [key in LogoColor]: string } = {
  primary: '#474ed1',
  secondary: '#fff',
};

const NevoLogoIcon = (props: Props): JSX.Element => {
  const { color = 'primary' } = props;

  const fillColor = COLORS_MAP[color];

  return (
    <SvgIcon {...props} viewBox="0 0 1900 2000">
      <g fill="none" style={{ transform: 'none' }}>
        <g>
          <path
            d="M0 1000 l0 -1000 50 0 50 0 0 450 0 451 103 -3 102 -3 3 -247 2 -248 33 0 c30 1 38 9 134 153 56 84 132 196 168 250 l66 97 105 0 104 0 0 -450 0 -450 70 0 70 0 0 450 0 450 355 0 355 0 0 -75 0 -75 -245 0 -245 0 0 -120 0 -120 223 -2 222 -3 3 -62 3 -63 -226 0 -225 0 0 -105 0 -105 240 0 240 0 0 -85 0 -85 70 0 70 0 0 1000 0 1000 -950 0 -950 0 0 -1000z m1603 921 c117 -46 227 -183 243 -302 4 -27 10 -49 15 -49 5 0 9 -29 9 -65 0 -36 -4 -65 -9 -65 -4 0 -11 -20 -14 -45 -16 -115 -99 -225 -210 -280 -253 -124 -545 -26 -639 215 -30 76 -36 222 -13 310 38 148 141 255 285 294 83 23 257 16 333 -13z m-980 -28 c8 -27 72 -214 142 -418 70 -203 129 -378 132 -388 5 -16 -2 -18 -88 -15 l-94 3 -92 320 c-51 176 -92 323 -92 328 -1 4 -17 7 -36 7 -31 0 -35 -3 -45 -37 -5 -21 -47 -168 -93 -328 l-83 -290 -98 -3 -98 -3 7 23 c6 18 272 786 290 836 3 9 36 12 118 12 l114 0 16 -47z"
          />
          <path
            d="M1289 1777 c-32 -17 -50 -37 -69 -72 -23 -46 -25 -59 -25 -195 0 -132 2 -149 23 -192 37 -74 70 -92 175 -97 107 -4 127 1 171 40 56 49 71 103 71 254 -1 118 -3 135 -27 186 -35 76 -81 99 -195 99 -64 0 -88 -5 -124 -23z"
          />
          <path
            d="M612 488 c-16 -24 -62 -92 -104 -153 -177 -261 -198 -294 -198 -314 0 -21 3 -21 200 -21 l200 0 0 265 0 265 -35 0 c-29 0 -38 -6 -63 -42z"
          />
        </g>
      </g>

      <g fill={fillColor} style={{ transform: 'none' }}>
        <g>
          <path
            d="M1240 1964 c-143 -39 -247 -146 -285 -294 -24 -92 -23 -256 3 -337 62 -195 221 -303 448 -303 111 0 176 14 261 55 150 74 223 213 223 424 -1 164 -44 275 -142 366 -87 81 -141 98 -313 102 -102 2 -155 -2 -195 -13z m268 -215 c37 -18 49 -32 70 -77 21 -47 25 -71 26 -156 1 -120 -17 -179 -70 -225 -42 -38 -68 -45 -143 -39 -72 5 -111 31 -143 96 -20 41 -23 62 -23 162 0 104 3 120 25 165 46 89 159 121 258 74z"
          />
          <path
            d="M345 1958 c-102 -280 -312 -905 -306 -911 4 -4 66 -6 136 -5 l129 3 92 323 c84 295 103 353 105 326 0 -5 41 -153 92 -329 l92 -320 133 -3 c74 -1 132 1 132 6 0 5 -7 29 -16 53 -17 48 -256 744 -281 822 l-16 47 -144 0 c-106 0 -145 -3 -148 -12z"
          />
          <path
            d="M70 465 l0 -465 133 0 132 0 30 48 c57 88 288 427 301 442 12 12 14 -24 14 -238 l0 -252 135 0 135 0 0 465 0 465 -134 0 -135 0 -66 -97 c-36 -54 -112 -166 -168 -249 l-102 -151 -5 246 -5 246 -132 3 -133 3 0 -466z"
          />
          <path
            d="M1030 465 l0 -465 380 0 380 0 0 100 0 100 -240 0 -240 0 0 75 0 75 225 0 226 0 -3 93 -3 92 -222 3 -223 2 0 90 0 90 245 0 245 0 0 105 0 105 -385 0 -385 0 0 -465z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default NevoLogoIcon;
