import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  RadioGroup,
  Typography,
  Box,
  Button,
  Radio,
  FormHelperText,
} from '@mui/material';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  ScreeningContainer,
  ScreeningInput,
  TextInput,
} from '../styles';
import { questionSchema } from '../validations';

interface AddQuestionProps {
  open: boolean;
  handleClose(): void;
  handleData(data: any): Promise<void>;
}

const AddQuestion: React.FC<AddQuestionProps> = ({
  open,
  handleClose,
  handleData,
}) => {
  const {
    control,
    watch,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(questionSchema),
  });
  const watchAnswer = watch('desiredAnswer');
  React.useEffect(() => {
    if (watchAnswer !== 'value') clearErrors('minimumValue');
  }, [watchAnswer]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-question-modal"
      aria-describedby="screening-questions-added"
      component="form"
    >
      <ScreeningContainer sx={{
        margin: 'auto',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
      >
        <Typography>Additional question</Typography>
        <Controller
          control={control}
          name="question"
          render={({
            field: {
              onChange, onBlur, value, ref,
            },
          }) => (
            <TextInput
              size="small"
              sx={{ width: '100%' }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              error={!!errors.question?.message}
              helperText={errors.question?.message}
            />
          )}
        />
        <FormControl sx={{ mb: 1 }} component="fieldset">
          <FormLabel component="legend">Desired answer</FormLabel>
          <Controller
            control={control}
            name="desiredAnswer"
            render={({
              field: {
                onChange, onBlur, value, ref,
              },
            }) => (
              <RadioGroup
                row
                aria-label="desired-answer"
                name="desiredAnswer"
                sx={{ alignItems: 'center' }}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
              >
                <FormControlLabel value="yes" control={<Radio />} label="yes" />
                <FormControlLabel value="no" control={<Radio />} label="no" />
                <Typography sx={{ mr: 3, fontWeight: '700' }}>OR</Typography>
                <FormControlLabel value="value" control={<Radio />} label="A number above" />
                { watchAnswer === 'value' && (
                <Controller
                  control={control}
                  name="minimumValue"
                  defaultValue={0}
                  render={({
                    field: {
                      onChange: valueChange,
                      onBlur: blurred, value: inputValue, ref: refValue,
                    },
                  }) => (
                    <ScreeningInput
                      value={inputValue}
                      ref={refValue}
                      onChange={valueChange}
                      onBlur={blurred}
                      InputProps={{ inputProps: { min: 0 } }}
                      type="number"
                      size="small"
                    />
                  )}
                />
                )}
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormHelperText error sx={{ mb: 2 }}>
          {errors.desiredAnswer?.message || errors.minimumValue?.message}
        </FormHelperText>
        <Box sx={{ mb: 3 }} display="flex" flexDirection="row" justifyContent="space-between">
          <Button sx={{ mr: 1 }} color="error" variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSubmit(async (data) => {
              await handleData(data);
              reset();
            })}
          >
            Submit
          </Button>
        </Box>
      </ScreeningContainer>
    </Modal>
  );
};

export default AddQuestion;
