import React from 'react';
import Chip from '@mui/material/Chip';

interface ChipCharCounterProps {
  text: string;
  limit: number;
}

const textLength = (text: string): number => text.length;

const ChipCharCounter: React.FC<ChipCharCounterProps> = ({
  text,
  limit,
}) => (
  <>
    {
      text && (
      <Chip
        label={`${(limit) - textLength(text)} Characters Remaining`}
        color={textLength(text) === limit ? 'secondary' : 'primary'}
        variant="filled"
      />
      )
    }
  </>
);

export default ChipCharCounter;
