import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmailSentIcon from '../../assets/icons/mail-sent.svg';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 200,
    sm: 300,
    md: 500,
  },
  bgcolor: 'background.paper',
  border: '1px solid rgba(0,0,0,0.1)',
  boxShadow: 10,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
};

export interface VerifyEmailMessageProps {
  handleClose(): void;
  open: boolean;
}

const VerifyEmailMessage: React.FC<VerifyEmailMessageProps> = ({
  handleClose,
  open,
}) => (
  <div>
    <Modal
      aria-labelledby="verify-email-message"
      aria-describedby="verify-email-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style as any}>
          <img src={EmailSentIcon} height="100" alt="email sent" />
          <Typography id="verify-email-message" variant="h6" component="h2">
            A verification Link has been sent to your email
          </Typography>
          <Typography id="verify-email-description" sx={{ mt: 2 }}>
            Please click on the link that has just been sent to your email account
            to verify your email and finish the registration process.
          </Typography>
          <Button
            sx={{ mt: 3, width: 'fit-content', alignSelf: 'center' }}
            size="large"
            variant="contained"
            onClick={handleClose}
          >
            Proceed to Login
          </Button>
        </Box>
      </Fade>
    </Modal>
  </div>
);

export default VerifyEmailMessage;
