import { useEffect } from 'react';

const usePageTitle = (title: string): null => {
  useEffect(() => {
    document.title = `NEVO Dashboard | ${title}`;
  }, [title]);

  return null;
};

export default usePageTitle;
