export interface IDateObject {
  date: number;
  month: number;
  year: number;
  day: string;
  monthName: string;
  jsDate: Date;
}

const DaysOfTheWeek = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thur',
  5: 'Fri',
  6: 'Sat',
};

const getMonday = (d: Date): Date => {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

const dateObject = (date: number, month: number, year: number): IDateObject => {
  const dayDate = new Date(year, month, date);
  const WhichDay = dayDate.getDay() as keyof typeof DaysOfTheWeek;
  return {
    date,
    month,
    year,
    monthName: dayDate.toLocaleString('en-uk', { month: 'short' }),
    day: DaysOfTheWeek[WhichDay],
    jsDate: dayDate,
  };
};

const addDays = (date: Date, days: number): Date => {
  const ndate = new Date(date);
  ndate.setDate(ndate.getDate() + days);
  return ndate;
};

export const daysWeeksGenerator = (
  startDate: Date = getMonday(new Date()),
  daysRequested = 70,
): any => {
  const stopDate: Date = addDays(new Date(startDate), daysRequested - 1);
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    const curr = new Date(currentDate);
    dateArray.push(dateObject(curr.getDate(), curr.getMonth(), curr.getFullYear()));
    currentDate = addDays(currentDate, 1);
  }

  const perChunk = 7;

  const result = dateArray.reduce((resultArray: any[], item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] as any[]; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return result;
};
