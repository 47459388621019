import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPropertyParams, IPropertyTypes } from '../../sdk/propertyApi';

export interface ICreatePropertyState {
  data: IPropertyParams[];
  propertyTypes: IPropertyTypes[];
}

const initialState: ICreatePropertyState = {
  data: [],
  propertyTypes: [],
};

export const propertiesListSlice = createSlice({
  name: 'property-list',
  initialState,
  reducers: {
    propertiesListReducer: (
      state,
      action: PayloadAction<IPropertyParams[]>,
    ) => {
      state.data = action.payload;
    },
    propertiesTypesListReducer: (
      state,
      action: PayloadAction<IPropertyTypes[]>,
    ) => {
      state.propertyTypes = action.payload;
    },
  },
});

export const {
  propertiesListReducer,
  propertiesTypesListReducer,
} = propertiesListSlice.actions;

export default propertiesListSlice.reducer;
