import React from 'react';
import { ErrorOption } from 'react-hook-form';

export default function useScrollToError(errors: ErrorOption[]): void {
  React.useEffect(() => {
    const errorsvalues = Object.values(errors) as any;
    if (errorsvalues.length > 0) {
      const elements = Object.keys(errors).map((name) => document.getElementsByName(name)[0]).filter((el) => !!el);
      elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
      elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);
}
