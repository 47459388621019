import { client } from './client';

export interface IUserResponse {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
}

export const fetchUser = (): Promise<IUserResponse> => client
  .get<IUserResponse>('/me')
  .then((response) => response.data);
