import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISessionToken {
  sessionToken: string;
}

export interface LoginState extends ISessionToken {
  isLoggedIn: boolean;
}

const initialState: LoginState = {
  sessionToken: '',
  isLoggedIn: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    addSessionToken: (
      state,
      action: PayloadAction<{sessionToken: string}>,
    ) => {
      state.sessionToken = action.payload.sessionToken;
      state.isLoggedIn = true;
    },
    removeSessionToken: (state) => {
      state.sessionToken = '';
      state.isLoggedIn = false;
    },
  },
});

export const { addSessionToken, removeSessionToken } = loginSlice.actions;

export default loginSlice.reducer;
