import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  TextInput,
  FormContainer,
  LinkView,
  ButtonView as Button,
} from './styles';
import { registerSchema } from './validations';
import { IUserRegisterParams } from '../../sdk/authentication';
import VerifyEmailMessage, { VerifyEmailMessageProps } from './VerifyEmail';

export interface IregisterProps extends VerifyEmailMessageProps {
  handleSubmitData(userDetails: IUserRegisterParams): void;

  loading?: boolean;
}

const RegisterForm: React.FC<IregisterProps> = ({
  handleSubmitData,
  loading,
  open,
  handleClose,
}): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IUserRegisterParams>({
    resolver: yupResolver(registerSchema),
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword(!showPassword);
  const handleMouseDownPassword = (): void => setShowPassword(!showPassword);

  return (
    <FormContainer
      component="form"
      onSubmit={handleSubmit((data: IUserRegisterParams) => handleSubmitData(data))}
    >
      <Box>
        <Controller
          control={control}
          name="firstName"
          render={({
            field: {
              onChange,
              onBlur,
              value,
              ref,
            },
          }) => (
            <TextInput
              id="firstName-login"
              label="first name"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              helperText={errors.firstName?.message || ''}
              error={!!errors.firstName?.message}
              value={value || ''}
              ref={ref}
              type="text"
              fullWidth
              size="small"
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="lastName"
          render={({
            field: {
              onChange,
              onBlur,
              value,
              ref,
            },
          }) => (
            <TextInput
              id="lastName-login"
              label="last name"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type="text"
              helperText={errors.lastName?.message || ''}
              error={!!errors.lastName?.message}
              fullWidth
              size="small"
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="phoneNumber"
          render={({
            field: {
              onChange,
              onBlur,
              value,
              ref,
            },
          }) => (
            <TextInput
              id="phone-login"
              label="phone number"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type="tel"
              helperText={errors.phoneNumber?.message || ''}
              error={!!errors.phoneNumber?.message}
              fullWidth
              size="small"
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="username"
          render={({
            field: {
              onBlur,
              value,
              ref,
            },
          }) => (
            <TextInput
              id="email-login"
              label="email"
              variant="outlined"
              onChange={(e) => {
                const val = e.target.value || '';
                setValue('username', val.toLowerCase());
              }}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type="email"
              helperText={errors.username?.message || ''}
              error={!!errors.username?.message}
              fullWidth
              size="small"
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="password"
          render={({
            field: {
              onChange,
              onBlur,
              value,
              ref,
            },
          }) => (
            <TextInput
              id="password-login"
              label="password"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              helperText={errors.password?.message || ''}
              error={!!errors.password?.message}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="passwordConfirmation"
          render={({
            field: {
              onChange,
              onBlur,
              value,
              ref,
            },
          }) => (
            <TextInput
              id="password-confirm"
              label="confirm password"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type={showPassword ? 'text' : 'password'}
              helperText={errors.passwordConfirmation?.message || ''}
              error={!!errors.passwordConfirmation?.message}
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Button loading={loading} type="submit" variant="contained">Register</Button>
      <LinkView type="button" to="/login">
        Already have an account? Log in

      </LinkView>
      <VerifyEmailMessage open={open} handleClose={handleClose} />
    </FormContainer>
  );
};

RegisterForm.defaultProps = {
  loading: false,
};

export default RegisterForm;
