import React from 'react';
import { toast } from 'react-toastify';
import {
  Grid,
  CircularProgress,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Container,
  HeaderContainer,
  HeaderTitle,
  MainContainer,
} from '../Home/Ads/styles';
import { ILead } from '../../sdk/leads';
import ScreeningModal from './screeningAnswers';
import FilterSortContainer from './FilterSortContainer';
import DialogConfirm from '../common/DialogConfirm';
import DateTimeUtils from '../../utils/DateTimeUtils';
import { cancelLeadAction, getLeadListAction, rebookLeadAction } from '../../store/actions/LeadActions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import RebookViewingSlots from '../CreateAd/ViewingSlots/RebookViewingSlots';
import LeadCard from '../common/LeadCard';

export const ScreeningStatus = {
  created: {
    color: '#1E90FF',
    name: 'CREATED',
  },
  failed: {
    color: '#DE3163',
    name: 'FAILED',
  },
  succeeded: {
    color: '#B666D2',
    name: 'PASSED',
  },
  viewing_slots_requested: {
    color: '#C19A6B',
    name: 'VIEWING SLOTS REQUESTED',
  },
  booked: {
    color: '#B666D2',
    name: 'BOOKED',
  },
  confirmed: {
    color: '#008080',
    name: 'CONFIRMED',
  },
  cancelled: {
    color: '#A70D2A',
    name: 'CANCELLED',
  },
};

export const SingleLead: React.FC<{ lead: any, leadsList: ILead[] }> = ({
  lead,
  leadsList,
}) => {
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = React.useState(false);
  const [modalRebook, setModalRebook] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [dialog, setDialog] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    viewer, viewingSlot, id: leadId, advert,
  } = lead || {};

  const { id: advertId } = advert || {};
  const { name = '' } = viewer || {};
  const { dateAndTime = '' } = viewingSlot || {};
  const in30Min = DateTimeUtils.isAfterDate(DateTimeUtils.subMinutesDate(DateTimeUtils.now(), 30), dateAndTime);

  const handleCancelLead = async (id: string): Promise<void> => {
    try {
      await dispatch(cancelLeadAction(id));
      await dispatch(getLeadListAction());
      toast.success('Lead cancelled successfully');
    } catch (error) {
      toast.error('Something went wrong while cancelling the lead');
    }
  };
  const handleRebookLead = async (id: string, leadid: string): Promise<void> => {
    try {
      const { viewingSlot: slot } = leadsList.find((f) => f.id === leadid) || {};
      const { id: slotId } = slot || {};

      await dispatch(rebookLeadAction(id, slotId || ''));
      await dispatch(getLeadListAction());
      toast.success('Lead rebooked successfully');
    } catch (error) {
      toast.error('Something went wrong while rebooking the lead');
    }
  };

  return (
    <>
      <Grid
        key={leadId}
        direction="column"
        flexWrap="nowrap"
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ width: '100%' }}
      >
        <LeadCard
          lead={lead}
          onOptionsClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpenMenu(true);
          }}
          onScreeningClick={() => setModalState(true)}
        />

        {modalState && <ScreeningModal id={leadId} open={modalState} handleClose={() => setModalState(false)} />}

        <DialogConfirm
          open={dialog === 'Rebook' || dialog === 'Cancel'}
          handleClose={() => setDialog('')}
          title={`Confirm ${dialog} Appointment`}
          content={dialog === 'Cancel' ? `Just to confirm you will be cancelling the following appointment
        : ${name}, @ ${DateTimeUtils.Format.cancelDate(dateAndTime)}`
            : `We will cancel these viewing slots and ask all people booked on this slot to choose another date and time for a viewing? 
          ${in30Min ? '⚠️ The viewing is in less than 30 mins, please call the applicants.' : ''}`}
          handleAgree={() => {
            if (dialog === 'Cancel') {
              handleCancelLead(leadId);
            } else {
              setModalRebook(true);
            }
            setDialog('');
          }}
        />
        {
          modalRebook && (
            <RebookViewingSlots
              open={modalRebook}
              setOpen={setModalRebook}
              advertId={advertId}
              leadId={leadId}
              handleRebookLead={handleRebookLead}
              leadsList={leadsList}
            />
          )
        }
      </Grid>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => {
          setOpenMenu(false);
          setDialog('Rebook');
        }}
        >
          Rebook Leads
        </MenuItem>
        <MenuItem onClick={() => {
          setOpenMenu(false);
          setDialog('Cancel');
        }}
        >
          Cancel Lead
        </MenuItem>
      </Menu>
    </>
  );
};

const LeadManagerContainerContent = ({
  data,
  leadsList,
}: {
  data: ILead[]
  leadsList: ILead[]
}): JSX.Element => (
  <>
    {data.map((lead, index) => (
      <SingleLead key={lead?.id} lead={lead} leadsList={leadsList} />))}
  </>
);
const LeadManagerContainer = (): JSX.Element => {
  const [data, setData] = React.useState<ILead[]>([]);
  const [initialData, setInitialData] = React.useState<ILead[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  const leadsList = useAppSelector((state) => state.leadState.leads);

  const getLeads = async (): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(getLeadListAction());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('something went while fetching your leads, please contact support');
    }
  };
  React.useEffect(() => {
    getLeads();
  }, []);

  React.useEffect(() => {
    if (leadsList.length) {
      setData(leadsList);
      setInitialData(leadsList);
    }
  }, [leadsList]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle variant="h4">LEAD MANAGER</HeaderTitle>
        <FilterSortContainer
          data={data || []}
          initialData={initialData || []}
          handleData={(dta: ILead[]) => setData(dta)}
        />
      </HeaderContainer>
      <MainContainer>
        {loading
          ? (
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
                height: '50vh',
              }}
            >
              <CircularProgress />
            </Box>
          )
          : <LeadManagerContainerContent data={data || []} leadsList={leadsList} />}
      </MainContainer>
    </Container>
  );
};
export default LeadManagerContainer;
