import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '../../components/layout/Navigation/Button';
import useQuery from '../../hooks/useQuery';
import NevoLogoIcon from '../../components/icons/NevoLogo';
import CheckoutStatus from './CheckoutStatus';

const Checkout: FC = () => {
  const queryParams = useQuery();
  const statusParam = queryParams.get('status');

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center" marginBottom={10}>
          <Link to="/">
            <NevoLogoIcon style={{ fontSize: '6em' }} />
          </Link>
        </Box>

        {
          statusParam
          && <CheckoutStatus status={statusParam} />
        }

        <Box display="flex" justifyContent="center" marginTop={10}>
          <Button to="/" replace variant="contained">Go to dashboard</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Checkout;
