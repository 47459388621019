import {
  configureStore, ThunkAction, Action, combineReducers,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import Auth from './reducers/login';
import profile from './reducers/profile';
import propertyList from './reducers/listProperties';
import createproperty from './reducers/createProperty';
import listAdvert from './reducers/listAdvert';
import listLead from './reducers/listLead';

const reducers = combineReducers({
  authState: Auth,
  profileState: profile,
  propertyListState: propertyList,
  createpropertyState: createproperty,
  AdvertListState: listAdvert,
  leadState: listLead,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authState', 'profileState', 'createpropertyState', 'leadState'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
