import { Box, AppBar } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import NevoLogoIcon from '../icons/NevoLogo';

const Navbar = (): JSX.Element => (
  <AppBar position="static" sx={{ width: '100vw', margin: 0 }}>
    <Box sx={{ margin: theme.spacing(2, 3) }} component="div">
      <NevoLogoIcon color="secondary" fontSize="large" />
    </Box>
  </AppBar>
);

export default Navbar;
