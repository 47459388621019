import {
  StepLabel,
  Stepper,
} from '@mui/material';
import React from 'react';
import {
  SideBarContainer,
  StepNumber,
  StepView,
} from '../styles';

interface VerticalStepperProps {
  steps: string[];
  activeStep: number;
}

const VerticalStepper: React.FC<VerticalStepperProps> = ({
  steps,
  activeStep,
}) => (
  <SideBarContainer>
    <StepNumber>
      STEP
      {' '}
      {activeStep + 1}
      {' '}
      OF
      {' '}
      {
        steps.length
      }
    </StepNumber>
    {/* <StepperTitle variant="h5">{title}</StepperTitle> */}
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((label: string) => (
        <StepView key={label}>
          <StepLabel>{label}</StepLabel>
        </StepView>
      ))}
    </Stepper>
  </SideBarContainer>
);

export default VerticalStepper;
