import { AppDispatch } from '..';
import { leadListReducer, removeLeadReducer } from '../reducers/listLead';
import {
  cancelLead, fetchLeads, ILead, rebookLead,
} from '../../sdk/leads';

export const getLeadListAction = () => async (
  dispatch: AppDispatch,
): Promise<ILead[]> => {
  try {
    const res = await fetchLeads();
    dispatch(leadListReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    if (error.response?.status === 401) {
      return [];
    }

    throw error?.response;
  }
};

export const cancelLeadAction = (id: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await cancelLead(id);
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const rebookLeadAction = (id: string, viewingSlotId: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await rebookLead(id, viewingSlotId);
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};
