import {
  format, parseJSON, isAfter, subMinutes,
} from 'date-fns';

type DateTimeFormats = 'cancelDate' | 'dateTime'

const FORMATS: Record<DateTimeFormats, string> = {
  cancelDate: "p 'on' iiii do",
  dateTime: 'dd/MM/y, h:mm aa',
};

const now = () : Date => new Date();

const parse = (datetime: string): Date => parseJSON(datetime || JSON.stringify(new Date(0)));

const formatDate = (
  datetime: Date,
  dateFormat: DateTimeFormats,
) : any => format(datetime, FORMATS[dateFormat]);

const cancelDate = (datetime: string) : string => formatDate(parse(datetime), 'cancelDate');
const dateTime = (datetime: string) : string => formatDate(parse(datetime), 'dateTime');

const isAfterDate = (date: Date, dateToCompare: string): boolean => isAfter(date, parse(dateToCompare));

const subMinutesDate = (date: Date, minutes: number): Date => subMinutes(date, minutes);

const DateTimeUtils = {
  parse,
  now,
  isAfterDate,
  subMinutesDate,
  Format: {
    cancelDate,
    dateTime,
  },
};

export default DateTimeUtils;
