import * as React from 'react';
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format, compareAsc, isAfter } from 'date-fns';
import { BpCheckedIcon } from '../../calendar/styles';
import { IViewingSlot } from '../../../store/reducers/createProperty';
import ChipIsVirtual from '../../common/ChipIsVirtual';
import ChipIsMaxAttendees from '../../common/ChipIsMaxAttendees';

interface AccordionViewingSlotsProps {
  viewingSlots: IViewingSlot[]
}

const AccordionViewingSlots: React.FC<AccordionViewingSlotsProps> = ({
  viewingSlots,
}) => {
  const [expanded, setExpanded] = React.useState('');
  const handleExpand = (id: string): void => {
    if (id === expanded) {
      setExpanded('');
      return;
    }
    setExpanded(id);
  };
  const groupSlots = (): any[] => {
    if (viewingSlots?.length > 0) {
      const cpSlots = [...viewingSlots];
      const sortSLots = cpSlots.filter((slot) => isAfter(new Date(slot.dateAndTime), new Date())).sort(
        (dateObjA, dateObjB) => compareAsc(
          new Date(dateObjA.dateAndTime), new Date(dateObjB.dateAndTime),
        ),
      );
      const finalDates: any[] = [];
      sortSLots.forEach((slot) => {
        const date = slot.dateAndTime.split('T')[0];
        const foundDateIndex = finalDates.findIndex((e) => e.date === date);
        if (foundDateIndex > -1) {
          finalDates[foundDateIndex].slots.push({
            time: format(new Date(slot.dateAndTime), 'p'),
            isVirtual: slot.isVirtual,
            isOnsite: slot.isOnsite,
            maxAttendees: slot.maxAttendees,
          });
        } else {
          finalDates.push({
            date,
            name: `${format(new Date(date), 'cccc do')} of ${format(new Date(date), 'MMMM yyyy')}`,
            slots: [{
              time: format(new Date(slot.dateAndTime), 'p'),
              isVirtual: slot.isVirtual,
              isOnsite: slot.isOnsite,
              maxAttendees: slot.maxAttendees,
            }],
          });
        }
      });
      return finalDates;
    }
    return [];
  };
  return (
    <>
      {groupSlots().map((row) => {
        const id = row.date;
        return (
          <Accordion
            sx={{
              borderColor: '#485fc7',
              backgroundColor: '#fff',
              borderStyle: 'solid',
              borderWidth: 0,
              borderLeftWidth: 4,
              marginBottom: 2,
            }}
            key={id}
            expanded={expanded === id}
            onChange={() => handleExpand(id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${id}-content`}
              id={`${id}-header`}
            >
              <Typography><b>{row.name}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                row?.slots?.map((slot: { time: string, isVirtual: boolean, isOnsite: boolean, maxAttendees: number }) => (
                  <Box display="flex" alignItems="center" sx={{ my: 1 }}>
                    <BpCheckedIcon sx={{
                      width: 30,
                      height: 30,
                      '&:before': {
                        width: 28, height: 28,
                      },
                    }}
                    />
                    {' '}
                    <Typography sx={{ ml: 1 }}>{slot.time}</Typography>
                    <Stack
                      sx={{ ml: 1 }}
                      spacing={1}
                      direction="row"
                    >
                      <ChipIsVirtual isVirtual={slot?.isVirtual} isOnsite={slot?.isOnsite} useIcon />
                      <ChipIsMaxAttendees maxAttendees={slot?.maxAttendees} useIcon />
                    </Stack>
                  </Box>
                ))
              }
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default AccordionViewingSlots;
