import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserResponse } from '../../sdk/profile';

export interface ISessionToken {
  sessionToken: string;
}

export interface ProfileState {
  userExist: boolean;
  user: IUserResponse | null;
}

const initialState: ProfileState = {
  userExist: false,
  user: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    addUserInfo: (
      state,
      action: PayloadAction<IUserResponse>,
    ) => {
      state.user = action.payload;
      state.userExist = true;
    },
    removeUser: (state) => {
      state.user = null;
      state.userExist = false;
    },
  },
});

export const { addUserInfo, removeUser } = profileSlice.actions;

export default profileSlice.reducer;
