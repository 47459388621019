import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      [key: string | number]: string;
    };
  }

  interface ThemeOptions {
    colors: {
      [key: string | number]: string;
    };
  }
}

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  colors: {
    light: '#868e96',
    gains: '#d2d8de',
    dark: '#212529',
    white: '#ffffff',
    30: '#EDEEFB',
    200: '#c7cbf6',
    100: '#fafafe',
  },
  palette: {
    primary: {
      light: '#999efa',
      main: '#3c4ccc',
      dark: '#0f1585',
      contrastText: '#fff',
    },
  },
  typography: {
    // fontFamily: "'Poppins', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
  },
});

theme = responsiveFontSizes(theme);

export default theme;
