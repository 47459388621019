import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { styled } from '@mui/material/styles';

interface DialogConfirmProps {
  open: boolean;
  handleClose: any;
  handleAgree: any | undefined;
  title: string;
  content: string;
}

export const ListStyle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const DialogConfirm: React.FC<DialogConfirmProps> = ({
  open,
  handleAgree,
  handleClose,
  title,
  content,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {title}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {content}
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'center' }}>
      <Button onClick={handleAgree} variant="contained" color="error">OK</Button>
    </DialogActions>
  </Dialog>
);

export default DialogConfirm;
