import { Button } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ButtonContainer } from '../styles';

interface BottomBtnProps {
  handleNext: any;
  handleBack(): void;
  typeSubmit: 'submit' | 'button';
  loading?: boolean | undefined;
  disabledBack?: boolean | undefined;
  disabledNext?: boolean | undefined;
  backText?: string;
  nextText?: string;
}

const BottomBtn: React.FC<BottomBtnProps> = ({
  handleNext,
  handleBack,
  typeSubmit,
  loading,
  disabledBack,
  disabledNext,
  backText = 'Back',
  nextText = 'Next',
}) => (
  <ButtonContainer>
    <Button disabled={loading || disabledBack} onClick={handleBack}>
      <ChevronLeftIcon />
      {backText}
    </Button>
    {handleNext && (
    <LoadingButton loading={loading} disabled={disabledNext} type={typeSubmit} onClick={handleNext} variant="contained">
      {nextText}
    </LoadingButton>
    )}
  </ButtonContainer>
);

BottomBtn.defaultProps = {
  loading: false,
  disabledBack: false,
  disabledNext: false,
};

export default BottomBtn;
