import { toast } from 'react-toastify';

export const parseApiError = (error: any, fallbackMessage?: string): void => {
  if (error.length > 0) {
    if (error[0].type === 'ApiError') {
      toast.error(error[0].message || 'invalid email/password');
    } else if (error[0].type === 'ValidationError') {
      toast.error('Please confirm if your details are correct');
    }
  } else {
    toast.error(fallbackMessage || 'something went wrong, please try again');
  }
};
