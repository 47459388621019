import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent, DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportIcon from '@mui/icons-material/Report';
import { steps } from '../../../views/CreateAd';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import {
  ButtonContainer,
  CheckBoxContainer,
  CheckBoxLabel,
  ChipContainer,
  FormContainer,
  KitchenField,
  Label,
  ScreeningContainer,
  ScreeningInput,
  SectionHead,
  TextInput,
  TextInputContainer,
} from '../styles';
import {
  listAmenities,
  listInternetTypes,
  listPropertyTypes,
  listTenantTypes,
  rentFrequenciesList,
} from '../../../sdk/propertyApi';
import { useRequestState } from '../../../hooks/request';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { UnpublishAdvertAction, updateAdvertDetails } from '../../../store/actions/PropertyActions';
import { resetStateReducer } from '../../../store/reducers/createProperty';
import { CheckBoxesAmenities, CheckBoxesRent } from '../RoomDetails';
import AccordionVirtualMeetingDetails from './AccordionVirtualMeetingDetails';
import PhotoPaper from '../../common/PhotoPaper';
import AccordionViewingSlots from '../ViewingSlots/AccordionViewingSlots';
import ProductSelect from '../../ProductSelect';

interface ILocationState {
  id?: string;
}

const SummaryPage: React.FC = () => {
  const locationState = useLocation().state as ILocationState;
  const [unpublishDialogOpen, setUnpublishDialogOpen] = React.useState<boolean>(false);
  const [publishDialogOpen, setPublishDialogOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    data,
    roomDetails,
    viewingSlots,
    screeningQuestions,
    photos,
    bathrooms,
    kitchens,
    propertyAmenities,
    published,
  } = useAppSelector((state) => state.createpropertyState);

  const navigate = useNavigate();
  const {
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      if (data?.id) {
        const {
          buildingNameOrNumber,
          flatNumber,
          streetName,
          town,
          postalCode,
          typeId,
        } = data;
        return {
          buildingNameOrNumber,
          flatNumber,
          streetName,
          town,
          postalCode,
          typeId,
          livingRooms: data?.livingRooms,
          maxOccupancy: data?.maxOccupancy,
          males: data?.males,
          females: data?.females,
          maxAge: data?.maxAge,
          minAge: data?.minAge,
          tenantTypeId: data?.tenantTypeId,
          speed: data?.internetConnection?.speed,
          hardwired: data?.internetConnection?.hardwired,
          internetConnectionTypeId: data?.internetConnection?.internetConnectionTypeId,
          wifi: data?.internetConnection?.wifi,
          provider: data?.internetConnection?.provider,
          epcExpiry: data?.epcExpiry,
          hmoLicenceExpiry: data?.hmoLicenceExpiry,
          fireSafetyExpiry: data?.fireSafetyExpiry,
          gasExpiry: data?.gasExpiry,
          ...roomDetails as any,
        };
      }
      return {} as any;
    }, [data?.id, roomDetails?.id]),
  });
  React.useEffect(() => {
    if (locationState?.id && data?.id) {
      reset({
        ...data,
        ...roomDetails,
        rentFrequencyId: (roomDetails as any)?.rentFrequency?.id,
        livingRooms: data?.livingRooms,
        maxOccupancy: data?.maxOccupancy,
        males: data?.males,
        females: data?.females,
        maxAge: data?.maxAge,
        minAge: data?.minAge,
        tenantTypeId: data?.tenantTypeId,
        speed: data?.internetConnection?.speed,
        hardwired: data?.internetConnection?.hardwired,
        internetConnectionTypeId: data?.internetConnection?.internetConnectionTypeId,
        wifi: data?.internetConnection?.wifi,
        provider: data?.internetConnection?.provider,
        epcExpiry: data?.epcExpiry,
        hmoLicenceExpiry: data?.hmoLicenceExpiry,
        fireSafetyExpiry: data?.fireSafetyExpiry,
        gasExpiry: data?.gasExpiry,
      });
    }
  }, [data?.id, roomDetails?.id]);

  const propertyTypes = useRequestState(listPropertyTypes);
  const rentFrequencies = useRequestState(rentFrequenciesList);
  const tenantsTypes = useRequestState(listTenantTypes);
  const internetTypes = useRequestState(listInternetTypes);
  const amenitiesList = useRequestState(listAmenities);
  const getDetails = async (id: string): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(updateAdvertDetails(id, true));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('something went wrong while fetching the property details, please try again later');
    }
  };
  React.useEffect(() => {
    if ((data?.id && roomDetails?.id) || locationState?.id) {
      if (locationState?.id) {
        getDetails(locationState.id);
      }
      propertyTypes.fetch();
      rentFrequencies.fetch();
      tenantsTypes.fetch();
      internetTypes.fetch();
      amenitiesList.fetch();
    } else {
      toast.error('create property first before adding details');
      navigate('/create-ad');
    }
  }, []);
  const handleUnpublishClick = (): void => {
    setUnpublishDialogOpen(true);
  };

  const handlePublishClick = (): void => {
    setPublishDialogOpen(true);
  };

  const handleClose = (event: any): void => {
    setPublishDialogOpen(false);
    setUnpublishDialogOpen(false);
  };

  const handleUnpublish = async (): Promise<void> => {
    dispatch(UnpublishAdvertAction(roomDetails?.id as string));
    setUnpublishDialogOpen(false);
    navigate('/dashboard');
  };
  const handleSaveExit = (): void => {
    dispatch(resetStateReducer());
    navigate('/dashboard');
  };
  const watchRent = watch('rent');
  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={8} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      {loading ? (
        <Box sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          height: '50vh',
        }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormContainer>
            {/* <------- Property Address section -----> */}
            <Box>
              <SectionHead>
                <Typography sx={{ fontWeight: 'bold' }}>Property Address</Typography>
                <Button onClick={() => navigate('/create-ad/property-address')}>
                  <EditIcon sx={{ mr: 1 }} />
                  {'  '}
                  Edit Section
                </Button>
              </SectionHead>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="buildingNameOrNumber"
                    render={({
                      field: {
                        value,
                        ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-buildingNameOrNumber"
                        label="building name or number"
                        variant="outlined"
                        disabled
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="flatNumber"
                    render={({
                      field: {
                        value,
                        ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-flatNumber"
                        label="flat number"
                        variant="outlined"
                        disabled
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box>
                <Controller
                  control={control}
                  name="streetName"
                  render={({
                    field: {
                      value,
                      ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-streetName"
                      label="street name, number"
                      variant="outlined"
                      disabled
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="town"
                    render={({
                      field: {
                        value,
                        ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-city"
                        label="city"
                        variant="outlined"
                        disabled
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="postalCode"
                    render={({
                      field: {
                        value,
                        ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-postCode"
                        label="postal code"
                        variant="outlined"
                        disabled
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box>
                <Controller
                  control={control}
                  name="typeId"
                  render={({
                    field: {
                      value, ref,
                    },
                  }) => (
                    <FormControl sx={{ width: '100%', margin: '12px 0' }}>
                      <TextInput
                        id="select-property-type"
                        value={value || ''}
                        defaultValue=""
                        select
                        disabled
                        label="Select property type"
                        ref={ref}
                      >
                        {propertyTypes.value?.map((type) => (
                          <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                        ))}
                      </TextInput>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            {/* <--------- Property Details section ----> */}
            <Box>
              <SectionHead>
                <Typography sx={{ fontWeight: 'bold' }}>Property Details</Typography>
                <Button onClick={() => navigate('/create-ad/property-details')}>
                  <EditIcon sx={{ mr: 1 }} />
                  {'  '}
                  Edit Section
                </Button>
              </SectionHead>
              <Typography sx={{ mb: 2, fontWeight: 'bold' }}>Number Of Shared Bathrooms</Typography>
              <Grid container spacing={2}>
                {bathrooms.map((bathroom, idx) => (
                  <Grid key={`bathroom-${bathroom.id}`} item xs={12} sm={6}>
                    <CheckBoxContainer sx={{ p: 1 }} variant="standard">
                      <CheckBoxLabel>
                        Bathroom
                        {' '}
                        {idx + 1}
                      </CheckBoxLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={bathroom.hasToilet}
                              disabled
                              name="hasToilet"
                            />
                          )}
                          label="Toilet"
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={bathroom.hasShower}
                              disabled
                              name="hasShower"
                            />
                          )}
                          label="Shower"
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={bathroom.hasBath}
                              disabled
                              name="hasBath"
                            />
                          )}
                          label="Bath"
                        />
                      </FormGroup>
                    </CheckBoxContainer>
                  </Grid>
                ))}
              </Grid>
              <Typography sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>Number Of Shared Kitchens</Typography>
              <Grid container spacing={2}>
                {kitchens.map((kitchen, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid key={`kitchen-${idx}`} item xs={12} sm={6}>
                    <CheckBoxContainer sx={{ p: 1 }} variant="standard">
                      <CheckBoxLabel>
                        Kitchen
                        {' '}
                        {idx + 1}
                      </CheckBoxLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <KitchenField
                              type="number"
                              size="small"
                              InputProps={{ inputProps: { min: 0 } }}
                              value={kitchen.numberOfHobs}
                              disabled
                              name="numberOfHobs"
                            />
                          )}
                          label="Hobs"
                        />
                        <FormControlLabel
                          control={(
                            <KitchenField
                              type="number"
                              size="small"
                              InputProps={{ inputProps: { min: 0 } }}
                              value={kitchen.microwaves}
                              disabled
                              name="microwaves"
                            />
                          )}
                          label="Microwaves"
                        />
                        <FormControlLabel
                          control={(
                            <KitchenField
                              type="number"
                              size="small"
                              InputProps={{ inputProps: { min: 0 } }}
                              value={kitchen.ovens}
                              disabled
                              name="ovens"
                            />
                          )}
                          label="Ovens"
                        />
                        <FormControlLabel
                          control={(
                            <KitchenField
                              type="number"
                              size="small"
                              InputProps={{ inputProps: { min: 0 } }}
                              value={kitchen.fridges}
                              disabled
                              name="fridges"
                            />
                          )}
                          label="Fridges"
                        />
                        <FormControlLabel
                          control={(
                            <KitchenField
                              type="number"
                              value={kitchen.freezers}
                              InputProps={{ inputProps: { min: 0 } }}
                              disabled
                              size="small"
                              name="freezers"
                            />
                          )}
                          label="Freezers"
                        />
                      </FormGroup>
                    </CheckBoxContainer>
                  </Grid>
                ))}
              </Grid>
              <Typography sx={{ mt: 6, mb: 2, fontWeight: 'bold' }}>Amenities in the House</Typography>
              <ChipContainer mb={2}>
                {amenitiesList?.value?.map((amenity) => {
                  const ifExists = propertyAmenities?.some((el) => el.id === amenity.id);
                  return (
                    <Chip
                      label={amenity.name}
                      key={amenity.id}
                      sx={{ mr: 2, mb: 2 }}
                      color={ifExists ? 'primary' : undefined}
                    />
                  );
                })}
              </ChipContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <Controller
                      control={control}
                      name="maxOccupancy"
                      render={({
                        field: {
                          value, ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-occupancy"
                          label="max occupancy"
                          variant="outlined"
                          type="number"
                          disabled
                          value={value || ''}
                          ref={ref}
                          InputProps={{ inputProps: { min: 0 } }}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </TextInputContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <Controller
                      control={control}
                      name="males"
                      render={({
                        field: {
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-males"
                          label="Number of males"
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          disabled
                          value={value || ''}
                          ref={ref}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </TextInputContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <Controller
                      control={control}
                      name="females"
                      render={({
                        field: {
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-females"
                          label="Number of females"
                          variant="outlined"
                          type="number"
                          disabled
                          value={value || ''}
                          ref={ref}
                          InputProps={{ inputProps: { min: 0 } }}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </TextInputContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <FormControl sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="tenantTypeId"
                        render={({
                          field: {
                            onBlur,
                            value,
                            ref,
                          },
                        }) => (
                          <>
                            <TextInput
                              value={value}
                              onBlur={onBlur}
                              ref={ref}
                              size="small"
                              select
                              label="Tenant Type"
                              disabled
                            >
                              {tenantsTypes?.value?.map((
                                tenant,
                              ) => <MenuItem value={tenant.id}>{tenant.name}</MenuItem>)}
                            </TextInput>
                          </>
                        )}
                      />
                    </FormControl>
                  </TextInputContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <Controller
                      control={control}
                      name="minAge"
                      render={({
                        field: {
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-minAge"
                          label="Minimum Age"
                          variant="outlined"
                          type="number"
                          disabled
                          value={value || ''}
                          ref={ref}
                          InputProps={{ inputProps: { min: 0 } }}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </TextInputContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <Controller
                      control={control}
                      name="maxAge"
                      render={({
                        field: {
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-maxAge"
                          label="maximum Age"
                          variant="outlined"
                          type="number"
                          value={value || ''}
                          ref={ref}
                          fullWidth
                          disabled
                          InputProps={{ inputProps: { min: 0 } }}
                          size="small"
                        />
                      )}
                    />
                  </TextInputContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputContainer>
                    <Controller
                      control={control}
                      name="livingRooms"
                      render={({
                        field: {
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          name="livingRooms"
                          label="Number of Living rooms"
                          type="number"
                          size="small"
                          value={value || ''}
                          InputProps={{ inputProps: { min: 0 } }}
                          ref={ref}
                          disabled
                        />
                      )}
                    />
                  </TextInputContainer>
                </Grid>
              </Grid>
              <TextInputContainer sx={{ mt: 5 }}>
                <Label>Internet connection</Label>
                <FormControl sx={{ margin: '12px 0', width: '50%' }}>
                  <Controller
                    control={control}
                    name="internetConnectionTypeId"
                    render={({
                      field: {
                        onChange,
                        value,
                        ref,
                      },
                    }) => (
                      <>
                        <TextInput
                          value={value}
                          ref={ref}
                          onChange={onChange}
                          size="small"
                          select
                          disabled
                          label="Internet connection type"
                        >
                          {internetTypes?.value?.map((net) => (
                            <MenuItem value={net.id}>{net.name}</MenuItem>
                          ))}
                        </TextInput>
                      </>
                    )}
                  />
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="provider"
                      render={({
                        field: {
                          onChange, value, onBlur, ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-net-provide"
                          label="Internet provider name"
                          variant="outlined"
                          type="text"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ''}
                          ref={ref}
                          fullWidth
                          disabled
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="speed"
                      render={({
                        field: {
                          onChange, value, onBlur, ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-net-speeds"
                          label="Internet speeds in mbps"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          disabled
                          onBlur={onBlur}
                          value={value || ''}
                          ref={ref}
                          fullWidth
                          InputProps={{ inputProps: { min: 0 } }}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ mt: 2, fontSize: 14 }}>Wifi available?</FormLabel>
                      <Controller
                        control={control}
                        name="wifi"
                        render={({
                          field: {
                            onChange, value, ref,
                          },
                        }) => (
                          <RadioGroup ref={ref} row aria-label="wifi" value={value} onChange={onChange} name="wifi">
                            <FormControlLabel disabled value control={<Radio />} label="yes" />
                            <FormControlLabel disabled value={false} control={<Radio />} label="no" />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" sx={{ mt: 2, fontSize: 14 }}>Wired connection available?</FormLabel>
                      <Controller
                        control={control}
                        name="hardwired"
                        render={({
                          field: {
                            value, ref,
                          },
                        }) => (
                          <RadioGroup value={value} ref={ref} row aria-label="wiredNet" name="wiredNet">
                            <FormControlLabel disabled value control={<Radio />} label="yes" />
                            <FormControlLabel disabled value={false} control={<Radio />} label="no" />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </TextInputContainer>

            </Box>
            {/* <------- Advert details section -----> */}
            <Box>
              <SectionHead>
                <Typography sx={{ fontWeight: 'bold' }}>Advert details Sections</Typography>
                <Button onClick={() => navigate('/create-ad/room-details')}>
                  <EditIcon sx={{ mr: 1 }} />
                  {'  '}
                  Edit Section
                </Button>
              </SectionHead>
              <Box>
                <Controller
                  control={control}
                  name="adTitle"
                  render={({
                    field: {
                      onChange,
                      onBlur,
                      value,
                      ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-ad-adTitle"
                      label="Advert title"
                      variant="outlined"
                      type="text"
                      disabled
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="note"
                  render={({
                    field: {
                      onChange,
                      onBlur,
                      value,
                      ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-ad-note"
                      label="Advert description"
                      variant="outlined"
                      type="text"
                      multiline
                      disabled
                      minRows={3}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Box>
              <TextInputContainer>
                <Typography sx={{ fontWeight: 'bold' }}>Details For Room Advert</Typography>
                <Divider />
                <Grid sx={{ mt: 1 }} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="rent"
                      render={({
                        field: {
                          onChange,
                          onBlur,
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-ad-rent"
                          label="rent per month"
                          variant="outlined"
                          type="number"
                          disabled
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ''}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">&#163;</InputAdornment>,
                          }}
                          ref={ref}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="deposit"
                      render={({
                        field: {
                          onChange,
                          onBlur,
                          value,
                          ref,
                        },
                      }) => (
                        <TextInput
                          id="prop-ad-deposit"
                          disabled
                          label="Deposit amount"
                          variant="outlined"
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">&#163;</InputAdornment>,
                          }}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ''}
                          ref={ref}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="availableBy"
                      render={({
                        field: {
                          onChange,
                          value,
                          ref,
                        },
                      }) => (
                        <MobileDatePicker
                          label="Available from"
                          disabled
                          inputFormat="MM/dd/yyyy"
                          value={value}
                          onChange={onChange}
                          ref={ref}
                          renderInput={(params) => (
                            <TextInput
                              fullWidth
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <Controller
                        control={control}
                        name="rentFrequencyId"
                        render={({
                          field: {
                            onChange,
                            onBlur,
                            value,
                            ref,
                          },
                        }) => (
                          <TextInput
                            value={value || ''}
                            onChange={onChange}
                            size="small"
                            disabled
                            label="Minimum tenancy"
                            select
                            ref={ref}
                            onBlur={onBlur}
                          >
                            {rentFrequencies.value?.map((frequency) => (
                              <MenuItem key={frequency.id} value={frequency.id}>{frequency.name}</MenuItem>
                            ))}
                          </TextInput>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      id="prop-ad-holdingDeposit"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">&#163;</InputAdornment>,
                      }}
                      value={Math.floor((watchRent * 12) / 52)}
                      disabled
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
                <FormControl component="fieldset">
                  <FormLabel component="legend" sx={{ mt: 2 }}>Inclusive in rent</FormLabel>
                  <RadioGroup row aria-label="included-rent" name="includedRent">
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      {CheckBoxesRent.map(({ name, id, label }) => (
                        <Grid item xs={6} sm={4} md={3}>
                          <FormControlLabel
                            key={id}
                            value={name}
                            control={(
                              <Controller
                                control={control}
                                name={name}
                                render={({
                                  field: {
                                    onChange,
                                    value,
                                    ref,
                                  },
                                }) => (
                                  <Checkbox
                                    ref={ref}
                                    disabled
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                  />
                                )}
                              />
                            )}
                            label={label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend" sx={{ mt: 2 }}>Room Amenities</FormLabel>
                  <RadioGroup row aria-label="room-amenities" name="roomAmenities">
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      {CheckBoxesAmenities.map(({ name, id, label }) => (
                        <Grid item xs={6} sm={4} md={3}>
                          <FormControlLabel
                            key={id}
                            value={name}
                            control={(
                              <Controller
                                control={control}
                                name={name}
                                render={({
                                  field: {
                                    onChange,
                                    value,
                                    ref,
                                  },
                                }) => (
                                  <Checkbox
                                    ref={ref}
                                    disabled
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                  />
                                )}
                              />
                            )}
                            label={label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </TextInputContainer>
              <Controller
                control={control}
                name="youtubeLink"
                defaultValue=""
                render={({
                  field: {
                    onChange,
                    value,
                    onBlur,
                    ref,
                  },
                }) => (
                  <TextInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    label="Youtube Link"
                    disabled
                    size="small"
                  />
                )}
              />
            </Box>
            {/**  <------- Advert Photos --------->  */}
            <Box>
              <SectionHead>
                <Typography sx={{ fontWeight: 'bold' }}>Advert Photos/Files</Typography>
                <Button onClick={() => navigate('/create-ad/room-images')}>
                  <EditIcon sx={{ mr: 1 }} />
                  {'  '}
                  Edit Section
                </Button>
              </SectionHead>
              <Grid container spacing={2}>
                {photos?.map((photo) => {
                  const { id, file } = photo;
                  const { url } = file;
                  const extension = url.split('.')
                    .pop();
                  const isPdf = extension === 'pdf';
                  return (
                    <Grid item xs={12} sm={4} md={3} key={id}>
                      <PhotoPaper isPdf={isPdf} url={url} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {/* <------- Screening questions section -----> */}
            <Box>
              <SectionHead>
                <Typography sx={{ fontWeight: 'bold' }}>Screening Questions</Typography>
                <Button onClick={() => navigate('/create-ad/screening-questions')}>
                  <EditIcon sx={{ mr: 1 }} />
                  {'  '}
                  Edit Section
                </Button>
              </SectionHead>
              <Grid container spacing={2}>
                {screeningQuestions.map((dfn) => (
                  <Grid xs={12} item>
                    <ScreeningContainer>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Typography
                          sx={{ fontWeight: '700' }}
                        >
                          {dfn.question}
                        </Typography>
                      </Box>
                      {dfn.type === 'value' ? (
                        <Box sx={{ width: '90%' }} display="flex" justifyContent="space-between">
                          <Typography>Suitable applicants will input number above</Typography>
                          <ScreeningInput
                            disabled
                            value={dfn.minimumValue || ''}
                            type="number"
                            size="small"
                          />
                        </Box>
                      )
                        : (
                          <FormControl component="fieldset">
                            <FormLabel component="legend" sx={{ mt: 2 }}>Desired answer</FormLabel>
                            <RadioGroup
                              row
                              aria-label="desired answer"
                              name="desiredAnswer"
                              value={dfn.desiredAnswer}
                            >
                              <FormControlLabel value control={<Radio disabled />} label="yes" />
                              <FormControlLabel value={false} control={<Radio disabled />} label="no" />
                            </RadioGroup>
                          </FormControl>
                        )}
                    </ScreeningContainer>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* <------- Viewing slots section -----> */}
            <Accordion defaultExpanded sx={{ mb: 6, mt: 6 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontWeight: 'bold', flex: 1 }}>Viewing Slots</Typography>
                <Button sx={{ mr: 4 }} onClick={() => navigate('/create-ad/viewing-slots')}>
                  <EditIcon sx={{ mr: 1 }} />
                  {'  '}
                  Edit Section
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                <AccordionViewingSlots viewingSlots={viewingSlots} />
              </AccordionDetails>
            </Accordion>
            <AccordionVirtualMeetingDetails virtualMeetingDetails={roomDetails?.virtualMeetingDetails || ''} />
            <ButtonContainer sx={{ justifyContent: 'flex-end' }}>
              {
                published
                  ? (
                    <Button size="large" variant="contained" onClick={handleUnpublishClick}>
                      Unpublish
                    </Button>
                  ) : (
                    <Button size="large" variant="contained" onClick={handlePublishClick}>
                      Publish
                    </Button>
                  )
              }

              <Button size="large" sx={{ mx: 1 }} variant="outlined" onClick={handleSaveExit}>
                Save & Exit
              </Button>
            </ButtonContainer>

            {
              roomDetails?.id && (
                <>
                  <Dialog open={publishDialogOpen} maxWidth="xl" onClose={handleClose}>
                    <DialogContent sx={{ mx: 4, my: 2 }}>
                      <ProductSelect advertId={roomDetails.id as string} />
                    </DialogContent>
                  </Dialog>
                </>
              )
            }

            <Dialog open={unpublishDialogOpen}>
              <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ReportIcon color="error" sx={{ fontSize: 60 }} />
                <Typography color="error" variant="h4" sx={{ fontWeight: 500 }}>Are you sure?</Typography>
              </DialogTitle>
              <DialogContent sx={{ width: '70%', mx: 'auto', textAlign: 'center' }}>
                <Typography>
                  If you
                  {' '}
                  <strong>unpublish</strong>
                  {' '}
                  your advert, you will have to purchase a new listing if you wish to re-list.
                </Typography>
                <Typography sx={{ my: 1 }}>
                  Don&apos;t unpublish unless you&apos;ve at least got a holding deposit!
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button onClick={handleClose} variant="contained" size="large">Cancel</Button>
                <Button onClick={handleUnpublish} variant="contained" color="error" size="large">Confirm</Button>
              </DialogActions>
            </Dialog>
          </FormContainer>
        </LocalizationProvider>
      )}
    </ApplicationWrapper>
  );
};

export default SummaryPage;
