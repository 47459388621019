import Chip from '@mui/material/Chip';
import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { styled } from '@mui/material/styles';

interface ChipIsMaxAttendeesProps {
  maxAttendees: number;
  useIcon: boolean;
}

export const ChipStyle = styled(Chip)`
  fontSize: x-small !important;
`;

const ChipIsMaxAttendees: React.FC<ChipIsMaxAttendeesProps> = ({
  maxAttendees,
  useIcon,
}) => (
  <ChipStyle
    label={`Max Attendees: ${maxAttendees === 0 ? 'Unlimited' : maxAttendees}`}
    color="primary"
    size="small"
    icon={useIcon ? <GroupIcon fontSize="small" /> : <></>}
  />
);

export default ChipIsMaxAttendees;
