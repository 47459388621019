/* eslint-disable no-nested-ternary */
import {
  Box, CircularProgress, Grid, Modal, Typography,
} from '@mui/material';
import React from 'react';
import { useRequestState } from '../../hooks/request';
import { fetchLeadDetails } from '../../sdk/leads';
import { ScreeningContainer } from '../CreateAd/styles';

interface ScreeningModalProps {
  open: boolean;
  id: string;
  handleClose(): void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '2px solid rgba(0,0,0,0.01)',
  overflowY: 'scroll !important',
  height: '80%',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
};

const ScreeningModal:React.FC<ScreeningModalProps> = ({
  id,
  open,
  handleClose,
}) => {
  const [questionAnswers, setQuestionAnswers] = React.useState<any[]>([]);
  const screenings = useRequestState(fetchLeadDetails);
  React.useEffect(() => {
    screenings.fetch(id);
  }, []);
  React.useEffect(() => {
    if (screenings.value?.screeningQuestions?.length > 0) {
      const merge: any = [];
      screenings.value?.screeningQuestions?.forEach((el: any) => {
        merge.push({
          ...el,
          ...(screenings.value?.screeningAnswers?.find((ans: any) => ans.screeningQuestionId === el.id) || {}),
        });
      });
      setQuestionAnswers(merge);
    }
  }, [screenings.value?.id]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="screening-modal"
      aria-describedby="screening-answers"
    >
      <Box sx={style}>
        {screenings.loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{
                my: 2,
              }}
            >
              {screenings.value?.viewer?.name
                ? `${screenings.value?.viewer?.name}'s Screening Answers`
                : 'Applicant\'s Screening Answers'}
            </Typography>
            <Grid container spacing={2}>
              {questionAnswers.map((question) => (
                <Grid sm={10} xs={12} item key={`question_${question?.id}`}>
                  <ScreeningContainer>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: '700' }}
                    >
                      {question?.question}
                    </Typography>
                    <Box>
                      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <i>Expected Answer:</i>
                        {' '}
                        <Typography sx={{ ml: 1 }} variant="h6">
                          {question?.type === 'value'
                            ? question?.minimumValue : (question?.desiredAnswer ? 'yes' : 'no')}
                        </Typography>
                      </Typography>
                      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <i>Provided Answer:</i>
                        {' '}
                        <Typography sx={{ ml: 1 }} variant="h6">
                          {question?.type === 'value'
                            ? question?.valueAnswer : (question?.yesNoAnswer ? 'yes' : 'no')}
                        </Typography>
                      </Typography>
                    </Box>
                    {/* {question?.type === 'value' ? (
                      <Box sx={{ width: '90%', mt: 2 }} display="flex" alignItems="center">
                        <Typography sx={{ mr: 2 }}>applicant&apos;s Answer</Typography>
                        <ScreeningInput
                          value={question?.valueAnswer || 'no answer'}
                          size="small"
                          disabled
                        />
                      </Box>
                    )
                      : (
                        <FormControl component="fieldset">
                          <FormLabel component="legend" sx={{ mt: 2 }}>applicant&apos;s Answer</FormLabel>
                          <RadioGroup
                            row
                            aria-label="desired answer"
                            name="desiredAnswer"
                            value={question?.yesNoAnswer}
                          >
                            <FormControlLabel disabled value control={<Radio />} label="yes" />
                            <FormControlLabel disabled value={false} control={<Radio />} label="no" />
                          </RadioGroup>
                        </FormControl>
                      )} */}
                  </ScreeningContainer>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ScreeningModal;
