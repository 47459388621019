import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ResetPasswordForm from '../components/Auth/ResetPassword';
import Navbar from '../components/layout/Navbar';
import usePageTitle from '../hooks/pageTitle';
import { useRequestState } from '../hooks/request';
import useLazyEffect from '../hooks/useEffect';
import { resetPassword } from '../sdk/authentication';
import { parseApiError } from '../utils/errors';

const ResetPassword: React.FC = () => {
  const {
    error, loading, value, fetch,
  } = useRequestState(resetPassword);
  usePageTitle('forgot password');
  const navigate = useNavigate();
  const handleReset = (
    userEmail: { username: string },
  ): void => {
    fetch(userEmail);
  };

  useLazyEffect(() => {
    if (error) {
      parseApiError(error,
        'something went while resetting your password, please try again');
    } else {
      toast.success('Password reset successfully, please log in.');
      navigate('/email-instructions');
    }
  }, [value, error]);
  return (
    <>
      <Navbar />
      <ResetPasswordForm loading={loading} handleReset={handleReset} />
    </>
  );
};

export default ResetPassword;
