import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  TextInput,
  FormContainer,
  LinkView,
  ButtonView as Button,
} from './styles';
import { loginSchema } from './validations';
import { IUserLoginParams } from '../../sdk/authentication';

interface ILoginProps {
  handleLogin(userCredentials: IUserLoginParams): void;
  loading?: boolean;
}

const LoginForm: React.FC<ILoginProps> = ({ handleLogin, loading }): JSX.Element => {
  const {
    control, handleSubmit, formState: { errors }, setValue,
  } = useForm<IUserLoginParams>({
    resolver: yupResolver(loginSchema),
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword(!showPassword);
  const handleMouseDownPassword = (): void => setShowPassword(!showPassword);

  return (
    <FormContainer
      component="form"
      onSubmit={handleSubmit((data: IUserLoginParams) => handleLogin(data))}
    >
      <Box>
        <Controller
          control={control}
          name="username"
          render={({
            field: {
              onBlur, value, ref,
            },
          }) => (
            <TextInput
              id="email-login"
              label="email"
              variant="outlined"
              onChange={(e) => {
                const val = e.target.value || '';
                setValue('username', val.toLowerCase(), {
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type="email"
              fullWidth
              size="small"
              helperText={errors.username?.message || ''}
              error={!!errors.username?.message}
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="password"
          render={({
            field: {
              onChange, onBlur, value, ref,
            },
          }) => (
            <TextInput
              id="password-login"
              label="password"
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              ref={ref}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              helperText={errors.password?.message || ''}
              error={!!errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          )}
        />
      </Box>
      <LinkView type="button" to="/reset-password">Forgot your password? Reset password</LinkView>
      <Button loading={loading} type="submit" variant="contained">Log in</Button>
      <LinkView replace type="button" to="/register">Don&apos;t have an account? Register here</LinkView>
    </FormContainer>
  );
};

LoginForm.defaultProps = {
  loading: false,
};

export default LoginForm;
