import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from '../views';
import Login from '../views/Login';
import Register from '../views/Signup';
import ResetPassword from '../views/ResetPassword';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoutes';
import { useAppSelector } from '../store/hooks';
import Logout from '../views/Logout';
import CreateAd from '../views/CreateAd';
import LeadManager from '../views/lead-manager';
import Rebooking from '../views/Rebooking';
// import Accounts from '../views/Accounts';
import UpcomingViewing from '../views/UpcomingViewing';
import Checkout from '../views/Checkout';
import EmailInstructions from '../views/EmailInstructions';

const Routing = (): JSX.Element => {
  const { authState } = useAppSelector((state) => state);

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: authState.isLoggedIn,
    authenticationPath: '/login',
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/dashboard"
        element={(
          <ProtectedRoute {...defaultProtectedRouteProps}>
            <Home />
          </ProtectedRoute>
        )}
      />
      <Route
        path="/create-ad/*"
        element={<ProtectedRoute {...defaultProtectedRouteProps}><CreateAd /></ProtectedRoute>}
      />
      <Route
        path="/rebooking/:advertId/lead/:leadId/*"
        element={<ProtectedRoute {...defaultProtectedRouteProps}><Rebooking /></ProtectedRoute>}
      />
      <Route
        path="/lead-manager"
        element={<ProtectedRoute {...defaultProtectedRouteProps}><LeadManager /></ProtectedRoute>}
      />
      <Route
        path="/upcoming-view"
        element={<ProtectedRoute {...defaultProtectedRouteProps}><UpcomingViewing /></ProtectedRoute>}
      />
      <Route path="/checkout" element={<ProtectedRoute {...defaultProtectedRouteProps}><Checkout /></ProtectedRoute>} />
      <Route path="/logout" element={<ProtectedRoute {...defaultProtectedRouteProps}><Logout /></ProtectedRoute>} />
      <Route path="/" element={<Navigate replace to="/dashboard" />} />
      <Route path="/email-instructions" element={<EmailInstructions />} />
    </Routes>
  );
};

export default Routing;
