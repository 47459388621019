import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILead } from '../../sdk/leads';

export interface ILeadState {
  leads: ILead[];
}

const initialState: ILeadState = {
  leads: [],
};

export const leadListSlice = createSlice({
  name: 'lead-list',
  initialState,
  reducers: {
    leadListReducer: (
      state,
      action: PayloadAction<ILead[]>,
    ) => {
      state.leads = action.payload;
    },
    removeLeadReducer: (
      state,
      action: PayloadAction<string>,
    ) => {
      const leadIndex = state.leads.findIndex(((obj) => obj.id === action.payload));
      state.leads.splice(leadIndex, 1);
    },
  },
});

export const {
  leadListReducer,
  removeLeadReducer,
} = leadListSlice.actions;

export default leadListSlice.reducer;
