import React from 'react';
import { Document, Page } from 'react-pdf';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import Box from '@mui/material/Box';
import { PhotoContainer } from '../CreateAd/styles';

interface PhotoPaperProps {
  children?: JSX.Element;
  url: string;
  isPdf?: boolean;
}
interface PDFDocument {
  numPages: number
}

const PhotoPaper: React.FC<PhotoPaperProps> = ({
  url,
  isPdf,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const [pages, setPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocument): void => {
    setPages(numPages);
  };
  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleNext = (): void => {
    setPageNumber(pageNumber + 1);
  };

  const handleBack = (): void => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        {
          isPdf && open && (
            <Box
              sx={{
                overflow: 'auto',
              }}
            >
              <Document
                file={{
                  url,
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
          )
        }
        {
          !isPdf && open && <img src={url} alt="Room" />
        }
        <DialogActions>
          {
            isPdf
            && (
            <ButtonGroup variant="outlined" aria-label="pdf pages">
              <Button disabled={pageNumber === 1} onClick={handleBack}>{'<'}</Button>
              <Button disabled>
                Page
                {' '}
                {pageNumber}
                {' '}
                of
                {' '}
                {pages}
              </Button>
              <Button disabled={pageNumber === pages} onClick={handleNext}>{'>'}</Button>
            </ButtonGroup>
            )
          }
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <PhotoContainer
        sx={{
          backgroundImage: `url(${isPdf ? `${process.env.PUBLIC_URL}/pdf2.png` : url})`,
          cursor: 'zoom-in',
        }}
        onClick={handleClickOpen}
      >
        {children}
      </PhotoContainer>
    </>
  );
};

export default PhotoPaper;
