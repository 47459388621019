import * as React from 'react';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { updateAdvertDetails } from '../../../store/actions/PropertyActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ILead } from '../../../sdk/leads';
import DateTimeUtils from '../../../utils/DateTimeUtils';

interface RebookViewingSlotsProps {
  open: boolean,
  setOpen: any,
  advertId: string,
  leadId: string,
  handleRebookLead: any,
  leadsList: ILead[],
}

const RebookViewingSlots: React.FC<RebookViewingSlotsProps> = ({
  open,
  setOpen,
  advertId,
  leadId,
  handleRebookLead,
  leadsList,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { viewingSlots } = useAppSelector((state) => state.createpropertyState);

  const getDetails = async (id: string): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(updateAdvertDetails(id, true));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('something went wrong while fetching the property details, please try again later');
    }
  };

  const availableSlots = (): boolean => viewingSlots
    .map((m) => {
      const leads = leadsList.filter((f) => f.viewingSlot?.id === m.id);
      const maxAttendees = m.maxAttendees || 0;
      return ({
        ...m,
        leads,
        availableSlots: maxAttendees === 0 ? true : (maxAttendees - leads.length) > 0,
      });
    })
    .filter((f) => !DateTimeUtils.isAfterDate(DateTimeUtils.now(), f.dateAndTime) && f.availableSlots).length > 0;

  React.useEffect(() => {
    getDetails(advertId);
  }, []);

  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        Add aditional viewing slots
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {
          loading
          && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress size={20} />
          </Box>
          )
        }
        {
          !loading && (
            <Typography variant="body1">
              You can add additional viewing slots if you like?
            </Typography>
          )
        }
        {
          !loading && !availableSlots() && (
            <>
              <Typography variant="body1">
                ⚠️ No viewing slots or all viewing slots are full
                <Button
                  variant="text"
                  size="small"
                  onClick={() => navigate(`/rebooking/${advertId}/lead/${leadId}/viewing-slots`)}
                >
                  create aditional slots
                </Button>
              </Typography>
            </>
          )
        }
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={() => navigate(`/rebooking/${advertId}/lead/${leadId}/viewing-slots`)}
          variant="contained"
          color="primary"
          disabled={loading || viewingSlots.length === 0}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            if (viewingSlots.length === 0) {
              setOpen(false);
            } else {
              handleRebookLead(advertId, leadId);
              setOpen(false);
            }
          }}
          variant="contained"
          color="error"
          disabled={loading}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RebookViewingSlots;
