import { styled } from '@mui/material/styles';
import { alpha } from '@mui/system';
import {
  Box,
  Typography,
  Button,
  InputBase,
  Paper,
} from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors[100],
  width: '100%',
  minHeight: '100vh',
  padding: 0,
  margin: 0,
  // position: 'relative',
}));
export const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  padding: theme.spacing(2, 4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2),
  },
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

export const CreateButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontWeight: 500,
  color: 'white',
  padding: '5px 15px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 2),
  },
}));

export const SortContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const SortButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '.7rem',
  textTransform: 'none',
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'white',
  },
}));

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  '&:hover &:focus': {
    backgroundColor: alpha(theme.palette.common.white, 0.7),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SearchInput = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const AdContainer = styled(Paper)(({ theme }) => ({
  borderRadius: 5,
  height: 'auto',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: theme.colors.white,
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  wordWrap: 'break-word',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const Adtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 'bold',
  flexGrow: 1,
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));

export const ButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  // alignSelf: 'flex-start',
  marginTop: '8px',
}));

export const ButtonDown = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const ImgContainer = styled(Box)`
   width: 120px;
   height: 120px;
   margin-top: 12px;
`;

export const AdImg = styled('img')(() => ({
  maxHeight: '120px',
  diplay: 'block',
  width: '100%',
}));
