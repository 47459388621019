import React, { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  TextInput,
  FormContainer,
} from '../styles';
import { firstStepSchema } from '../validations';
import BottomBtn from '../common/ButtonContainer';
import { useRequestState } from '../../../hooks/request';
import { IPropertyParams, listPropertyTypes } from '../../../sdk/propertyApi';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import { steps } from '../../../views/CreateAd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createPropertyAction, updatePropertyAction } from '../../../store/actions/PropertyActions';
import useScrollToError from '../../../hooks/useScrollToError';

interface PropertyAddressProps {
  handleNext(): void;
  handleBack(): void;
}

const PropertyAddress: React.FC<PropertyAddressProps> = ({
  handleNext,
  handleBack,
}) => {
  const dispatch = useAppDispatch();
  const propertyData = useAppSelector((state) => state.createpropertyState);
  const [loading, setLoading] = useState(false);
  const {
    control, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(firstStepSchema),
    defaultValues: useMemo(() => {
      if (propertyData.data?.id) {
        const {
          buildingNameOrNumber,
          flatNumber,
          streetName,
          town,
          postalCode,
          typeId,
        } = propertyData.data;
        return {
          buildingNameOrNumber,
          flatNumber,
          streetName,
          town,
          postalCode,
          typeId,
        };
      }
      return {};
    }, [propertyData.data?.id]),
  });
  useScrollToError(errors as any);
  const createProperties = async (formData: IPropertyParams): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(createPropertyAction(formData));
      setLoading(false);
      handleNext();
    } catch (error) {
      setLoading(false);
      toast.error('something went wrong while creating properties');
    }
  };
  const handlePropertyAddress = async (formData: any): Promise<void> => {
    setLoading(true);
    if (!propertyData.data?.id) {
      await createProperties(formData);
    } else {
      await dispatch(updatePropertyAction(formData, propertyData.data?.id as string));
      handleNext();
    }
    setLoading(false);
  };
  const propertyTypes = useRequestState(listPropertyTypes);

  React.useEffect(() => {
    propertyTypes.fetch();
  }, []);
  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={1} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      <>
        {propertyTypes.loading
          ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          )
          : (
            <FormContainer
              component="form"
              onSubmit={handleSubmit((formData: any) => handlePropertyAddress(formData))}
            >
              {propertyData.edit && (
              <Alert severity="info" sx={{ mb: 5 }}>
                Editing property details will affect all the adverts related to this property.
              </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="buildingNameOrNumber"
                    render={({
                      field: {
                        onChange, onBlur, value, ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-buildingNameOrNumber"
                        label="building name or number"
                        name="buildingNameOrNumber"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                        helperText={errors.buildingNameOrNumber?.message || ''}
                        error={!!errors.buildingNameOrNumber?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="flatNumber"
                    render={({
                      field: {
                        onChange, onBlur, value, ref,
                      },
                    }) => (
                      <TextInput
                        name="flatNumber"
                        id="prop-flatNumber"
                        label="flat number"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box>
                <Controller
                  control={control}
                  name="streetName"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <TextInput
                      id="prop-streetName"
                      name="streetName"
                      label="street name"
                      variant="outlined"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      ref={ref}
                      fullWidth
                      size="small"
                      helperText={errors.streetName?.message || ''}
                      error={!!errors.streetName?.message}
                    />
                  )}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="town"
                    render={({
                      field: {
                        onChange, onBlur, value, ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-city"
                        label="city"
                        name="town"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                        helperText={errors.town?.message || ''}
                        error={!!errors.town?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="postalCode"
                    render={({
                      field: {
                        onChange, onBlur, value, ref,
                      },
                    }) => (
                      <TextInput
                        id="prop-postCode"
                        label="postal code"
                        variant="outlined"
                        name="postalCode"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value || ''}
                        ref={ref}
                        fullWidth
                        size="small"
                        helperText={errors.postalCode?.message || ''}
                        error={!!errors.postalCode?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box>
                <Controller
                  control={control}
                  name="typeId"
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => (
                    <FormControl sx={{ width: '100%', margin: '12px 0' }}>
                      <TextInput
                        name="typeId"
                        value={value || ''}
                        defaultValue=""
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.typeId?.message}
                        select
                        label="Property type"
                      >
                        <MenuItem value="" disabled>Select property type</MenuItem>
                        {propertyTypes.value?.map((type) => (
                          <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                        ))}
                      </TextInput>
                    </FormControl>
                  )}
                />
              </Box>
              <BottomBtn
                typeSubmit="submit"
                disabledBack={propertyData.isEditAdvert}
                loading={loading}
                handleNext={handleSubmit((formData: any) => handlePropertyAddress(formData))}
                handleBack={handleBack}
              />
            </FormContainer>
          )}
      </>
    </ApplicationWrapper>
  );
};

export default PropertyAddress;
