import { AppDispatch } from '..';
import { fetchAdverts, unpublishAdvert } from '../../sdk/adverts';
import { IRoomDetails } from '../reducers/createProperty';
import { advertListReducer, togglePublish } from '../reducers/listAdvert';

export const getAdvertListAction = () => async (
  dispatch: AppDispatch,
): Promise<IRoomDetails[]> => {
  try {
    const res = await fetchAdverts();
    dispatch(advertListReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    if (error.response?.status === 401) {
      return [];
    }

    throw error?.response;
  }
};

export const unpublishAction = (id: string) => async (
  dispatch: AppDispatch,
): Promise<IRoomDetails> => {
  try {
    const res = await unpublishAdvert(id);
    dispatch(togglePublish(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};
