import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { toggleWelcomeModal } from '../../../store/reducers/listAdvert';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 200,
    sm: 300,
    md: 500,
  },
  bgcolor: 'background.paper',
  border: '1px solid rgba(0,0,0,0.1)',
  boxShadow: 10,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
};

export interface WelcomeModalProps {
  open: boolean;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  open,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleCloseModal = (): void => {
    dispatch(toggleWelcomeModal(false));
  };
  const handleCreateAd = (): void => {
    dispatch(toggleWelcomeModal(false));
    navigate('/create-ad');
  };
  return (
    <div>
      <Modal
        aria-labelledby="verify-email-message"
        aria-describedby="verify-email-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style as any}>
            <Typography id="verify-email-message" variant="h6" component="h2">
              Welcome To Nevoliving
            </Typography>
            <Typography id="verify-email-description" sx={{ mt: 2 }}>
              Please click on the &quot;CREATE AD&quot;
              {' '}
              button below to create your first Advert.
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Button
                sx={{ mt: 3, width: 'fit-content', alignSelf: 'center' }}
                size="large"
                variant="contained"
                onClick={handleCreateAd}
              >
                Create Ad
              </Button>
              <Button
                sx={{ mt: 3, width: 'fit-content', alignSelf: 'center' }}
                size="large"
                variant="contained"
                color="error"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default WelcomeModal;
