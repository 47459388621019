import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormLabel,
  InputBase,
  InputLabel,
  Paper,
  Step,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const TextInput = styled(TextField)(() => `
  margin: 12px 0;
`);

export const TextInputBase = styled(InputBase)(() => `
  margin: 12px 0;
`);

export const FormContainer = styled(Box)(() => `
  margin: 30px 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 70vh;
  padding-bottom: 60px;
`);

export const Label = styled(InputLabel)(() => `
  margin-top: 12px;
`);

export const LinkView = styled(Link)`
  color: #868e96;
  margin: 12px 0;
  text-decoration: none;
  &:hover {
    color: #474ed1;
  }
`;

export const ButtonView = styled(LoadingButton)`
  width: 40%;
  height: 40px;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5px;
`;

export const SideBarContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
`;

export const StepNumber = styled(Typography)`
  color: rgba(255,255,255,0.5);
  margin-bottom: 5px;
`;

export const StepperTitle = styled(Typography)`
  margin: 10px 0;
  color: #fff;
  align-self: center;
`;

export const StepView = styled(Step)`
  & span.MuiStepLabel-label {
    color: #fff;
    font-weight: bold;
  }
  & svg {
    color: #fff !important;
    & .MuiStepIcon-text {
      fill: #000;
    };
  };
  & svg.Mui-active {
      fill: #00e874;
    & .MuiStepIcon-text {
      fill: #00e874 !important;
    };
  }
`;

export const CheckBoxContainer = styled(FormControl)`
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
  width: 100%;
  background-color: #fafafe;
`;

export const CheckBoxLabel = styled(FormLabel)`
  display: flex;
  justify-content: space-between;
`;

export const AddCheckBoxContainer = styled(Box)`
  width: 100%;
  background-color: #fafafe;
  border: 1px dotted rgba(0,0,0,0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 180px;
`;

export const ChipContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TextInputContainer = styled(Box)`
  background-color: #fafafe;
  padding: 10px;
  border-radius: 5px;
`;

export const DocumentContainer = styled(Paper)`
  margin-top: 15px;
  min-width: 200px;
  width: 40%;
  min-height: 12rem;
  background-color: #fafafe;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PDFContainer = styled(Paper)`
  margin-top: 15px;
  min-width: 100%;
  height: 12rem;
  position: relative;
  display: flex;
  background-size: auto 100%;
  background-repeat: no-repeat;
`;

export const PhotoContainer = styled(Paper)`
  margin-top: 15px;
  min-width: 100%;
  height: 12rem;
  position: relative;
  display: flex;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

export const KitchenField = styled(TextField)`
  max-width: 70px;
  margin: 1rem;
`;

export const ScreeningContainer = styled(Box)(({ theme }) => ({
  border: `solid 1px ${theme.colors.gains}`,
  borderRadius: '5px',
  minHeight: '70px',
  backgroundColor: theme.colors[100],
  padding: 8,
}));

export const ScreeningInput = styled(TextField)`
  width: 120px;
`;

export const CertDocumentContainer = styled(FormControl)(({ theme }) => ({
  border: `dotted 1px ${theme.colors.gains}`,
  borderRadius: '5px',
  minWidth: '200px',
  width: '30%',
  minHeight: '12rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#fafafe',
  position: 'relative',
  padding: 10,
  margin: 20,
  '&:active, &:focus, &:hover': {
    border: `solid 1px ${theme.colors.gains}`,
  },
}));

export const SectionHead = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(15, 21, 133, 0.3);
`;
