import { client } from './client';

export interface IUserRegisterParams {
  username: string;
  password: string;
  passwordConfirmation: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface IUserLoginParams {
  username: string;
  password: string;
}

export interface IUserLoginResponse {
  sessionToken: string;
}

export function registerUser(userDetails: IUserRegisterParams): Promise<{message: string}> {
  return client
    .post('/sign-up', { ...userDetails })
    .then(() => ({ message: 'success' }));
}

export function loginUser(userDetails: IUserLoginParams): Promise<IUserLoginResponse> {
  return client
    .post<IUserLoginResponse>('/log-in', { ...userDetails })
    .then((response) => response.data);
}

export function resetPassword(userDetails: { username: string }): Promise<null> {
  return client
    .post('/reset-password', { ...userDetails })
    .then((response) => response.data);
}

export function logOut(): Promise<null> {
  return client
    .post('/log-out')
    .then((response) => response.data);
}
