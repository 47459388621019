import {
  Button, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField,
} from '@mui/material';
import React, { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import Fuse from 'fuse.js';
import { ILead } from '../../sdk/leads';
import DateTimeUtils from '../../utils/DateTimeUtils';

const filterOptions = [
  { label: 'None', value: '' },
  { label: 'Created', value: 'created' },
  { label: 'Failed', value: 'failed' },
  { label: 'Passed', value: 'succeeded' },
  { label: 'Booked', value: 'booked' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Active', value: 'active' },
  { label: 'Expired', value: 'expired' },
];

const sortrOptions = [
  { label: 'None', value: '' },
  { label: 'Ad Title', value: 'adTitle' },
  { label: 'Postal Code', value: 'postalCode' },
  { label: 'Street Name', value: 'streetName' },
  { label: 'Town', value: 'town' },
  { label: 'Viewer Name', value: 'viewerName' },
];

interface FilterSortContainerProps {
  data: ILead[];
  initialData: ILead[];
  handleData(data: ILead[]): void;
}

const FilterSortContainer: React.FC<FilterSortContainerProps> = ({ data, initialData, handleData }) => {
  const [openFilter, setOpenFilter] = useState<null | HTMLElement>(null);
  const [openSort, setOpenSort] = useState<null | HTMLElement>(null);
  const open = Boolean(openFilter);
  const openSortMenu = Boolean(openSort);
  const [filter, setFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const options = {
    threshold: 0.3,
    keys: ['status', 'advert.adTitle', 'viewer.name'],
  };
  const fuse = new Fuse(initialData, options);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (filter) setFilter('');
    if (event.target.value) {
      const results = fuse.search(event.target.value || '');
      const leadResults = results.map((lead) => lead.item);
      handleData(leadResults);
    } else {
      handleData(initialData);
    }
  };
  const handleFilter = (filterStatus: string): void => {
    setFilter(filterStatus);
    switch (filterStatus) {
      case 'created':
      case 'failed':
      case 'succeeded':
      case 'booked':
      case 'confirmed':
      case 'cancelled': {
        const filteredData = initialData.filter((lead) => lead.status === filterStatus);
        handleData(filteredData);
        break;
      }
      case 'expired':
      case 'active': {
        const filteredData = initialData.filter((lead) => {
          const { viewingSlot } = lead;
          const { dateAndTime = '' } = viewingSlot || {};
          const expired = DateTimeUtils.isAfterDate(DateTimeUtils.now(), dateAndTime);
          return filterStatus === 'expired' ? expired : !expired;
        });
        handleData(filteredData);
        break;
      }
      default:
        handleData(initialData);
        break;
    }
    setOpenFilter(null);
  };
  const handleSort = (sortStatus: string): void => {
    setSortBy(sortStatus);
    switch (sortStatus) {
      case 'postalCode':
      case 'town':
      case 'streetName': {
        const sortedData = [...data].sort((a, b) => {
          const aProperty = a.property[sortStatus].toLowerCase();
          const bProperty = b.property[sortStatus].toLowerCase();
          if (aProperty > bProperty) return 1;
          if (aProperty < bProperty) return -1;
          return 0;
        });
        handleData(sortedData);
        break;
      }
      case 'adTitle': {
        const sortedData = [...data].sort((a, b) => {
          const aAdvert = a.advert.adTitle.toLowerCase();
          const bAdvert = b.advert.adTitle.toLowerCase();
          if (aAdvert > bAdvert) return 1;
          if (aAdvert < bAdvert) return -1;
          return 0;
        });
        handleData(sortedData);
        break;
      }
      case 'viewerName': {
        const sortedData = [...data].sort((a, b) => {
          const aViewer = a.viewer.name.toLowerCase();
          const bViewer = b.viewer.name.toLowerCase();
          if (aViewer > bViewer) return 1;
          if (aViewer < bViewer) return -1;
          return 0;
        });
        handleData(sortedData);
        break;
      }
      default:
        handleData(data);
        break;
    }
    setOpenSort(null);
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        my: 1,
      }}
    >
      <Grid item md={6} xs={10}>
        <TextField
          fullWidth
          label="Search"
          placeholder="Search by advert, status, viewer..."
          variant="standard"
          onChange={handleSearch}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton><CloseIcon /></IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={3} xs={10} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={(e) => setOpenFilter(e.currentTarget)}
          sx={{ mx: 1, p: 1 }}
        >
          <FilterAltIcon />
          Filter by
          {' '}
          {filter}
          {' '}
          status
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={openFilter}
          open={open}
          onClose={() => setOpenFilter(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {
            filterOptions.map((option) => (
              <MenuItem key={option.label} onClick={() => handleFilter(option.value)}>
                {option.label}
                {' '}
              </MenuItem>
            ))
          }
        </Menu>
      </Grid>
      <Grid item md={3} xs={10} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          size="small"
          fullWidth
          onClick={(e) => setOpenSort(e.currentTarget)}
          sx={{ mx: 1, p: 1 }}
        >
          <SortIcon />
          Sort by
          {' '}
          {sortBy}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={openSort}
          open={openSortMenu}
          onClose={() => setOpenSort(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {
            sortrOptions.map((option) => (
              <MenuItem key={option.label} onClick={() => handleSort(option.value)}>
                {option.label}
                {' '}
              </MenuItem>
            ))
          }
        </Menu>
      </Grid>
    </Grid>
  );
};

export default FilterSortContainer;
