import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';

const StyledList = styled('ul')(`
  margin-left: 2em;
`);

const StyledListItem = styled('li')(`
  margin-bottom: 0.5em;
`);

const LinkNevo = (): JSX.Element => (
  <Link
    href="https://www.nevoliving.com/post/pre-screening-your-applicants"
    target="_blank"
    sx={{ ml: 1 }}
  >
    Pre-screening your applicants!
  </Link>
);

const Instructions: React.FC = () => (
  <Box>
    <Typography variant="body1" component="div" gutterBottom sx={{ mb: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          This is the section you define your screening criteria, specific to your advert 😊
        </Typography>

        <StyledList>
          <StyledListItem>Applicants will see your questions, exactly as you&apos;ve typed them.</StyledListItem>
          <StyledListItem>Applicants will not know what your desired answer is.</StyledListItem>
          <StyledListItem>
            If an applicant doesn&apos;t respond with the desired answer, they will be screened out.
          </StyledListItem>
          <StyledListItem>All the answers will be recorded (pass or fail) so you can review them later.</StyledListItem>
        </StyledList>
      </Box>

      <Box sx={{ mb: 2 }}>
        <p>Example: If you do not accept couples; type:</p>
        <p>
          <strong>&ldquo;Are you OK with single occupancy&rdquo;</strong>
          &nbsp;and set desired answer to&nbsp;
          <strong>&ldquo;YES&rdquo;</strong>
        </p>
      </Box>

      <Box sx={{ mb: 2 }}>
        <p>Example: if you only take applicants that earn £25000 and above; type:</p>
        <p>
          <strong>&ldquo;Please input your annual salary&rdquo;</strong>
          &nbsp;and set desired answer to&nbsp;
          <strong>&ldquo;25000&rdquo;</strong>
        </p>
      </Box>

      <Box>
        If you&apos;d like some help creating questions, please read here:
        <LinkNevo />
      </Box>
    </Typography>
  </Box>
);

export default Instructions;
