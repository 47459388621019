import * as yup from 'yup';

const phoneRegExp = /^(?:0|\+?44)(?:\d\s?){9,12}$/;

export const registerSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().email('Email is invalid').required('Email is required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  password: yup.string().min(8).required('Password is required'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
}).required();

export const loginSchema = yup.object({
  username: yup.string().email('Email is invalid').required('Email is required'),
  password: yup.string().min(8).required('Password is required'),
}).required();

export const resetSchema = yup.object({
  username: yup.string().email('Email is invalid').required('Email is required'),
}).required();

export const changeEmailSchema = yup.object({
  email: yup.string().email('Email is invalid').required('Email is required'),
  confirmEmail: yup.string().oneOf([yup.ref('email'), null], 'Email must match').required('Email must match'),
  password: yup.string().min(8).required('Password is required'),
}).required();

export const changePasswordSchema = yup.object({
  currentPassword: yup.string().min(8).required('Current password is required'),
  newPassword: yup.string().min(8).required('New password is required'),
  confirmNewPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'New password should match')
    .required('Confirm password is required'),
}).required();

export const changeMobileNumberSchema = yup.object({
  currentNumber: yup.string().matches(phoneRegExp, 'Current phone number is not valid').required(),
  newNumber: yup.string().matches(phoneRegExp, 'New phone number is not valid').required(),
  confirmNewNumber: yup
    .string()
    .oneOf([yup.ref('newNumber'), null], 'Numbers don\'t match')
    .required('Confirm new number'),
}).required();
