import { AppDispatch } from '..';
import {
  addAdvertPhoto,
  createAdvert,
  fetchAdvertDetails,
  fetchAdvertPhoto,
  removePhoto,
  unpublishAdvert,
  updateAdvert,
  updateThumbNail,
} from '../../sdk/adverts';
import {
  addAmenitiesProp,
  addBathroom,
  addKitchen,
  createProperty,
  createScreeningQuestion,
  editBathroom,
  editKitchen,
  internetDetails,
  IProperty,
  IPropertyParams,
  listProperty,
  removeAmenitiesProp,
  removeBathroom,
  removeKitchen,
  updateProperty,
  addViewingSlot,
  removeViewingSlot,
  addPropertyDocument,
  removeScreeningQuestions,
  editScreeningQuestions,
  fetchPropertyDetails,
  fetchViewingSlot,
  screeningQuestionsList,
  listKitchens,
  listBathrooms,
  listPropAmenities,
  removePropertyDocument,
} from '../../sdk/propertyApi';
import removeEmptyFields from '../../utils/removeEmptyFields';
import {
  addBathroomsReducer,
  addCertsReducer,
  addKitchensReducer,
  addPhotoReducer,
  addPropertyAmenities,
  addViewingSlotReducer,
  createAdvertReducer,
  createPropertyReducer,
  createQuestionsReducer,
  editBathroomsReducer,
  editKitchensReducer,
  editQuestionsReducer,
  editStateReducer,
  IAmenities,
  IBathroom,
  IKitchen,
  internetConnctionReducer,
  InternetConnection,
  IRoomDetails,
  IScreeninQuestions,
  IViewingSlot,
  removeBathroomsReducer,
  removeCertsReducer,
  removeKitchensReducer,
  removePhotoReducer,
  removePropertyAmenities,
  removeQuestionsReducer,
  removeViewingSlotReducer,
  resetStateReducer,
  updatePropertyReducer,
  updateThumbnailReducer,
} from '../reducers/createProperty';
import { propertiesListReducer } from '../reducers/listProperties';

export const createPropertyAction = (
  data: IPropertyParams,
) => async (
  dispatch: AppDispatch,
): Promise<IProperty> => {
  try {
    const property = await createProperty(data);
    dispatch(createPropertyReducer({ ...property, typeId: data.typeId }));
    return property;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const updatePropertyAction = (
  data: IPropertyParams,
  id: string,
) => async (
  dispatch: AppDispatch,
): Promise<IProperty> => {
  try {
    removeEmptyFields(data);
    const res = await updateProperty(data, id);
    dispatch(updatePropertyReducer({
      ...data as any,
      ...(data.typeId && { typeId: data.typeId }),
      ...(data.tenantTypeId && { tenantTypeId: data.tenantTypeId }),
    }));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const listPropertyAction = () => async (
  dispatch: AppDispatch,
): Promise<IPropertyParams[]> => {
  try {
    const res = await listProperty();
    dispatch(propertiesListReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const addBathroomAction = (data: IBathroom) => async (
  dispatch: AppDispatch,
): Promise<IBathroom> => {
  try {
    const res = await addBathroom(data);
    dispatch(addBathroomsReducer({ id: res.id, ...data }));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const editBathroomAction = (data: IBathroom) => async (
  dispatch: AppDispatch,
): Promise<IBathroom> => {
  try {
    const res = await editBathroom(data);
    dispatch(editBathroomsReducer(data));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const removeBathroomAction = (propertyId: string, id: string) => async (
  dispatch: AppDispatch,
): Promise<void> => {
  try {
    const res = await removeBathroom(propertyId, id);
    dispatch(removeBathroomsReducer(id));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const addKitchenAction = (data: IKitchen) => async (
  dispatch: AppDispatch,
): Promise<IKitchen> => {
  try {
    const res = await addKitchen(data);
    dispatch(addKitchensReducer({ ...data, id: res.id }));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const editKitchenAction = (data: IKitchen) => async (
  dispatch: AppDispatch,
): Promise<IKitchen> => {
  try {
    const res = await editKitchen(data);
    dispatch(editKitchensReducer(data));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const removeKitchenAction = (propertyId: string, id: string) => async (
  dispatch: AppDispatch,
): Promise<void> => {
  try {
    const res = await removeKitchen(propertyId, id);
    dispatch(removeKitchensReducer(id));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const createAdvertAction = (data: IRoomDetails) => async (
  dispatch: AppDispatch,
): Promise<IRoomDetails> => {
  try {
    removeEmptyFields(data);
    const res = await createAdvert(data);
    dispatch(createAdvertReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const editAdvertAction = (data: IRoomDetails, id: string) => async (
  dispatch: AppDispatch,
): Promise<IRoomDetails> => {
  try {
    removeEmptyFields(data);
    const res = await updateAdvert(data, id);
    dispatch(createAdvertReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const thumbNailAction = (advertId: string, photoId: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await updateThumbNail(advertId, photoId);
    dispatch(updateThumbnailReducer(photoId));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const createQuestionAction = (data: IScreeninQuestions) => async (
  dispatch: AppDispatch,
): Promise<IScreeninQuestions> => {
  try {
    const res = await createScreeningQuestion(data);
    dispatch(createQuestionsReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const editQuestionAction = (id: string, data: IScreeninQuestions) => async (
  dispatch: AppDispatch,
): Promise<IScreeninQuestions> => {
  try {
    const res = await editScreeningQuestions(data, id);
    dispatch(editQuestionsReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const removeQuestionAction = (id: string) => async (
  dispatch: AppDispatch,
): Promise<null> => {
  try {
    const res = await removeScreeningQuestions(id);
    dispatch(removeQuestionsReducer(id));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const amenitiesAction = (amenity: IAmenities, propId: string, exists: boolean) => async (
  dispatch: AppDispatch,
): Promise<IScreeninQuestions> => {
  try {
    let res;
    if (exists) {
      res = await removeAmenitiesProp(amenity.id, propId);
      dispatch(removePropertyAmenities(amenity.id));
    } else {
      res = await addAmenitiesProp(amenity.id, propId);
      await dispatch(addPropertyAmenities(amenity));
    }
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const internetConnectionsAction = (data: InternetConnection, propId: string) => async (
  dispatch: AppDispatch,
): Promise<IProperty> => {
  try {
    const res = await internetDetails(data, propId);
    dispatch(internetConnctionReducer(data));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const addViewingSlotAction = (
  date: string,
  advertId: string,
  virtual: boolean,
  onsite: boolean,
  maxAttendees: number,
) => async (
  dispatch: AppDispatch,
): Promise<IViewingSlot> => {
  try {
    const res = await addViewingSlot(advertId, date, virtual, onsite, maxAttendees);
    await dispatch(addViewingSlotReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const removeViewingSlotAction = (viewSlotId: string, advertId: string) => async (
  dispatch: AppDispatch,
): Promise<null> => {
  try {
    await removeViewingSlot(advertId, viewSlotId);
    await dispatch(removeViewingSlotReducer(viewSlotId));
    return null;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const addDocumentsCertsAction = (propId: string, bodyFormData: any, type: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await addPropertyDocument(propId, bodyFormData);
    const newRes = { ...res, type };
    await dispatch(addCertsReducer(newRes));
    return newRes;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const removeDocumentsCertsAction = (propId: string, type: string, expiryType?: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await removePropertyDocument(propId, type);
    await dispatch(removeCertsReducer({ type, expiryType }));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const addPhotoAction = (advertId: string, bodyFormData: any) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await addAdvertPhoto(advertId, bodyFormData);

    await dispatch(addPhotoReducer(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const removePhotoAction = (advertId: string, photoId: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await removePhoto(advertId, photoId);
    await dispatch(removePhotoReducer(photoId));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const UnpublishAdvertAction = (advertId: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const res = await unpublishAdvert(advertId);
    await dispatch(resetStateReducer());
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const updateAdvertDetails = (advertId: string, isEditAdvert = false) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  try {
    const fetchAdvert = await fetchAdvertDetails(advertId);
    const fetchProperty = await fetchPropertyDetails(fetchAdvert.propertyId);
    const fetchImages = await fetchAdvertPhoto(fetchAdvert.id as string);
    const fetchSlots = await fetchViewingSlot(fetchAdvert.id as string);
    const screeningQuestions = await screeningQuestionsList(fetchAdvert.id as string);
    const fetchKitchens = await listKitchens(fetchProperty.id as string);
    const fetchBathrooms = await listBathrooms(fetchProperty.id as string);
    const fetchAmenities = await listPropAmenities(fetchProperty.id as string);
    const data = {
      data: {
        ...fetchProperty,
        typeId: (fetchProperty as any)?.type?.id,
        tenantTypeId: (fetchProperty as any)?.tenantType?.id,
      },
      id: fetchProperty.id,
      formRoute: '/create-ad',
      address: null,
      bathrooms: fetchBathrooms,
      kitchens: fetchKitchens,
      propertyAmenities: fetchAmenities,
      propertyCerts: {},
      roomDetails: fetchAdvert,
      photos: fetchImages,
      screeningQuestions,
      viewingSlots: fetchSlots,
      edit: true,
      isEditAdvert,
      published: fetchAdvert.published,
      thumbnail: (fetchAdvert as any).thumbnail?.id || '',
    };

    dispatch(editStateReducer(data as any));
    // return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const useExistingProperty = (id: string) => async (
  dispatch: AppDispatch,
): Promise<any> => {
  const fetchProperty = await fetchPropertyDetails(id);
  const fetchKitchens = await listKitchens(id);
  const fetchBathrooms = await listBathrooms(id);
  const fetchAmenities = await listPropAmenities(id);
  const data = {
    data: fetchProperty,
    id: fetchProperty.id,
    formRoute: '/create-ad',
    address: null,
    bathrooms: fetchBathrooms,
    kitchens: fetchKitchens,
    propertyAmenities: fetchAmenities,
    propertyCerts: {},
    roomDetails: {},
    photos: [],
    screeningQuestions: [],
    viewingSlots: [],
    edit: true,
  };

  dispatch(editStateReducer(data as any));
};
