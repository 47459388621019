import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  Drawer,
  ListItemButton,
} from '@mui/material';
import { ReactComponent as MenuSVg } from '../../../assets/icons/portfolio.svg';

const drawerWidth = 250;

export const FormContainer = styled(Box)(() => `
  margin: 30px auto;
  max-width: 445px;
  width: 80%;
  display: flex;
  flex-direction: column;
`);

export const ToolBarContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

export const LogoContainer = styled(Grid)(({ theme }) => ({
  '& a': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    '& a': {
      display: 'flex',
    },
  },
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const ToolBarContent = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'nowrap',
}));

export const OverflowItems = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& button': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: drawerWidth,
  },
  overflow: 'hidden',
}));

export const ToolBar = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  [theme.breakpoints.up('sm')]: {
    minHeight: 84,
  },
  display: 'flex',
  flexWrap: 'wrap',
}));

export const DrawerView = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 0,
    flexShrink: 0,
  },
  backgroundColor: theme.palette.primary.main as any,
}));

export const Nav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 0,
    flexShrink: 0,
  },
}));

export const DrawerLevels = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

export const DrawerLevelsMobile = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  // height: '100%',
}));

export const DrawerLogo = styled(Link)(({ theme }) => ({
  margin: '12px 5vw',
  color: 'white',
  textDecoration: 'none',
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    margin: '15px auto',
  },
}));

export const ItemContainer = styled(ListItemButton)(({ selected }) => ({
  paddingRight: 16,
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  fontWeight: 300,
  paddingLeft: '4px',
  // maxHeight: 100,
  boxSizing: 'border-box',
  ...(selected ? {
    width: drawerWidth,
    borderLeft: '4px solid white',
    paddingLeft: '0px',
    fontWeight: 700,
    backgroundColor: 'rgba(0,0,0,0.1) !important',
  } : {}),
}));

export const MenuImg = styled(MenuSVg)(({ theme }) => ({
  width: 28,
  height: 28,
  marginLeft: theme.spacing(1),
}));
