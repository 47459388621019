import {
  Button, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Switch, Typography,
} from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { steps } from '../../../views/CreateAd';
import ApplicationWrapper from '../../layout/ApplicationWrapper';
import VerticalStepper from '../common/VerticalStepper';
import { DocumentContainer, FormContainer } from '../styles';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import BottomBtn from '../common/ButtonContainer';
import { addPhotoAction, removePhotoAction, thumbNailAction } from '../../../store/actions/PropertyActions';
import PhotoPaper from '../../common/PhotoPaper';

interface AdvertImagesProps {
  handleBack(): void;
  handleNext(): void;
}

const AdvertImages: React.FC<AdvertImagesProps> = ({
  handleBack,
  handleNext,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const photosData = useAppSelector((state) => state.createpropertyState?.photos);
  const advertData = useAppSelector((state) => state.createpropertyState?.roomDetails);
  const thumbnailData = useAppSelector((state) => state.createpropertyState?.thumbnail);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingImage, setLoadingImage] = React.useState(false);

  React.useEffect(() => {
    if (!advertData?.id) {
      toast.error('create an advert first and then try again');
      navigate('/create-ad/room-details');
    }
  }, []);

  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const images = Array.from(e.target.files as FileList);
    let sizeErrorsCount = 0;
    let uploadError = 0;
    if (images?.length && advertData?.id) {
      if (((images.length || 0) + photosData.length) <= 8) {
        setLoadingImage(true);
        await Promise.all(images.map(async (image) => {
          const fileSize = image.size / 1024 / 1024;
          if (fileSize > 7) {
            sizeErrorsCount += 1;
          } else {
            try {
              const bodyFormData = new FormData();
              bodyFormData.append('file', image as any);
              await dispatch(
                addPhotoAction(advertData?.id as string, bodyFormData) as any,
              );
            } catch (error) {
              uploadError += 1;
            }
          }
        }));
        if (sizeErrorsCount) {
          toast.error('One of the images exceeds 2 MB image upload limit, kindly upload a smaller image');
        }
        if (uploadError) {
          toast.error('Sorry, please check the images being uploaded and try again');
        }
        setLoadingImage(false);
      } else {
        toast.error('Sorry, you can only upload maximum of 8 images to an advert');
      }
    }
  };

  const handlePhotoDelete = async (id: string): Promise<void> => {
    try {
      await dispatch(removePhotoAction(advertData?.id as string, id) as any);
    } catch (error) {
      toast.error('something went wrong while deleting the photo');
    }
  };

  const handleThumbnail = async (id: string): Promise<void> => {
    if (thumbnailData !== id) {
      setLoading(true);
      try {
        await dispatch(thumbNailAction(advertData?.id as string, id) as any);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('something went wrong while updating the photo thumbnail');
      }
    }
  };

  const handleSubmit = (): void => {
    if (!thumbnailData) {
      toast.warning('A thumbnail is required for your advert, please select thumbnail image');
    } else {
      handleNext();
    }
  };

  return (
    <ApplicationWrapper
      customSideNav={<VerticalStepper activeStep={4} steps={steps} />}
      activeMenu={0}
      menuElements={[]}
    >
      <FormContainer component="form">
        <Grid container spacing={2}>
          {photosData?.map((photo) => {
            const { id, file } = photo;
            const { url } = file;
            const extension = url.split('.').pop();
            const isPdf = extension === 'pdf';
            return (
              <Grid item xs={12} sm={4} md={3} key={id}>
                <PhotoPaper isPdf={isPdf} url={url}>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: 0,
                    }}
                    onClick={() => handlePhotoDelete(id)}
                  >
                    <DeleteForeverIcon fontSize="large" color="error" />
                  </IconButton>
                </PhotoPaper>
                {
                  !isPdf
                  && (
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          loading ? <CircularProgress size={20} /> : (
                            <Switch
                              onChange={() => handleThumbnail(id)}
                              checked={id === thumbnailData}
                            />
                          )
                        )}
                        label="Thumbnail"
                      />
                    </FormGroup>
                  )
                }
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={4}>
            <DocumentContainer
              elevation={0}
              sx={{ border: 'dashed #e0e0e0 1px', cursor: 'pointer', padding: 2 }}
            >
              {loadingImage ? <CircularProgress color="primary" sx={{ width: '50%' }} size="large" /> : (
                <label style={{ width: '100%' }} htmlFor="photoAdvert">
                  <input
                    id="photoAdvert"
                    accept=".jpg,.jpeg,.png,.pdf"
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handlePhotoUpload(e)}
                  />
                  <Button fullWidth variant="contained" disabled={loading} component="span" size="small">
                    <Typography>Add Photo/PDF</Typography>
                  </Button>
                </label>
              )}
            </DocumentContainer>
          </Grid>
        </Grid>
        <BottomBtn
          typeSubmit="button"
          loading={loading}
          disabledNext={photosData?.length === 0}
          handleNext={handleSubmit}
          handleBack={handleBack}
        />
      </FormContainer>
    </ApplicationWrapper>
  );
};

export default AdvertImages;
