import { AppDispatch } from '..';
import {
  IUserLoginParams, IUserLoginResponse, loginUser, logOut,
} from '../../sdk/authentication';
import { fetchUser, IUserResponse } from '../../sdk/profile';
import { addSessionToken, removeSessionToken } from '../reducers/login';
import { addUserInfo, removeUser } from '../reducers/profile';

export const loginAction = (
  userCredentials: IUserLoginParams,
) => async (
  dispatch: AppDispatch,
): Promise<IUserLoginResponse> => {
  try {
    const res = await loginUser(userCredentials);
    dispatch(addSessionToken(res));
    const user = await fetchUser();
    dispatch(addUserInfo(user));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const getUserAction = () => async (
  dispatch: AppDispatch,
): Promise<IUserResponse> => {
  try {
    const res = await fetchUser();
    dispatch(addUserInfo(res));
    return res;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};

export const logoutAction = () => async (
  dispatch: AppDispatch,
): Promise<null> => {
  try {
    const res = await logOut();
    dispatch(removeSessionToken());
    dispatch(removeUser());
    return res;
  } catch (error) {
    dispatch(removeSessionToken());
    dispatch(removeUser());
    if (!error.response) {
      throw error;
    }

    throw error?.response;
  }
};
