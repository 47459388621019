import React from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import SelectProperty from '../../components/CreateAd/SelectProperty';
import usePageTitle from '../../hooks/pageTitle';
import PropertyAddress from '../../components/CreateAd/PropertyAddress';
import PropertyDetailsForm from '../../components/CreateAd/PropertyDetails';
import RoomDetailsForm from '../../components/CreateAd/RoomDetails';
import ViewingSlots from '../../components/CreateAd/ViewingSlots';
import VirtualMeeting from '../../components/CreateAd/VirtualMeeting';
import ScreeningQuestionView from '../../components/CreateAd/ScreenQuestions';
import SummaryPage from '../../components/CreateAd/SummaryPage';
import AdvertImages from '../../components/CreateAd/AdvertImages';

export const steps = [
  'Select Property',
  'Property Address',
  'Property Details',
  'Advert Details',
  'Add Advert Images',
  'Screening Questions',
  'Viewing Slots',
  'Preview & Submit',
];

const CreateAd: React.FC = () => {
  usePageTitle('Create Advert');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedProperty, setSelectedProp] = React.useState('add');
  const [step, setStep] = React.useState(0);

  const componentSwitch = [{
    id: 0,
    title: 'Create an advert',
    route: '/create-ad',
  },
  {
    id: 1,
    title: 'Property Address',
    route: '/create-ad/property-address',
  },
  {
    id: 2,
    title: 'Property Details',
    route: '/create-ad/property-details',
  },
  {
    id: 3,
    title: 'Room Details',
    route: '/create-ad/room-details',
  },
  {
    id: 4,
    title: 'Add Advert Images',
    route: '/create-ad/room-images',
  },
  {
    id: 5,
    title: 'Screening questions',
    route: '/create-ad/screening-questions',
  },
  {
    id: 6,
    title: 'Viewing Slots',
    route: '/create-ad/viewing-slots',
  },
  {
    id: 7,
    title: 'Preview Property',
    route: '/create-ad/preview-submission',
  },
  ];

  React.useEffect(() => {
    const newStep = componentSwitch.findIndex((item) => item.route === pathname);
    if (newStep !== step) {
      setStep(newStep);
    }
  }, [pathname]);

  const handleBack = (): void => {
    if (step > 0) {
      navigate(componentSwitch[step - 1].route);
    } else {
      navigate('/dashboard');
    }
  };

  const handleNext = (): void => {
    if (step < 7) {
      setStep(step + 1);
      navigate(componentSwitch[step + 1].route);
    }
  };

  const handleNextSelect = (): void => {
    if (selectedProperty === 'add') {
      handleNext();
    } else {
      handleNext();
    }
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <SelectProperty value={selectedProperty} setProperty={setSelectedProp} handleNext={handleNextSelect} />
          }
        />
        <Route
          path="/property-address"
          element={
            <PropertyAddress handleBack={handleBack} handleNext={handleNext} />
          }
        />
        <Route
          path="/property-details"
          element={
            <PropertyDetailsForm handleBack={handleBack} handleForm={handleNext} />
          }
        />
        <Route
          path="/room-details"
          element={
            <RoomDetailsForm handleBack={handleBack} handleNext={handleNext} />
          }
        />
        <Route
          path="/room-images"
          element={
            <AdvertImages handleBack={handleBack} handleNext={handleNext} />
          }
        />
        <Route
          path="/screening-questions"
          element={
            <ScreeningQuestionView handleBack={handleBack} handleNext={handleNext} />
          }
        />
        <Route
          path="/viewing-slots"
          element={
            <ViewingSlots handleBack={handleBack} handleNext={handleNext} steps={steps} enableStepper />
          }
        />
        <Route
          path="/virtual-meeting"
          element={
            <VirtualMeeting handleBack={handleBack} handleNext={handleNext} steps={steps} enableStepper />
          }
        />
        <Route
          path="/preview-submission"
          element={
            <SummaryPage />
          }
        />
      </Routes>
    </>
  );
};

export default CreateAd;
