import React from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import {
  Box,
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SingleLead } from '../../LeadManager/LeadManagerContainer';
import { ILead } from '../../../sdk/leads';

interface Props {
  leads: Array<ILead>;
}

const ViewingsTimelineItem = (props: Props): JSX.Element => {
  const { leads } = props;
  const time = format(new Date(leads[0].viewingSlot!.dateAndTime), 'p');

  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ flex: 0 }}>
        <Typography color="textSecondary" noWrap sx={{ width: 80 }}>{time}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {
          leads.map((lead: any) => (
            <SingleLead
              key={lead.id}
              lead={lead}
              leadsList={leads}
            />
          ))
        }
      </TimelineContent>
    </TimelineItem>
  );
};

const Header = styled(Typography)`
  background: rgba(72, 95, 199, 0.5);
  color: #0f1585;
  font-weight: 500;
  margin: -8px -16px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const ViewingsTimelineItemMobile = (props: Props): JSX.Element => {
  const { leads } = props;
  const time = format(new Date(leads[0].viewingSlot!.dateAndTime), 'p');

  return (
    <Box paddingBottom={2}>
      <Header>
        <AccessTimeIcon fontSize="inherit" />
        &nbsp;
        {time}
      </Header>

      <Stack>
        {
          leads.map((lead: any) => (
            <SingleLead
              key={lead.id}
              lead={lead}
              leadsList={leads}
            />
          ))
        }
      </Stack>
    </Box>
  );
};

const ViewingsTimeline = (props: Props): JSX.Element => {
  const { leads } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const groupedByTime = groupBy(
    leads,
    (slot) => format(new Date(slot.viewingSlot!.dateAndTime), 'p'),
  );

  const sortedGroups = sortBy(groupedByTime, (group) => leads.indexOf(group[0]));
  const Component = isMobile ? ViewingsTimelineItemMobile : ViewingsTimelineItem;

  return (
    <div>
      {
        isMobile
        && (
          <Stack>
            { sortedGroups.map((sortedGroup) => <Component key={sortedGroup[0].viewingSlot!.dateAndTime} leads={sortedGroup} />) }
          </Stack>
        )
      }
      {
        !isMobile
        && (
        <Timeline position="right">
          {
            sortedGroups.map((sortedGroup) => <Component key={sortedGroup[0].viewingSlot!.dateAndTime} leads={sortedGroup} />)
          }
        </Timeline>
        )
      }

    </div>
  );
};

export default ViewingsTimeline;
