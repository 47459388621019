import React from 'react';
import {
  Box, Button,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Menu, MenuItem, Typography,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReportIcon from '@mui/icons-material/Report';
import {
  Container,
  HeaderTitle,
  HeaderContainer,
  CreateButton,
  MainContainer,
  AdContainer,
  ButtonContainer,
  ButtonDown,
  AdImg,
  ImgContainer,
} from './styles';
import House from '../../../assets/images/no-image.png';
import { useRequestState } from '../../../hooks/request';
import { fetchAdverts } from '../../../sdk/adverts';
import { fetchPropertyDetails } from '../../../sdk/propertyApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAdvertListAction, unpublishAction } from '../../../store/actions/advertActions';
import WelcomeModal from './WelcomePopover';
import { toggleWelcomeModal } from '../../../store/reducers/listAdvert';
import ProductSelect from '../../ProductSelect';

export interface AdProps {
  room: any;
}

export const PublishTitle: React.FC<{
  title: string;
  publish: true;
}> = ({ title, publish }) => (<Typography color={publish ? 'primary' : 'error'} variant="h6">{title}</Typography>);

const Ad: React.FC<AdProps> = ({ room }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [unpublishDialogOpen, setUnpublishDialogOpen] = React.useState<boolean>(false);
  const [publishDialogOpen, setPublishDialogOpen] = React.useState<boolean>(false);
  const propDetails = useRequestState(fetchPropertyDetails);
  const [anchorExtra, setAnchorExtra] = React.useState<null | HTMLElement>(null);
  const handleClickExtra = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorExtra(event.currentTarget);
  };
  React.useEffect(() => {
    propDetails.fetch(room.propertyId);
  }, []);

  const handleChangeEdit = ():void => {
    navigate('/create-ad/preview-submission', { state: { id: room.id } });
  };

  const handleUnpublish = async (): Promise<void> => {
    dispatch(unpublishAction(room.id));
    setUnpublishDialogOpen(false);
  };

  return (
    <AdContainer elevation={2}>
      <ImgContainer>
        <AdImg
          src={room.thumbnail?.file?.url || House}
          alt="house"
        />
      </ImgContainer>
      <Box sx={{ flex: 1, ml: 2 }}>
        <Grid
          sx={{ marginTop: '8px', marginLeft: '10px', alignItems: { xs: 'center', md: 'flex-start' } }}
          container
          direction="column"
        >
          <Typography
            variant="body1"
            sx={{
              color: '#000',
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '5px',
              whiteSpace: 'pre-wrap',
              mr: 1,
            }}
          >
            {room.adTitle}
            {' '}
            {`(#${room.referenceNumber})`}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#000',
              fontSize: '13px',
              fontWeight: 500,
              marginBottom: '5px',
              whiteSpace: 'pre-wrap',
              mr: 1,
              display: 'flex',
            }}
          >
            {propDetails.value?.flatNumber}
            {propDetails.value?.flatNumber && ', '}
            {propDetails.value?.buildingNameOrNumber}
            <Typography
              variant="body1"
              sx={{
                color: '#9e9e9e',
                fontSize: '13px',
                fontWeight: 500,
                marginBottom: '5px',
                whiteSpace: 'pre-wrap',
                mr: 1,
              }}
            >
              {', '}
              {propDetails.value?.streetName}
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#9e9e9e',
              fontSize: '13px',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            {propDetails.value?.town}
            ,
            {' '}
            {propDetails.value?.postalCode}
          </Typography>
        </Grid>
      </Box>
      <ButtonContainer>
        <ButtonDown
          id="status-btn"
          aria-haspopup="true"
          variant="outlined"
          disableRipple
        >
          {room.published ? 'Live' : 'Offline'}
        </ButtonDown>
        <ButtonDown
          onClick={handleClickExtra}
          id="extra-btn"
          aria-haspopup="true"
          aria-expanded={anchorExtra ? 'true' : undefined}
          variant="outlined"
        >
          <MoreHorizIcon />
        </ButtonDown>
        <Menu
          anchorEl={anchorExtra}
          open={Boolean(anchorExtra)}
          onClose={() => setAnchorExtra(null)}
          MenuListProps={{
            'aria-labelledby': 'status-btn',
          }}
        >
          {
            room.published && (
              <MenuItem onClick={() => setUnpublishDialogOpen(true)}>
                Unpublish
              </MenuItem>
            )
          }

          {
            !room.published && (
              <MenuItem onClick={() => setPublishDialogOpen(true)}>
                Publish
              </MenuItem>
            )
          }

          <MenuItem onClick={() => handleChangeEdit()}>
            Edit
          </MenuItem>
        </Menu>
      </ButtonContainer>

      <Dialog open={publishDialogOpen} maxWidth="xl" onClose={() => setPublishDialogOpen(false)}>
        <DialogContent sx={{ mx: 4, my: 2 }}>
          <ProductSelect advertId={room.id} />
        </DialogContent>
      </Dialog>

      <Dialog open={unpublishDialogOpen} onClose={() => setUnpublishDialogOpen(false)}>
        <DialogTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ReportIcon color="error" sx={{ fontSize: 60 }} />
          <Typography color="error" variant="h4" sx={{ fontWeight: 500 }}>Are you sure?</Typography>
        </DialogTitle>
        <DialogContent sx={{ width: '70%', mx: 'auto', textAlign: 'center' }}>
          <Typography>
            If you
            {' '}
            <strong>unpublish</strong>
            {' '}
            your advert, you will have to purchase a new listing if you wish to re-list.
          </Typography>
          <Typography sx={{ my: 1 }}>
            Don&apos;t unpublish unless you&apos;ve at least got a holding deposit!
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={() => setUnpublishDialogOpen(false)} variant="contained" size="large">Cancel</Button>
          <Button onClick={handleUnpublish} variant="contained" color="error" size="large">Confirm</Button>
        </DialogActions>
      </Dialog>
    </AdContainer>
  );
};

const AdsView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const advertList = useAppSelector((state) => state.AdvertListState.data);
  const showWelcome = useAppSelector((state) => state.AdvertListState.showWelcome);
  const propertyList = useRequestState(fetchAdverts);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getAdverts = async (): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(getAdvertListAction());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('something went while fetching your properties, please contact support');
    }
  };
  React.useEffect(() => {
    propertyList.fetch();
    getAdverts();
  }, []);

  React.useEffect(() => {
    if (loading === false && advertList.length === 0) {
      dispatch(toggleWelcomeModal(true));
    } else {
      dispatch(toggleWelcomeModal(false));
    }
  }, [loading, advertList]);

  return (
    <Container>
      <HeaderContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <HeaderTitle variant="h4">Ads</HeaderTitle>
        <CreateButton onClick={() => navigate('/create-ad')}>
          <AddIcon sx={{ marginRight: 1 }} />
          Create ad
        </CreateButton>
      </HeaderContainer>
      <MainContainer>
        <>
          {
          loading ? (
            <Box sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 3,
              height: '50vh',
            }}
            >
              <CircularProgress />
            </Box>
          )
            : advertList?.slice()?.sort(({ published: x }) => (x ? -1 : 1))?.map((room) => <Ad room={room} key={room.id} />)
        }
        </>
      </MainContainer>
      <WelcomeModal open={!!showWelcome} />
    </Container>
  );
};

export default AdsView;
