import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import MarkdownViewer from '../../common/MarkdownViewer';

interface AccordionVirtualMeetingDetailsProps {
    virtualMeetingDetails: string;
}

const AccordionVirtualMeetingDetails: React.FC<AccordionVirtualMeetingDetailsProps> = ({ virtualMeetingDetails }) => {
  const navigate = useNavigate();
  return (
    <>
      {
                    virtualMeetingDetails && (
                    <Accordion defaultExpanded sx={{ mb: 6, mt: 6 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', flex: 1 }}>Virtual Meeting Instructions</Typography>
                        <Button sx={{ mr: 4 }} onClick={() => navigate('/create-ad/virtual-meeting')}>
                          <EditIcon sx={{ mr: 1 }} />
                          {'  '}
                          Edit Section
                        </Button>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MarkdownViewer markdown={virtualMeetingDetails || ''} />
                      </AccordionDetails>
                    </Accordion>
                    )
                }
    </>
  );
};

export default AccordionVirtualMeetingDetails;
