import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import ApplicationWrapper from '../components/layout/ApplicationWrapper';
import LeadManagerContainer from '../components/LeadManager/LeadManagerContainer';
import usePageTitle from '../hooks/pageTitle';

const LeadManager = ():JSX.Element => {
  usePageTitle('Lead Manager');

  const menuElements: { id: string | number; title: string; icon: string; link: string; }[] = [
    {
      title: 'Adverts',
      id: 1,
      icon: '../../assets/icons/portfolio.svg',
      link: '/dashboard',
    },
    {
      title: 'Lead Manager',
      id: 2,
      icon: '../../assets/icons/portfolio.svg',
      link: '/lead-manager',
    },
    {
      title: 'Viewings Calendar',
      id: 3,
      icon: '/icons/portfolio',
      link: '/upcoming-view',
    },
  ];

  return (
    <ApplicationWrapper customSideNav={null} activeMenu={2} menuElements={menuElements}>
      <>
        <LeadManagerContainer />
      </>
    </ApplicationWrapper>
  );
};

export default LeadManager;
