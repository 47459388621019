import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Routing from './routes';
import store from './store';
import theme from './theme';
import 'react-toastify/dist/ReactToastify.css';
import { AppHistory, HistoryRouter as Router } from './routes/BrowserHistory';

const persistor = persistStore(store);

const App = (): JSX.Element => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={AppHistory}>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <div>
            <Routing />
          </div>
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
