import React from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
  Avatar,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import OverflowIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import {
  ToolBarContainer,
  LogoContainer,
  MenuButton,
  ToolBarContent,
  OverflowItems,
  MainContent,
  ToolBar,
  DrawerView,
  Nav,
  DrawerLevels,
  DrawerLogo,
  DrawerLevelsMobile,
} from './styles';
import stringAvatar from '../../../utils/stringToColor';
import DrawerList from './DrawerView';
import { useAppSelector } from '../../../store/hooks';
import LogoLink from '../Navigation/LogoLink';

export interface ImenuElement {
  id: string | number;
  title: string;
  icon: string;
  link: string;
}

interface ApplicationWrapperProps {
  children: JSX.Element;
  activeMenu: string | number;
  menuElements: ImenuElement[];
  customSideNav: JSX.Element | null;
}

const ApplicationWrapper: React.FC<ApplicationWrapperProps> = ({
  children,
  activeMenu,
  menuElements,
  customSideNav,
}) => {
  const { user, userExist } = useAppSelector((state) => state.profileState);
  const { sessionToken } = useAppSelector((state) => state.authState);
  const navigate = useNavigate();
  const [overflowAnchor, setOverflowAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [drawerState, setDrawerState] = React.useState(false);
  const toggleDrawer = (): void => {
    setDrawerState(!drawerState);
  };

  const handleSetOverflowAnchor = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setOverflowAnchor(event.currentTarget);
  };
  const [selectedMenu, setSelectedMenu] = React.useState<string | number>(activeMenu);

  const handleMenuClick = (menu: ImenuElement): void => {
    setSelectedMenu(menu.id);
    navigate(menu.link);
  };

  React.useEffect(() => {
    if (!sessionToken) {
      navigate('/logout');
    }
  }, []);

  return (
    <Box>
      <CssBaseline />
      <AppBar>
        <Toolbar style={{ width: '100%' }} variant="regular">
          <ToolBarContainer>
            <Grid container alignItems="center">
              <LogoContainer item>
                <MenuButton
                  color="inherit"
                  edge="start"
                  onClick={toggleDrawer}
                >
                  <MenuIcon sx={{ color: 'white' }} />
                </MenuButton>

                <LogoLink />
              </LogoContainer>
              <ToolBarContent
                item
                style={{ flexGrow: 1 }}
              >
                <OverflowItems>
                  <IconButton
                    onClick={handleSetOverflowAnchor}
                  >
                    {userExist && <Avatar component="div" {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} />}
                    <OverflowIcon fontSize="medium" />
                  </IconButton>
                  {overflowAnchor && (
                  <Menu
                    open={Boolean(overflowAnchor)}
                    anchorEl={overflowAnchor}
                    onClose={() => {
                      setOverflowAnchor(null);
                    }}
                  >
                    <MenuItem component={Link} to="/logout">
                      <LogoutIcon sx={{ marginRight: 2 }} />
                      Sign Out
                    </MenuItem>
                  </Menu>
                  )}
                </OverflowItems>
              </ToolBarContent>
            </Grid>
          </ToolBarContainer>
        </Toolbar>
      </AppBar>

      <Nav>
        <Hidden mdUp implementation="js">
          <DrawerView
            anchor="left"
            open={drawerState}
            variant="temporary"
            onClose={() => toggleDrawer()}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{ sx: { bgcolor: 'primary.main', minWidth: 250, overflow: 'hidden' }, elevation: 3 }}
          >
            <DrawerLogo to="/">
              <img width="70px" src={`${process.env.PUBLIC_URL}/nevo-logo.png`} alt="logo" />
            </DrawerLogo>
            <DrawerLevelsMobile>
              {customSideNav ?? (
              <DrawerList
                drawerItems={menuElements}
                selectedMenu={selectedMenu}
                handleMenuClick={handleMenuClick}
              />
              )}
            </DrawerLevelsMobile>
          </DrawerView>
        </Hidden>
        <Hidden smDown implementation="js">
          <DrawerView
            anchor="left"
            variant="permanent"
            PaperProps={{
              sx: {
                zIndex: 1000, bgcolor: 'primary.main', width: 250, overflow: 'hidden',
              },
              elevation: 3,
            }}
            open
          >
            <ToolBar />
            <DrawerLevels>
              <div>
                {customSideNav ?? (
                <DrawerList
                  drawerItems={menuElements}
                  selectedMenu={selectedMenu}
                  handleMenuClick={handleMenuClick}
                />
                )}
              </div>
            </DrawerLevels>
          </DrawerView>
        </Hidden>
      </Nav>

      <MainContent>
        <ToolBar />
        {children}
        <Outlet />
      </MainContent>
    </Box>
  );
};

export default ApplicationWrapper;
